import React from 'react';
import { useTranslation } from 'react-i18next';
import './SelectionDetails.scss';

/**
 * Display of the length / width or the diameter as well as the area of the element currently selected by the user in the toolbar of the designer.
 * @param selectedEl rect/arc
 * @returns {JSX.Element} element that will be added to the toolbar
 */
export const SelectionDetails = ({ selectedEl }) => {
  const { t } = useTranslation();
  const tPrefix = 'designer.panel.manipulation.selectionDetails';

  // If the selected element is undefined or neither an arc nor a rectangle
  // we just display "No selection"
  let statsLines = {
    outer: '',
    inner: t(`${tPrefix}.nothingSelected`),
    area: ''
  }

  if (selectedEl) {
    if (selectedEl.parent.statBlockObj) {
      // Rectangle
      const statBlockData = selectedEl.parent.statBlockObj;
      statsLines = {
        outer: statBlockData.outer,
        inner: statBlockData.inner,
        area: `${statBlockData.areaInSqMRounded}m²`
      }
    } else if (selectedEl.creationParams.generateStatBlockData) {
      // Arc/Circle
      const statBlockData = selectedEl.creationParams.generateStatBlockData();
      statsLines = {
        outer: statBlockData.outerDiameter,
        inner: statBlockData.innerDiameter,
        area: statBlockData.area
      }
    }
  }

  return (
    <div className='selectionDetails'>
      <table>
        <tbody>
        <tr>
          <th>{t(`${tPrefix}.outer`)}</th>
          <td>{statsLines.outer}</td>
        </tr>
        <tr>
          <th>{t(`${tPrefix}.inner`)}</th>
          <td>{statsLines.inner}</td>
        </tr>
        <tr>
          <th>{t(`${tPrefix}.area`)}</th>
          <td>{statsLines.area}</td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SelectionDetails;
