/**
 * @typedef StateInLocalStorage
 * @prop {LatLngNum} center Center of view
 * @prop {Object[]} elements Saved poly elements.
 * @prop {boolean} grid wheater or not to display the grid
 */
/**
 * @typedef {import("./AmazingStateManagement").default} AmazingStateManagement
 * @typedef {import("../logic/ElBench").ElBench} ElBench
 * @typedef {import("../elements/rectangle/RectangleCreationParams").RectangleCreationParams} RectangleCreationParams
 * @typedef {import("../elements/common/CommonDrawing").RectanglePoly} RectanglePoly
 * @typedef {import("../elements/common/CommonDrawing").PolyArcPoly} PolyArcPoly
 * @typedef {import("../../../common/customTypes").LatLngNum} LatLngNum
 */
import { latLngFnToNum } from "../../../common/Utils";
import drawArc from "../elements/arc/ArcDrawing";
import polyRotation from "../elements/common/logic/polyRotation";
import { isDebugging, tlog } from "../elements/debugging";
import createRectGooglePoly from "../elements/rectangle/RectangleDrawing";
import { RectangleCreationParams } from "../elements/rectangle/RectangleCreationParams";
import { ArcCreationParams } from "../elements/arc/ArcCreationParams";
import { ARC_TYPE } from "../elements/arc/ArcConsts";
import { RECTANGLE_TYPE } from "../elements/rectangle/RectangleConsts";
import { LS_STATE_02_KEY } from "../DesignerConstants";

class SaveStateManager {
    /**
     * @param {AmazingStateManagement} asm
     */
    constructor(asm, mockLocalStorage) {
        this.stateManagement = asm;
        this.elBench = undefined;
        this.storage = mockLocalStorage ? mockLocalStorage : window.localStorage;
    }

    /**
     *
     * @param {ElBench} elBench
     */
    setElBench(elBench) {
        this.elBench = elBench;
    }

    /**
     * Attempt to do an initial load via #loadFromLocalStorage, only if we haven not yet done that before
     * @param {*} useOldLocation
     * @param {*} locationToOverrideAllWith
     */
    attemptInitialLoad(useOldLocation, locationToOverrideAllWith) {
        if (!this.loadedOnce) {
            this.loadedOnce = true;
            this.loadFromLocalStorage(useOldLocation, locationToOverrideAllWith);
        }
    }

    isFarAway(latLng1, latLng2) {
        if (!latLng2) {
            console.warn("todo: step1OriginLatLng");
            return false;
        }
        const diffLat = Math.abs(latLng1.lat - latLng2.lat);
        const diffLng = Math.abs(latLng1.lng - latLng2.lng);
        const totalDiff = diffLat + diffLng;
        return totalDiff > 0.01; // Todo: find good distance
    }

    /**
     * Load 'LS_STATE_02_KEY' from local-storage
     * @returns undefined or the parsed json-object
     */
    state02OrUndefinedFromLocalStorage() {
        const storage = this.storage;
        if (storage.getItem(LS_STATE_02_KEY) === null) {
            isDebugging() && tlog("No saved state found");
            return undefined;
        } else {
            const jsonString = storage.getItem(LS_STATE_02_KEY);
            const jsonObj = JSON.parse(jsonString);

            tlog("Loaded JSON", jsonObj);
            return jsonObj;
        }
    }

    /**
     * Clear state and elements of step-02.
     * Invokes stateManagement.removeAllElements and localStorage.removeItem.
     */
    clearState02LocalStorageAndElements() {
        this.stateManagement.removeAllElements();
        localStorage.removeItem(LS_STATE_02_KEY);
    }

    /**
     *
     * @param {boolean} useOldLocation if !locationToOverrideAllWith  && !!useOldLocation don't set center
     * @param {google.maps.LatLng} locationToOverrideAllWith
     */
    loadFromLocalStorage(useOldLocation, locationToOverrideAllWith) {
        const jsonObj = this.state02OrUndefinedFromLocalStorage();
        if (jsonObj) {
            const mapCenter = latLngFnToNum(this.elBench.map.getCenter());
            const newViaLocalStorageFromStep1 = jsonObj.center; // mapCenter
            const diff = {
                lat: mapCenter.lat - newViaLocalStorageFromStep1.lat,
                lng: mapCenter.lng - newViaLocalStorageFromStep1.lng
            };

            tlog("Centers, diff", mapCenter, newViaLocalStorageFromStep1, diff);

            if (locationToOverrideAllWith) {
                console.warn(
                    "♦️ SaveStateManager#loadFromLocalStorage locationToOverrideAllWith",
                    locationToOverrideAllWith
                );
                this.stateManagement.setCenter(locationToOverrideAllWith);
            } else {
                if (useOldLocation) {
                    // keep current center
                    tlog("♦️ SaveStateManager#loadFromLocalStorage keepLocation");
                } else {
                    tlog("♦️ SaveStateManager#loadFromLocalStorage json.center", jsonObj.center);
                    this.stateManagement.setCenter(jsonObj.center);
                }
            }

            this.stateManagement.removeAllElements();
            this.restoreStateFromLocalStorage(jsonObj);
        }
    }

    initialLoadFromLocalStorage() {
        this.loadFromLocalStorage();
    }

    /**
     *
     * @param {StateInLocalStorage} json
     */
    restoreStateFromLocalStorage(json) {
        if (!json.center) {
            console.error("Center missing in state json");
        }
        if (!json.elements) {
            console.error("Elements missing in state json");
        }
        const elBench = this.elBench;

        tlog("Restoring state from local storage: ", json);

        for (const staticElement of json.elements) {
            let recreatedElement;
            switch (staticElement.type) {
                case ARC_TYPE:
                    const arcCreationParams = new ArcCreationParams(staticElement);
                    recreatedElement = this.createPolyArc(elBench, arcCreationParams);
                    break;
                case "rectangle":
                    const rectCreationParams = new RectangleCreationParams(staticElement);
                    recreatedElement = this.createRectangle(elBench, rectCreationParams);
                    break;
                default:
                    console.error("No creator for type ", staticElement.type, staticElement);
                    continue;
            }
            this.stateManagement.addElement(recreatedElement);
            const { rotation, scale } = staticElement;
            if (recreatedElement.getPath().length === 0) {
                console.error("No polys in element", recreatedElement);
            }
            tlog(
                "Created element, now rotating, scale already given via sizes",
                recreatedElement,
                rotation,
                scale
            );
            const NOP = () => {};
            polyRotation(recreatedElement, rotation, NOP);
        }
        tlog("🔁 restoreStateFromLocalStorage>handleDataChange");
        this.elBench.handleDataChange();
    }

    /**
     *
     * @param {ElBench} elBench
     * @param {ArcCreationParams} arcCreationParams
     */
    createPolyArc(elBench, arcCreationParams) {
        return drawArc(elBench, arcCreationParams);
    }

    /**
     *
     * @param {ElBench} elBench
     * @param {RectangleCreationParams} rectangleCreationParams
     * @returns {google.maps.Polygon}
     */
    createRectangle(elBench, rectangleCreationParams) {
        tlog("createRectangle", elBench, rectangleCreationParams);
        return createRectGooglePoly(elBench, rectangleCreationParams);
    }

    /**
     *
     * @param {StateInLocalStorage} state
     * @param {RectanglePoly|PolyArcPoly} element
     */
    addStaticCreationParamsToState(state, element) {
        state.elements.push(element.creationParams.getStaticCreationParams());
    }

    getStateAsJSON() {
        tlog(">💾 getStateAsJSON");
        let state = {
            elements: [],
            grid: true,
            center: {
                lat: this.stateManagement.getCenter().lat(),
                lng: this.stateManagement.getCenter().lng()
            }
        };
        for (const element of this.stateManagement.elements) {
            const type = element.creationParams.type;

            switch (type) {
                case ARC_TYPE:
                case RECTANGLE_TYPE:
                    this.addStaticCreationParamsToState(state, element);
                    break;
                default:
                    console.error("No persister for type ", type, element);
                    continue;
            }
        }

        return JSON.stringify(state);
    }

    writeStateToLocalStorage(mockLocalStorage) {
        const storage = mockLocalStorage ? mockLocalStorage : window.localStorage;
        storage.setItem(LS_STATE_02_KEY, this.getStateAsJSON());
    }
}

export default SaveStateManager;
