import turfDestination from "@turf/destination";
import { defaultGridSizeInBlocks } from "../../../../../Configuration";
import { arrToObj, lngLatArrToObj, objToArrLngLat } from '../../../../common/Utils.js';
import { defaultGridSettings } from "../common/DefaultPolySettings.js";
import polyTranslation from "../common/logic/polyTranslation";
import calculateGrid from "./GridCoordCalculation";

/**
 * Wraps the grid-polyline instead of directly exposing the Polyline to the rest of the codebase.
 */
export class Grid {
    /**
     * @class Grid
     * @param elBench
     * @param firstCenter
     */
    constructor(elBench, firstCenter) {
        const path = calculateGrid({
            origin: firstCenter,
            gridSizeInBlocks: defaultGridSizeInBlocks,
            map: elBench.map
        });
        this.gridCenter = firstCenter;
        /**
         * @type {google.maps.Map?} map for bounds
         */
        this.map = undefined;
        /**
         * @type {google.maps.Polyline} Polyline constructing a grid
         */
        this.gridPolyLine = new elBench.google.maps.Polyline({
            ...defaultGridSettings,
            path
        });
    }

    calculateGridCenter() {
        const path = this.gridPolyLine.getPath();
        // Diagonally from bottom left corner to half w/h
        const sizeInCm = (defaultGridSizeInBlocks * 250) / 2;
        const diagonalDistance = Math.sqrt(sizeInCm * sizeInCm + sizeInCm * sizeInCm);


        // Using `objToArrLngLat(path.getAt(0));` here results in warnings,
        // because the center temporarily has non-german coordinates
        // so we just inline the code here to skip the warnings
        const objToLngLatArr = obj => typeof obj.lat === "function" ? [obj.lng(), obj.lat()] : [obj.lng, obj.lat];
        const lngLat = objToLngLatArr(path.getAt(0));
        const dest = turfDestination(lngLat, diagonalDistance, 45, {
            units: "centimeters"
        }).geometry.coordinates;
        const newCenter = arrToObj(dest.reverse());
        return newCenter;
    }

    /**
     * @param {google.maps.LatLngLiteral} newCenter
     */
    recenter(newCenter) {
        this.gridCenter = newCenter;
        polyTranslation(this.gridPolyLine, newCenter, this.calculateGridCenter());
    }

    /**
     * Show or hide the grid, depending on its current state
     */
    toggleVisibility() {
        this.gridPolyLine.setVisible(!this.gridPolyLine.getVisible());
    }

    /**
     * Bind the grid to the given map
     * @param {google.maps.Map} map
     */
    setMap(map) {
        this.gridPolyLine.setMap(map);
        this.map = map;
        // Zoom change => in-/de-crease grid cell size
        map.addListener("zoom_changed", () => {
            this.recalculateGridDimensions();
        });
        // Bounds change => in-/de-crease bounds of grid
        map.addListener("bounds_changed", () => {
            this.recalculateGridDimensions();
        });
    }

    /**
     * Shall be called on zoom level change
     * @param {number} zoomLevel 0-22
     */
    recalculateGridDimensions() {
        // No zoom specific logic as that is already handled within the calculateGrid method
        const path = calculateGrid({
            origin: this.gridCenter,
            gridSizeInBlocks: defaultGridSizeInBlocks,
            map: this.map
        });
        this.gridPolyLine.setPath(path);
    }
}

export default Grid;
