import turfDestination from "@turf/destination";
import { isDebugging } from "scenes/2_setup_designer/designer/elements/debugging";
/*
 * Used Types:
 * - Turf-Point: {}.geometry.coordinates => [num, num] = [lat, lng]
 * LatLng used by google.maps: {lat: () => num, lng: () => num}
 * LatLng occasionally supported by google.maps: {lat: num, lng: num}
 */

/**
 * @typedef {import("./customTypes").LatLngNum} LatLngNum
 */

const debugOnlyTrace = (message, param) => {
    isDebugging() && console.trace(message, param);
};

const various = {
    /**
     * Wait for an element, trying to retrieve it using the given function. Note: no failsafe.
     * @param {{(el: any): void}} callBackWithElement Shall be called with element, when element is found
     * @param {{(): Element}|{(): HTMLElement}} getElementFn Shall return element at some point
     */
    waitForElement: (callBackWithElement, getElementFn) => {
        const el = getElementFn();
        if (el) {
            callBackWithElement(el);
        } else {
            setTimeout(() => {
                waitForElement(callBackWithElement, getElementFn);
            }, 250);
        }
    },

    /**
     * Rounds the number to three digits. e.g. 3.1415 -> 3.142
     * @param {number} num
     */
    round3: num => Math.round(num * 1000) / 1000,

    /**
     * Rounds the number to first digit. e.g. 3.1415 -> 3.1
     * @param {number} num
     */
    round1: num => Math.round(num * 10) / 10,

    /**
     * Rounds the number to six digits. e.g. 3.141592654 -> 3.141593 (0.11m=11cm)
     * @param {number} num
     */
    round6: num => Math.round(num * 1000000) / 1000000,

    /**
     * Rounds the number to six digits. e.g. 3.141592654 -> 3.14159265 (1.1mm)
     * @param {number} num
     */
    round8: num => Math.round(num * 1000000) / 1000000
};

const latLngConversions = {
    /**
     * google.maps.LatLng -> [lat, lng]
     * @param {google.maps.LatLngLiteral | google.maps.LatLng} obj
     * @return {number[]} [lat, lng]
     */
    objToArr: obj => {
        const retVal = typeof obj.lat === "function" ? [obj.lat(), obj.lng()] : [obj.lat, obj.lng];
        if (retVal[0] <= retVal[1]) {
            debugOnlyTrace("invalid value if not in germany, it should be lat>lng", retVal);
        }
        return retVal;
    },

    /**
     * google.maps.LatLng -> [lng, lat]
     * @param {google.maps.LatLngLiteral | google.maps.LatLng} obj
     * @return {number[]} [lng, lat]
     */
    objToArrLngLat: obj => {
        const retVal = objToArr(obj);
        retVal.reverse();
        if (retVal[1] <= retVal[0]) {
            debugOnlyTrace("invalid value if not in germany, it should be lat>lng", retVal);
        }
        return retVal;
    },

    /**
     * [lat, lng] => google.maps.LatLngLiteral
     * @param {number[]} arr
     * @returns {google.maps.LatLngLiteral} {lat: number, lng: number}
     */
    arrToObj: arr => {
        const retVal = { lat: arr[0], lng: arr[1] };
        if (retVal.lat <= retVal.lng) {
            debugOnlyTrace("invalid value if not in germany, it should be lat>lng", retVal);
        }
        return retVal;
    },

    /**
     * [lng, lat] => google.maps.LatLngLiteral
     * @param {number[]} arr
     * @returns {google.maps.LatLngLiteral} {lat: number, lng: number}
     */
    lngLatArrToObj: arr => {
        if (!(typeof arr[1] === "number" && typeof arr[0] === "number")) {
            throw Error("Invalid parameter" + JSON.stringify(arr));
        }
        const retVal = { lat: arr[1], lng: arr[0] };
        if (retVal.lat <= retVal.lng) {
            debugOnlyTrace("invalid value if not in germany, it should be lat>lng", retVal);
        }
        return retVal;
    },

    /**
     * [lng, lat] => google.maps.LatLngLiteral
     * @param {number[]} arr
     * @returns {{lng: (function(): number), lat: (function(): number)}} {lat: () => number, lng: () => number}
     */
    lngLatArrToObjFn: arr => {
        if (!(typeof arr[1] === "number" && typeof arr[0] === "number")) {
            throw Error("Invalid parameter" + JSON.stringify(arr));
        }
        const retVal = { lat: () => arr[1], lng: () => arr[0] };
        if (retVal.lat <= retVal.lng) {
            debugOnlyTrace("invalid value if not in germany, it should be lat>lng", retVal);
        }
        return retVal;
    },

    /**
     * { lat: () => num, lng: () => num } => { lat: num, lng:num }
     * @param {google.maps.LatLng} latLng { lat: () => num, lng: () => num }
     * @returns {LatLngNum} simple numeric variables, no methods { lat: num, lng:num }
     */
    latLngFnToNum: latLng => {
        if (latLng === undefined || latLng.lat === undefined || latLng.lng === undefined) {
            throw Error("LatLng invalid: " + JSON.stringify(latLng));
        }
        const retVal = {
            lat: typeof latLng.lat === "function" ? latLng.lat() : latLng.lat,
            lng: typeof latLng.lng === "function" ? latLng.lng() : latLng.lng
        };
        if (retVal.lat <= retVal.lng) {
            debugOnlyTrace("invalid value if not in germany, it should be lat>lng", retVal);
        }
        return retVal;
    }
};

/**
 * Note: turf uses [lng, lat], I usually use [lat, lng]
 */
const distanceCalc = {
    /**
     * @deprecated LAT = North South
     * @param {any[]} originArrLatLng
     * @param {number} targetDistInCm
     * @returns {number} lng distance north/south
     */
    lngNorth: (originArrLatLng, targetDistInCm) => {
        const turfLngLat = [originArrLatLng[1], originArrLatLng[0]];
        const lngResult = turfDestination(turfLngLat, targetDistInCm, 90, {
            units: "centimeters"
        }).geometry.coordinates[0]; // [*lng, lat]
        return round6(lngResult); // disregarding 7+ precision to avoid test failures
    },

    /**
     * @deprecated LNG = West East
     *
     * Returns the delta between 'originArr' and a point 'targetDistInCm'-cm west/east of it.
     * @param {any[]} originArrLatLng
     * @param {number} targetDistInCm
     * @returns {number} lat distance west/east
     */
    latWest: (originArrLatLng, targetDistInCm) => {
        const turfLngLat = [originArrLatLng[1], originArrLatLng[0]];
        const latResult = turfDestination(turfLngLat, targetDistInCm, 0, {
            units: "centimeters"
        }).geometry.coordinates[1]; // [lng, *lat]
        return round6(latResult); // disregarding 7+ precision to avoid test failures
    }
};

export const orderObjectKeysShallow = object => {
    const result = {};
    const sortedKeys = [...Object.keys(object)].sort();
    for (const key in sortedKeys) {
        result[key] = object[key];
    }
    return result;
};

export const latLngFnToStr = latLng => "lat: " + latLng.lat() + ", lng:" + latLng.lng();

export const objToArr = latLngConversions.objToArr;
export const objToArrLngLat = latLngConversions.objToArrLngLat;
export const arrToObj = latLngConversions.arrToObj;
export const lngLatArrToObj = latLngConversions.lngLatArrToObj;
export const lngLatArrToObjFn = latLngConversions.lngLatArrToObjFn;

export const latLngFnToNum = latLngConversions.latLngFnToNum;
export const lngNorth = distanceCalc.lngNorth;
export const latWest = distanceCalc.latWest;

export const waitForElement = various.waitForElement;
export const round1 = various.round1;
export const round3 = various.round3;
export const round6 = various.round6;
export const round8 = various.round8;

export const pow2 = num => Math.pow(num, 2);

export const centimetersSqToMetersSq = cm => cm / 10000;

export default "Use specific imports";
