import { tlog } from "../elements/debugging";

/**
 * @typedef {import("scenes/common/customTypes").LatLngNum} LatLngNum
 */
export class OriginProvider {
    /**
     * @param {LatLngNum} origin
     */
    constructor(origin) {
        this.origin = origin;
        this.listeners = [];
    }

    /**
     * @returns {LatLngNum} origin
     */
    getOrigin = () => this.origin;
    /**
     * @param {LatLngNum} newOrigin
     */
    setOrigin = newOrigin => {
        this.origin = newOrigin;
        for (const listener of this.listeners) {
            if (typeof listener !== "function") {
                console.error("not a function", listener);
            } else {
                listener(this.origin);
            }
        }
    };

    addListener = listener => {
        if (typeof listener !== "function") {
            throw Error("Listener shall be function");
        }
        tlog("%cPushed a new listener", "font-size:20px, background: teal");
        this.listeners.push(listener);
    };
}
