import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MINIMUM_BORDER_THICKNESS } from "../../elements/arc/ArcConsts";
import { getInlineSVGsColorized } from "../SelectionOperationsPanel/InlineSVGs";
import { HideDirection, ToggleableContent } from "../ToggleableContent";
import { ArcButton } from "./ArcButton";
import "./index.scss";
import variables from "./index.scss";
const { designerElementButtonColor, designerElementButtonBackgroundColor } = variables;
const { RectanglePlus } = getInlineSVGsColorized(
    designerElementButtonColor,
    designerElementButtonBackgroundColor
);

/**
 * @typedef {import("../../logic/R2GM").default} R2GM
 * @param {{logic: R2GM }} param0
 */
export const ElementPanel = ({ logic }) => {
    const { t } = useTranslation();

    const defaultSize = 10;
    if (defaultSize < 7.5) {
        throw Error("Keep defaultSize > 7.5 to avoid issues");
    }
    let [circleDiameter, setCircleDiameter] = useState(defaultSize);
    let [rectangleEdgeLengthX, setRectangleEdgeLengthX] = useState(defaultSize);
    let [rectangleEdgeLengthY, setRectangleEdgeLengthY] = useState(defaultSize);

    const stepValue = value => 2.5 * Math.round((value > 2.5 ? value : 2.5) / 2.5);

    // ui
    const addCircleClick = angle => {
        circleDiameter = stepValue(circleDiameter);
        setCircleDiameter(circleDiameter);
        logic.addPolyArc(angle, circleDiameter / 2, true, MINIMUM_BORDER_THICKNESS);
    };

    const addRectangleClick = withHole => {
        setRectangleEdgeLengthX(stepValue(rectangleEdgeLengthX));
        setRectangleEdgeLengthY(stepValue(rectangleEdgeLengthY));
        logic.addRectangle(
            rectangleEdgeLengthX,
            rectangleEdgeLengthY,
            false, // 0 => false, 1 => true
            withHole
        );
    };

    return (
        <div className="elementOnMap" id="elementDrawer">
            <ToggleableContent hideDirection={HideDirection.LEFT}>
                <div className={"panelWrapper"}>
                    <div className="panelBlock">
                        <div className="blockTitle">{t("designer.panel.addElements.title")}</div>
                        <div className="subTitle">{t("designer.panel.addElements.subtitle")}</div>
                        <div className={"formPanel"}>
                            <button
                                id="addRectangle"
                                className="arcButtonWrapper"
                                onClick={() => {
                                    addRectangleClick(false);
                                }}
                            >
                                <RectanglePlus />
                            </button>
                            <button
                                id="addFullCircle"
                                className="arcButtonWrapper"
                                onClick={() => {
                                    addCircleClick(Math.PI * 2);
                                }}
                            >
                                <ArcButton arcRadius={2} />
                            </button>
                            <button
                                id="addThreeQuartersCircle"
                                className="arcButtonWrapper"
                                onClick={() => {
                                    addCircleClick(Math.PI * 1.5);
                                }}
                            >
                                <ArcButton arcRadius={1.5} />
                            </button>
                            <button
                                id="addHalfCircle"
                                className="arcButtonWrapper"
                                onClick={() => {
                                    addCircleClick(Math.PI * 1);
                                }}
                            >
                                <ArcButton arcRadius={1} />
                            </button>
                            <button
                                id="addOneQuarterCircle"
                                className="arcButtonWrapper"
                                onClick={() => {
                                    addCircleClick(Math.PI * 0.5);
                                }}
                            >
                                <ArcButton arcRadius={0.5} />
                            </button>
                            <button
                                id="addOneEithCircle"
                                className="arcButtonWrapper"
                                onClick={() => {
                                    addCircleClick(Math.PI * 0.25);
                                }}
                            >
                                <ArcButton arcRadius={0.25} />
                            </button>
                        </div>
                        <div className={"hint"}>{t("designer.panel.addElements.hint")}</div>
                    </div>
                </div>
            </ToggleableContent>
        </div>
    );
};

ElementPanel.propTypes = {
    logic: PropTypes.object
};

export default ElementPanel;
