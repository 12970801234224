import React from "react";
import "./index.scss";

let idx = 0;
/**
 * timer = ms till the spinner stops
 */
class Spinner extends React.Component {
    constructor(props) {
        super(props);
        this.idx = idx++;
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        this._setState = this.setState;
    }

    forceUpdateHandler() {
        this.reset();
    }

    reset() {
        if (this.timer) {
            clearInterval(this.timer);
        }
        this.start = this.setStartPosition();

        this._setState({
            position: this.start,
            timeRemaining: this.props.timer
        });

        this.timer = setInterval(() => {
            this.tick();
        }, 100);
    }

    state = {
        position: 0,
        lastPosition: null
    };
    static iconHeight = 282;
    multiplier = Math.floor(1); //((mult++)%3)+1;//Math.floor(Math.random() * (4 - 1) + 1);
    start = this.setStartPosition();

    setStartPosition() {
        return Math.floor(Math.random() * 9) * Spinner.iconHeight * -1;
    }

    moveBackground() {
        const speed = Spinner.iconHeight * this.multiplier;
        this._setState({
            position: this.state.position - speed,
            timeRemaining: this.state.timeRemaining - 100
        });
    }

    tick() {
        const modFirst = Spinner.iconHeight * 6; // 1692
        const end = modFirst + Spinner.iconHeight / 2;

        const thatPos = this.state.position; // - this.start;
        if (this.state.timeRemaining <= 0) {
            if (this.state.position !== 0 && Math.abs(thatPos % end) === 0) {
                this._setState({
                    position: this.state.position,
                    timeRemaining: -1,
                    finished: true
                });
                clearInterval(this.timer);
                this.timer = undefined;
                this.props.onFinish(this.state.position);
            } else {
                this.moveBackground();
            }
        } else {
            this.moveBackground();
        }
    }

    componentDidMount() {
        clearInterval(this.timer);
        this.timer = undefined;
        this._setState({
            position: this.start,
            timeRemaining: this.props.timer,
            finished: false
        });

        if (this.props.doSpin === true) {
            this.startSpinning();
        }
    }

    componentDidUpdate() {
        if (this.props.doSpin === true && this.timer === undefined && !this.state.finished) {
            this.startSpinning();
        }
        if (this.props.doStopSpinning && this.state.timeRemaining !== -1) {
            this.beginStopping();
        }
    }

    startSpinning() {
        clearInterval(this.timer);
        this.timer = undefined;
        this._setState({
            position: this.start,
            timeRemaining: this.props.timer,
            finished: false
        });
        this.timer = setInterval(() => {
            this.tick();
        }, 100);
    }
    beginStopping() {
        this._setState({ timeRemaining: -1 });
    }

    render() {
        let { position } = this.state;

        return (
            <div
                style={{ backgroundPosition: "0px " + position + Spinner.iconHeight / 2 + "px" }}
                className={`icons`}
            />
        );
    }
}

class App extends React.Component {
    constructor(props) {
        super(props);

        this.finishHandler = this.finishHandler.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.spinningSpinners = 3;
        this.spinnerResult = [];
        this.allSpinnersFinishedCallback = props.finishedSpinningCB;
    }

    handleClick() {
        this.emptyArray();
        this._child1.forceUpdateHandler();
        this._child2.forceUpdateHandler();
        this._child3.forceUpdateHandler();
    }

    static matches = [];

    finishHandler(value) {
        this.spinnerResult.push(value);
        if (--this.spinningSpinners === 0) {
            if (this.spinnerResult.length === 3) {
                const hasWon = this.spinnerResult.every(el => el === this.spinnerResult[0]);
                this.allSpinnersFinishedCallback(hasWon);
            } else {
                this.allSpinnersFinishedCallback(false);
            }
        }
    }

    emptyArray() {
        App.matches = [];
    }

    render() {
        const doSpin = this.props.active.slotActive;
        const doStopSpinning = this.props.calculationDone;

        return (
            <div className="slotMachine">
                <div className={`spinner-container`}>
                    <Spinner
                        doSpin={doSpin}
                        doStopSpinning={doStopSpinning}
                        onFinish={this.finishHandler}
                        ref={child => {
                            this._child1 = child;
                        }}
                        timer="1000"
                    />
                    <Spinner
                        doSpin={doSpin}
                        doStopSpinning={doStopSpinning}
                        onFinish={this.finishHandler}
                        ref={child => {
                            this._child2 = child;
                        }}
                        timer="1400"
                    />
                    <Spinner
                        doSpin={doSpin}
                        doStopSpinning={doStopSpinning}
                        onFinish={this.finishHandler}
                        ref={child => {
                            this._child3 = child;
                        }}
                        timer="2000"
                    />
                    <div className="gradient-fade"></div>
                </div>
                {/* <h1 style={{'position':'fixed', bottom: 0, right: 0}}>{JSON.stringify(this.props)}</h1> */}
            </div>
        );
    }
}
export default App;
export const SlotmachineHoffman = App;
