import { defaultGridZoomLevel } from "Configuration";
import { defaultSelectionColor } from "../elements/common/DefaultPolySettings";
import {dbg, tlog} from "../elements/debugging";
import {
    allElementsAreConnected,
    hasAnyOverlappingElements,
    hasElementOverlappingElements
} from "./IntersectionLogic";
import SaveStateManager from "./SaveStateManager";

/**
 * Shall hold the current, *reproducable* state of all polygons.
 * As such it shall receive elements decorated by the PolygonCreator
 * with information on how to recreate each polygon the ASM receives.
 * The Elements shall hold the data required to reproduce the elements.
 * E.g. the ASM holds a circle, the circle holds the information, that
 * it has a radius of 250cm, scaled two times, with last known position
 * at [57.123, 8.321].
 * This information can then be extracted by the ASM to be persisted to a
 * JSON.
 * Later this JSON then can be read again, passed to the PolygonCreator,
 * which then will recreate all the elements and pass them to the ASM as
 * if they where just created via the GUI.
 * @class
 */
export class AmazingStateManagement {
    constructor({ map, originProvider, handleDataChange }) {
        this.elements = [];
        /**
         * @type {Grid|undefined} grid
         */
        this.grid = undefined;
        this.lastSelected = undefined;
        this.lastSelectedListeners = [];
        this.map = map;
        this.polyconCreator = new SaveStateManager(this);
        this.handleDataChange = handleDataChange;
        this.originProvider = originProvider;
        this.originProvider.addListener(newCenter => {
            console.warn("~ ASM~originProviderListener~gridUpdate");
            this.grid.recenter(newCenter);
        });
    }

    // /**
    //  * Naiver ansatz
    //  */
    // separateElementCount() {
    //     return countSeparateElements(this.elements);
    // }

    restoreSelectionColorization() {
        if (this.lastSelected) {
            this.lastSelected.setOptions({
                strokeColor: defaultSelectionColor
            });
        }
    }

    /**
     * We only need to know if any elements are disconnected.
     * How many is optional.
     */
    hasAllElementsConnected() {
        return allElementsAreConnected(this.elements);
    }

    /**
     * We only need to know if any elements are overlapping.
     * How many is optional. (Still iterating over all to highlight all)
     */
    hasOverlappingElements() {
        const result = hasAnyOverlappingElements(this.elements);
        this.restoreSelectionColorization();
        return result;
    }

    hasElementOverlappingElements(element) {
        return hasElementOverlappingElements(element, this.elements);
    }

    updateHandleDataChangeImpl(fn) {
        this.handleDataChange = fn;
    }

    setCenter(center) {
        dbg( () => console.warn("♦️ AmazingStateManagement#setCenter ?", center));
        this.map.setCenter(center);
        this.grid.recenter(center);
    }

    getCenter() {
        return this.map.getCenter();
    }

    zoomToGrid() {
        this.map.setZoom(defaultGridZoomLevel);
    }

    setGrid(grid) {
        grid.setMap(this.map);
        this.grid = grid;
    }

    toggleGridVisibility() {
        this.grid.toggleVisibility();
    }

    addElement(element) {
        if (!this.map) {
            console.error("Map undefined");
        }
        // Bind element to map
        element.setMap(this.map);

        this.elements.push(element);

        const evt = () => {
            this.newSelectionHandler(element);
        };

        element.addListener("mousedown", () => {
            evt();
            return false;
        });
        this.newSelectionHandler(element);

        tlog("🔁 addElement>handleDataChange");
        this.handleDataChange();
        return element;
    }
    newSelectionHandler(newSelection) {
        // deselect last element
        if (this.lastSelected) {
            this.lastSelected.setOptions({
                strokeColor: this.lastSelected.creationParams.drawingDefaults.strokeColor
            });
        }
        // select current element
        this.lastSelected = newSelection;
        newSelection.setOptions({ strokeColor: defaultSelectionColor });

        this.triggerSelectionListeners();
    }
    triggerSelectionListeners() {
        for (const selectionListener of this.lastSelectedListeners) {
            selectionListener.onTrigger(this.lastSelected);
        }
    }

    /**
     * Add a function to be called on selection change
     * @param {string} key
     * @param {function} listenerFn
     */
    addSelectionListener(key, onTrigger) {
        if (typeof key === "string" && typeof onTrigger === "function") {
            const match = this.lastSelectedListeners.filter(e => e.key === key);

            if (match.length === 0) {
                this.lastSelectedListeners.push({ key, onTrigger });
            } else {
                match.onTrigger = onTrigger;
            }
        } else {
            throw Error("Invalid type");
        }
    }
    removeSpecific(el) {
        if (!el) {
            return;
        }
        if (el === this.lastSelected) {
            this.removeSelected();
        } else {
            el.parent.removeFromMap()
            const idx = this.elements.indexOf(el);

            if (this.elements.length > 1) {
                this.elements.splice(idx, 1); // remove 1 element from array at index
            } else {
                this.elements = [];
            }
            this.handleDataChange();
        }
    }
    removeSelected() {
        if (this.lastSelected) {
            this.lastSelected.parent.removeFromMap();

            const idx = this.elements.indexOf(this.lastSelected);

            if (this.elements.length > 1) {
                this.elements.splice(idx, 1); // remove 1 element from array at index
            } else {
                this.elements = [];
            }
            delete this.lastSelected; // remove from this object
            const nextInArray = this.elements[this.elements.length - 1];
            if (nextInArray) {
                this.newSelectionHandler(this.elements[this.elements.length - 1]);
            }
            this.handleDataChange();
            this.triggerSelectionListeners();
        }
    }
    removeAllElements() {
        let el = this.elements.pop();
        while (el) {
            el.parent.removeFromMap();
            el = this.elements.pop();
        }
        this.lastSelected = undefined;
        tlog("🔁 removeAllElements>handleDataChange");
        this.handleDataChange();
        this.triggerSelectionListeners();
    }
}

export default AmazingStateManagement;
