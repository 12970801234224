import React, {useEffect, useState} from 'react';
import Modal, {Styles} from "react-modal";
import {InputWrapper} from "../InputWrapper";
import {useTranslation} from "react-i18next";
import {Button} from "../Button/Button";
import {useHistory} from "react-router-dom";

import "./index.scss";

interface LoadDesignerCodeModalProps {
  isOpen: boolean
  onClose: () => void
}

export const LoadDesignerCodeModal: React.FC<LoadDesignerCodeModalProps> = ({isOpen, onClose}) => {
  const [code, setCode] = useState("")
  const history = useHistory();
  const {t} = useTranslation();
  useEffect(() => {
    Modal.setAppElement("#appContainer");
  });

  return (
    <div className={"loadCodeModal"}>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={customStyles}
        contentLabel="saveId"
      >
        <div className={"loadPopup"}>
          <h1>{t("modals.load.title")}</h1>
          <p>{t("modals.load.explainer")}</p>
          <InputWrapper label={"Code"}
                        onChange={(e: any) => {
                          setCode(e.target.value)
                        }}
                        name={"code"}
                        value={code}
                        inputType={"text"}/>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <div onClick={onClose} className={"modal-close"}>
              <Button color={"orange"}>{t("app.general.close")}</Button>
            </div>
            <div onClick={() => {
              history.push(`/designer?saveId=${code}`)
              window.location.reload();
              onClose();
            }}
                 className={"modal-close"}>
              <Button color={"orange"}>{t("modals.load.loadCode")}</Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};


const customStyles: Styles = {
  content: {
    position: "absolute",
    top: "40px",
    left: "40px",
    right: "40px",
    bottom: "40px",
    border: "1px solid #ccc",
    background: "#fff",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "4px",
    outline: "none",
    padding: "20px",
    height: "600px",
    margin: "0 auto",
    width: "700px"
    // top: '50%',
    // left: '50%',
    // right: 'auto',
    // bottom: 'auto',
    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)'
  }
};
