import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { minRotationAngle } from "Configuration";
import { toggleArcHole } from "../../elements/arc/ArcMethods";
import polyExpand from "../../elements/common/logic/polyExpand";
import polyRotation from "../../elements/common/logic/polyRotation";
import polyScale from "../../elements/common/logic/polyScale";
import {
    toggleRectangleHole
} from "../../elements/rectangle/RectangleMethods";
import { HideDirection, ToggleableContent } from "../ToggleableContent";
import "./index.scss";
import variables from "./index.scss";
import { getInlineSVGsColorized } from "./InlineSVGs";
import { JoinedButton } from "./JoinedButton";
import { ARC_TYPE, THREE_CELLS } from "../../elements/arc/ArcConsts";
import SelectionDetails from './SelectionDetails';
const { designerManipulationButtonColor } = variables;
const {
    RotateLeft,
    RotateRight,
    ScaleDownHorizontal,
    ScaleDownVertical,
    ScaleUpVertical,
    ScaleUpHorizontal
} = getInlineSVGsColorized(designerManipulationButtonColor);

/**
 * @typedef {import("../../state/AmazingStateManagement").default} AmazingStateManagement
 * @param {{asmgmt: AmazingStateManagement|undefined}} param0
 */
const SelectionOperationsPanel = ({ asmgmt, logic, saveState, totalArea }) => {
    const { t } = useTranslation();
    const [selectedEl, setSelectedEl] = useState(asmgmt.lastSelected);

    const crParams = selectedEl && selectedEl.creationParams;

    const isRectangle = !!selectedEl && crParams.type === "rectangle";
    const isPolyArc = !!selectedEl && crParams.type === ARC_TYPE;
    const isExpandable = isRectangle || (isPolyArc && crParams.angleEnd === Math.PI * 2);
    const isCurvable = isRectangle;
    const hasCurvedCorners = isRectangle && crParams.useRoundedCorners;
    const isRectHoleSized =
        isRectangle &&
        crParams.rectangleDistCmX >= (hasCurvedCorners ? 750 : 750) &&
        crParams.rectangleDistCmY >= (hasCurvedCorners ? 750 : 750);
    const isArcHoleSized = isPolyArc && crParams.radiusInCm * 2 >= THREE_CELLS;
    const isHoleSized = isRectHoleSized || isArcHoleSized;
    const hasHole = crParams ? crParams.withHole : false; //isRectangle && crParams.withHole;

    asmgmt.addSelectionListener("selectionOperationsPanel", newSelection => {
        setSelectedEl(newSelection);
    });

    const rotate = degrees => polyRotation(selectedEl, degrees, asmgmt.handleDataChange);

    window.debug.rotateNeg90 = () => rotate(-90); // todo: removeme
    window.debug.rotate90 = () => rotate(90); // todo: removeme

    const scale = scale => polyScale(selectedEl, scale, asmgmt.handleDataChange);
    /*** expandEast: true = expand East , false = expand South */
    const expand = (twoPointFiveMeterExpansionCount, expandEast) =>
        polyExpand(
            selectedEl,
            twoPointFiveMeterExpansionCount,
            expandEast,
            asmgmt.handleDataChange
        );
    /*** shrinkWest: true = shrink West , false = shrink North */
    const shrink = (twoPointFiveMeterExpansionCount, shrinkWest) =>
        polyExpand(
            selectedEl,
            -twoPointFiveMeterExpansionCount,
            shrinkWest,
            asmgmt.handleDataChange
        );

    const toggleHole = () => {
        if (selectedEl.creationParams.type === "rectangle") {
            toggleRectangleHole(logic, selectedEl);
        } else {
            toggleArcHole(logic, selectedEl);
        }
    };

    const thickenBorder = () => {
        if (selectedEl.creationParams.type === "rectangle") {
            /**
             * @type {RectangleClass}
             */
            const rectClass = selectedEl.parent;
            rectClass.thickenBorder();
        } else {
            const arcCreationParams = selectedEl.creationParams;
            arcCreationParams.thickenBorder();
        }
        // Update state for 2->3->2 transitions
        asmgmt.handleDataChange();
    };
    const slimBorder = () => {
        if (selectedEl.creationParams.type === "rectangle") {
            /**
             * @type {RectangleClass}
             */
            const rectClass = selectedEl.parent;
            rectClass.slimBorder();
        } else {
            const arcCreationParams = selectedEl.creationParams;
            arcCreationParams.slimBorder();
        }
        // Update state for 2->3->2 transitions
        asmgmt.handleDataChange();
    };

    return (
        <div className="elementOnMap" id="selectionManipulation">
            <ToggleableContent hideDirection={HideDirection.DOWN}>
                <div className="selectionManipulationWidthFixer">
                    <p>{t("designer.panel.manipulation.title")}</p>
                    <div className={"flex flex-center align-items-start"}>
                        <SelectionDetails selectedEl={selectedEl}/>
                        <div className={"area-description"}>
                            <aside className={"flex flex-center"}>{totalArea}m²</aside>
                            <span>{t("designer.panel.description.size")}</span>
                        </div>
                        <JoinedButton>
                            <div>
                                <div>
                                    <button
                                        onClick={() => {
                                            rotate(-minRotationAngle);
                                        }}
                                    >
                                        <RotateLeft />
                                    </button>
                                    <button
                                        onClick={() => {
                                            rotate(+minRotationAngle);
                                        }}
                                    >
                                        <RotateRight />
                                    </button>
                                </div>
                                <span>{t("designer.panel.description.rotate")}</span>
                            </div>
                        </JoinedButton>
                        <JoinedButton>
                            <div>
                                <div>
                                    <button
                                        onClick={() => {
                                            scale(-1);
                                        }}
                                        id={"scale-up"}
                                    >
                                        <i className="ri-subtract-line"></i>
                                    </button>
                                    <button
                                        onClick={() => {
                                            scale(1);
                                        }}
                                    >
                                        <i className="ri-add-line"></i>
                                    </button>
                                </div>
                                <span>{t("designer.panel.description.area")}</span>
                            </div>
                        </JoinedButton>
                        {isRectangle && (
                            <React.Fragment>
                                <JoinedButton style={isExpandable ? {} : { visibility: "hidden" }}>
                                    <div>
                                        <div>
                                            <button
                                                onClick={() => {
                                                    shrink(1, true);
                                                }}
                                            >
                                                <ScaleDownHorizontal />
                                            </button>
                                            <button
                                                onClick={() => {
                                                    expand(1, true);
                                                }}
                                            >
                                                <ScaleUpHorizontal />
                                            </button>
                                        </div>
                                        <span>
                                            {t(
                                                "designer.panel.description.rectangle.scalehorizontal"
                                            )}
                                        </span>
                                    </div>
                                </JoinedButton>
                                <JoinedButton style={isExpandable ? {} : { visibility: "hidden" }}>
                                    <div>
                                        <div>
                                            <button
                                                onClick={() => {
                                                    shrink(1, false);
                                                }}
                                            >
                                                <ScaleDownVertical />
                                            </button>
                                            <button
                                                onClick={() => {
                                                    expand(1, false);
                                                }}
                                            >
                                                <ScaleUpVertical />
                                            </button>
                                        </div>
                                        <span>
                                            {t(
                                                "designer.panel.description.rectangle.scalevertical"
                                            )}
                                        </span>
                                    </div>
                                </JoinedButton>
                            </React.Fragment>
                        )}
                        {!isRectangle && (
                            <React.Fragment>
                                <JoinedButton style={isExpandable ? {} : { visibility: "hidden" }}>
                                    <div>
                                        <div>
                                            <button
                                                onClick={() => {
                                                    shrink(1, true);
                                                }}
                                            >
                                                <ScaleDownHorizontal />
                                            </button>
                                            <button
                                                onClick={() => {
                                                    shrink(1, false);
                                                }}
                                            >
                                                <ScaleDownVertical />
                                            </button>
                                        </div>
                                        <span>
                                            {t("designer.panel.description.circle.scalehorizontal")}
                                        </span>
                                    </div>
                                </JoinedButton>
                                <JoinedButton style={isExpandable ? {} : { visibility: "hidden" }}>
                                    <div>
                                        <div>
                                            <button
                                                onClick={() => {
                                                    expand(1, false);
                                                }}
                                            >
                                                <ScaleUpVertical />
                                            </button>
                                            <button
                                                onClick={() => {
                                                    expand(1, true);
                                                }}
                                            >
                                                <ScaleUpHorizontal />
                                            </button>
                                        </div>
                                        <span>
                                            {t("designer.panel.description.circle.scalevertical")}
                                        </span>
                                    </div>
                                </JoinedButton>
                            </React.Fragment>
                        )}
                        {hasHole && (
                            <JoinedButton>
                                <div className={"flex-row"}>
                                    <div className={"flex-col"}>
                                        <button
                                            onClick={() => {
                                                thickenBorder();
                                            }}
                                            id={"increase-border-width"}
                                        >
                                            <i className="ri-add-line"></i>
                                        </button>
                                        <button
                                            onClick={() => {
                                                slimBorder();
                                            }}
                                        >
                                            <i className="ri-subtract-line"></i>
                                        </button>
                                    </div>
                                    <span>{t("designer.panel.description.border")}</span>
                                </div>
                            </JoinedButton>
                        )}
                        {(isHoleSized || hasHole) && (
                            <button
                                onClick={() => {
                                    toggleHole();
                                }}
                                id={"toggleHole"}
                            >
                                {!hasHole &&
                                    t("designer.panel.manipulation.addHole", {
                                        escapeInterpolation: false
                                    })}
                                {hasHole && t("designer.panel.manipulation.removeHole")}
                            </button>
                        )}
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column"
                            }}
                        >
                            <button
                                onClick={() => {
                                    asmgmt.removeSelected();
                                }}
                            >
                                <i className="ri-delete-bin-line" style={{ fontSize: "18px" }} />
                            </button>
                            <span
                                style={{
                                    display: "inline-flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                    padding: "6px",
                                    fontSize: "14px"
                                }}
                            >
                                {t("designer.actions.deleteElement")}
                            </span>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column"
                            }}
                        >
                            <button
                                onClick={() => {
                                    saveState();
                                }}
                            >
                                <i className="ri-save-3-fill" style={{ fontSize: "18px" }} />
                            </button>
                            <span
                                style={{
                                    display: "inline-flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                    padding: "6px",
                                    fontSize: "14px"
                                }}
                            >
                                {t("designer.actions.save")}
                            </span>
                        </div>
                    </div>
                </div>
            </ToggleableContent>
        </div>
    );
};

SelectionOperationsPanel.propTypes = {
    asmgmt: PropTypes.object.isRequired,
    logic: PropTypes.object.isRequired
};
export default SelectionOperationsPanel;
