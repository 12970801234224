import React from "react";
import PropTypes from "prop-types";
import { Redirect, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { initialTotalArea } from "AppState";
import { Button } from "components/Button/Button";
import { paths } from "IceRinkApp/route_consts";
import { isDebugging } from "scenes/2_setup_designer/designer/elements/debugging";
import { FormGroup } from "../../components/FormGroup";
import { InputWrapper } from "../../components/InputWrapper";
import calIcon from "assets/Icon_Kalender.png";
import ticketIcon from "assets/Icon_Ticket.png";
import skateIcon from "assets/Icon_Schlittschuhe.png";
import locationIcon from "assets/Icon_Ortschaft.png";

import "./index.scss";
import { useGA } from "scenes/common/useGA";

const GuestInputsScene = ({ form, onFormChangeHandler, readOnlyFields }) => {
    const { t } = useTranslation();
    const NOP = () => {};
    useGA();

    const { areaInSquareMeters, circumfenceInMeters } = readOnlyFields;

    // If the area is not yet set, we just redirect the user to the previous page
    // so he can click the "next" button to trigger the area calculation
    if (areaInSquareMeters === initialTotalArea) {
        return <Redirect to={paths.step2} />;
    }

    return (
        <div className="container page3">
            <div className="innerContainer">
                <div className="smallSideMargin">
                    <h1>{t("app.headline.parameters")}</h1>
                    <h3 className="introText tc2 highlight">{t("app.description.parameters")}</h3>
                    <form>
                        <FormGroup
                            title={t("parameters.titles.measures")}
                            icon={<img src={locationIcon} />}
                        >
                            {/* "Fläche"*/}
                            <InputWrapper
                                name={"areaInSquareMeters"}
                                label={t("parameters.label.areaInSquareMeters")}
                                type={"number"}
                                value={areaInSquareMeters}
                                onChange={NOP}
                                disabled={true}
                            />
                            {/* "Umfang"*/}
                            <InputWrapper
                                name={"circumfenceInMeters"}
                                label={"Debugging:" + t("parameters.label.circumfenceInMeters")}
                                type={"number"}
                                value={Math.floor(circumfenceInMeters / 10) * 10}
                                onChange={NOP}
                                disabled={true}
                                style={{
                                    display: isDebugging() ? "block" : "none"
                                }}
                                data-circumfence={circumfenceInMeters}
                            />
                        </FormGroup>
                        <FormGroup
                            title={t("parameters.titles.rentTime")}
                            icon={<img src={calIcon} />}
                        >
                            {" "}
                            {/* "Mietdauer"*/}
                            <InputWrapper
                                name={"rentalTimeInWeeks"}
                                label={t("parameters.label.rentalTimeInWeeks")}
                                type={"number"}
                                value={form.rentalTimeInWeeks}
                                onChange={onFormChangeHandler}
                            />
                            <InputWrapper
                                name={"rentalTimeInDays"}
                                label={t("parameters.label.rentalTimeInDays")}
                                type={"number"}
                                value={form.rentalTimeInWeeks * 7}
                                onChange={NOP}
                                disabled={true}
                            />
                        </FormGroup>
                        <FormGroup
                            title={t("parameters.titles.entryFee")}
                            icon={<img src={ticketIcon} />}
                        >
                            {/* "Eintrittspreise"*/}
                            <InputWrapper
                                name={"entryFeeChildren"}
                                label={t("parameters.label.entryFeeChildren")}
                                type={"number"}
                                value={form.entryFeeChildren}
                                onChange={onFormChangeHandler}
                            />
                            <InputWrapper
                                name={"entryFeeAdults"}
                                label={t("parameters.label.entryFeeAdults")}
                                type={"number"}
                                value={form.entryFeeAdults}
                                onChange={onFormChangeHandler}
                            />
                        </FormGroup>
                        <FormGroup
                            title={t("parameters.titles.rentalFees")}
                            icon={<img src={skateIcon} />}
                        >
                            <InputWrapper
                                name={"rentalFeeSkates"}
                                label={t("parameters.label.rentalFeeSkates")}
                                type={"number"}
                                value={form.rentalFeeSkates}
                                onChange={onFormChangeHandler}
                            />
                            <InputWrapper
                                name={"rentalFeeSkatingAids"}
                                label={t("parameters.label.rentalFeeSkatingAids")}
                                type={"number"}
                                value={form.rentalFeeSkatingAids}
                                onChange={onFormChangeHandler}
                            />
                        </FormGroup>
                    </form>
                </div>
                <div className="lowerArea smallSideMargin">
                    <div className="navbar">
                        <Button color={"orange"} className={"prev"}>
                            <NavLink to={paths.step2}>{t("app.buttons.previousStep")}</NavLink>
                        </Button>
                        <Button color={"orange"} className={"next"}>
                            <NavLink to={paths.step4}>{t("app.buttons.step3.nextStep")}</NavLink>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

GuestInputsScene.propTypes = {
    form: PropTypes.object,
    onFormChangeHandler: PropTypes.func,
    readOnlyFields: PropTypes.object
};

export default GuestInputsScene;
