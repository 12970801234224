import distance from "@turf/distance";
import { point } from "@turf/helpers";
import { isDebugging } from "../debugging";
const turf = {
    point
};

const LAT_IDX = 1;
const LNG_IDX = 0;

/**
 * @param {turf.Feature<turf.Point, {[name: string]: any;}>} point */
const lat = point => point.geometry.coordinates[LAT_IDX];
/**
 * @param {turf.Feature<turf.Point, {[name: string]: any;}>} point  */
const lng = point => point.geometry.coordinates[LNG_IDX];

export class TurfHelper {
    /**
     *
     * @param {boolean} forLat true = lateral distance false = longitude distance
     * @param {*} p1
     * @param {*} p2
     * @returns {number} distance in meters
     */
    calculateDistance(forLat, p1, p2) {
        const p1T = this.duckTypeConversion(p1);
        const p2T = this.duckTypeConversion(p2);

        if (forLat) {
            /**
             * North South distance => LNG fixed, LAT diff
             * |1┌--┬    |1┌       |1┌-----┐ |    (lat)
             * |    ?    |left     |    right| (-lng)┼(lng)
             * |    ┴--┘2| └-----┘2|       ┘2|   (-lat)
             */
            const leftTop = p1T;
            const leftBottom = turf.point([lng(p1T), lat(p2T)]);
            const leftDist = distance(leftTop, leftBottom, { units: "meters" });

            const rightTop = turf.point([lng(p2T), lat(p1T)]);
            const rightBottom = p2T;
            const rightDist = distance(rightTop, rightBottom, {
                units: "meters"
            });

            const avgDist = (Math.abs(leftDist) + Math.abs(rightDist)) / 2;
            return avgDist;
        } else {
            /**
             * West East distance => LAT fixed, LNG diff
             * |1┌       |1┌ top ┐ |1┌        |    (lat)
             * | ├--?--┤ |       ┆ | ┆        | (-lng)┼(lng)
             * |       ┘2|       ┘2| └bottom┘2|   (-lat)
             */
            const topLeft = p1T;
            const topRight = turf.point([lng(p2T), lat(p1T)]);
            const topDist = distance(topLeft, topRight, { units: "meters" });

            const bottomLeft = turf.point([lng(p1T), lat(p2T)]);
            const bottomRight = p2T;
            const bottomDist = distance(bottomLeft, bottomRight, {
                units: "meters"
            });

            const avgDist = (Math.abs(topDist) + Math.abs(bottomDist)) / 2;
            return avgDist;
        }
    }

    /**
     * ..
     * @param {any} p1
     * @param {any} p2
     * @returns {number} distance in meters
     */
    calculateLatDistance(p1, p2) {
        return this.calculateDistance(true, p1, p2);
    }

    /**
     * ..
     * @param {any} p1
     * @param {any} p2
     * @returns {number} distance in meters
     */
    calculateLngDistance(p1, p2) {
        return this.calculateDistance(false, p1, p2);
    }

    /**
     *
     * @param {any} point the duck
     * @returns {turf.Feature<turf.Point, {[name: string]: any;}>}Turf Point
     */
    duckTypeConversion(point) {
        const lng = typeof point.lng === "function" ? point.lng() : point.lng;
        const lat = typeof point.lat === "function" ? point.lat() : point.lat;
        // Todo: Verify validity of lng (-180 to 180) and lat (-90 to 90)
        if (lng < -180 || 180 < lng) {
            throw Error("Longitude only valid between -180 and 180");
        }
        if (lat < -90 || 90 < lat) {
            throw Error("Latitude only valid between -90 and 90");
        }
        if (lat <= lng && isDebugging()) {
            console.warn("invalid value if not in germany, it should be lat>lng", lat, ">", lng);
        }

        return turf.point([lng, lat]);
    }
}
