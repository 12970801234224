/**
 * Bound via script tag in index.html
 */
export const USING_GOOGLE_MAPS_API_KEY = !!window.mapsKey;

export const protocolAndHost = `${document.location.protocol}//${document.location.hostname}`;

/**
 * Used to determine if center has been modified + as start point
 * 52.272661, 8.672762
 */
export const defaultStartPosition = { lat: 52.272661, lng: 8.672762 };

/**
 * 2.5m blocks
 */
export const defaultGridSizeInBlocks = 96; // 96*2.5 = 240m

/**
 * Default language for internationalization in i18next
 */
export const i18nDefaultLang = "en";

export const minRotationAngle = 3.75;

/**
 * Default map zoom-level
 */
export const defaultZoomLevel = localStorage.getItem("debug") ? 20 : 13;
/**
 * Zoom-level at which the screen is filled with grid
 */
export const defaultGridZoomLevel = 18;

export const defaultErrorMarginCm = 125;

/**
 * Default Map settings for LocationPicker map
 */
const defaultMapOptions = google => ({
    rotateControl: false,
    clickableIcons: false, // disable POI popup
    tilt: 0,

    // The following don't work for some reason (should work http://jsfiddle.net/9gnhwkfp/1/)
    scaleControl: true,
    mapTypeControl: true,
    mapTypeControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT,
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
    },
    streetViewControl: false,
    keyboardShortcuts: false
});

export const locationPickerMapOptionDefaults = google => ({
    ...defaultMapOptions(google),
    draggable: true
});
export const designerMapOptionDefaults = google => ({
    ...defaultMapOptions(google),
    draggable: false,
    minZoom: 16,
    maxZoom: 21
});

export const previewMapOptionDefaults = google => ({
    ...defaultMapOptions(google),
    draggable: false,
    mapTypeControl: false,
    disableDefaultUI: true,
    minZoom: 18,
    maxZoom: 18,
    zoom: 18,
    mapTypeId: google.maps.MapTypeId.HYBRID
});

/**
 * Slotmachine Settings
 */
export const SlotmachineType = {
    NONE: 0,
    ASCII: 1,
    HOFFMAN: 2
};

export const cfgSlotMachine = SlotmachineType.HOFFMAN;
