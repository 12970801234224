import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {BrowserRouter as Router, useHistory} from "react-router-dom";
import Modal from "react-modal";

import {Button} from "components/Button/Button";
import {IceRinkApp} from "IceRinkApp";
import {whenStateLoadedViaURLParam} from "IceRinkApp/StateRetrieval";
import {paths} from "IceRinkApp/route_consts";
import "./index.scss";

const PlaceHolder = () => {
  const {t} = useTranslation();
  const customStyles = {
    content: {
      top: "30%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center"
    }
  };
  return (
    <div className="blocking-fullscreen">
      <div className="loading-spinner"/>
      <Modal isOpen={true} contentLabel="" style={customStyles}>
        <h2>:(</h2>
        <div id="">{t("app.errors.noConfigById")}</div>
        <Button color={"orange"}>
          <a href={paths.root}>{t("app.buttons.backToRoot")}</a>
        </Button>
      </Modal>
    </div>
  );
};

const IceRinkSuspense = () => {
  // 1. First Load => display <Placeholder>, process urlParams, init fetch
  const [suspenseState, setSuspenseState] = useState({stateTracked: null});
  const history = useHistory();
  if (suspenseState.stateTracked === null) {
    whenStateLoadedViaURLParam()
      .then(result => {
        setSuspenseState(result);
        // 2. Fetch returns at some random point of time, MyAmazingSuspense.setState($result)
      })
      .catch(e => {
        history.push(paths.step1)
        console.error("FATAL!" + JSON.stringify(e));
        window.location.reload()
      });
  }

  if (suspenseState.stateTracked === null) {
    return <PlaceHolder/>;
  } else if (suspenseState.holdsNewState) {
    // 3. MyAmazingSuspense is reloaded with new state, pass state to IceRinkApp (first call to that)
    return <IceRinkApp loadedState={suspenseState.stateTracked}/>;
  } else {
    // 3. MyAmazingSuspense is reloaded with new state, pass state to IceRinkApp (first call to that)
    return <IceRinkApp loadedState={null}/>;
  }

  // 4. MyAmazingSuspense *should* not have to reload from now on
};

/**
 * This element wraps the actual `IceRinkApp` in an Router.
 * This way we can access/manipulate the browser-history.
 */
export default function Routing() {
  return (
    <Router>
      <IceRinkSuspense/>
    </Router>
  );
}
