import React, { useEffect, useCallback, useState } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { HideDirection, ToggleableContent } from "../ToggleableContent";
import { Button } from "components/Button/Button";
import "./index.scss";

/**
 *
 * @typedef {import("../../state/AmazingStateManagement").default} AmazingStateManagement
 */

/**
 * @param {{asmgmt: AmazingStateManagement}} param0
 */
export const GlobalOperationsPanel = ({ asmgmt }) => {
    const { t } = useTranslation();

    const [isAlertModalVisible, setisAlertModalVisible] = useState(false);
    const confirm = useCallback(() => {
        setisAlertModalVisible(true);
    });
    const hideAlert = useCallback(() => setisAlertModalVisible(false));

    const deleteAllElements = useCallback(() => {
        asmgmt.removeAllElements();
        setisAlertModalVisible(false);
    });

    return (
        <div className="elementOnMap" id="globalThings">
            <Confirm modalOpen={isAlertModalVisible} onClose={hideAlert}>
                <h1>{t("modals.confirm.title")}</h1>
                <p>{t("designer.confirm.deleteAll")}</p>
                <div className={"modal-close"}>
                    <div onClick={hideAlert}>
                        <Button color={"orange"}>{t("app.general.close")}</Button>
                    </div>
                    <div onClick={deleteAllElements}>
                        <Button color={"orange"}>{t("app.general.confirm")}</Button>
                    </div>
                </div>
            </Confirm>
            <ToggleableContent hideDirection={HideDirection.RIGHT}>
                <button
                    id="toggleGridVisibility"
                    onClick={() => {
                        asmgmt.toggleGridVisibility();
                    }}
                >
                    {t("designer.panel.global.toggleGrid")}
                </button>
                <button
                    id="removeAllElements"
                    onClick={() => {
                        confirm();
                    }}
                >
                    <span role="img" aria-label="remove all">
                        {t("designer.panel.global.removeAllElements")}
                    </span>
                </button>
                {/* <button
                    style={{ width: "initial" }}
                    id="zoomToGrid"
                    onClick={() => asmgmt.zoomToGrid()}
                >
                    {t("designer.panel.global.zoomToGrid")}
                </button> */}
            </ToggleableContent>
        </div>
    );
};

GlobalOperationsPanel.propTypes = {
    asmgmt: PropTypes.object.isRequired
};

export default GlobalOperationsPanel;

const customStyles = {
    content: {
        position: "absolute",
        top: "40px",
        left: "40px",
        right: "40px",
        bottom: "40px",
        border: "1px solid #ccc",
        background: "#fff",
        overflow: "auto",
        WebkitOverflowScrolling: "touch",
        borderRadius: "4px",
        outline: "none",
        padding: "20px",
        height: "300px",
        margin: "0 auto",
        width: "700px"
        // top: '50%',
        // left: '50%',
        // right: 'auto',
        // bottom: 'auto',
        // marginRight: '-50%',
        // transform: 'translate(-50%, -50%)'
    }
};

const Confirm = ({ modalOpen, onClose, children }) => {
    useEffect(() => {
        Modal.setAppElement("#appRoot");
    });

    return (
        <div>
            <Modal
                isOpen={modalOpen}
                onRequestClose={onClose}
                style={customStyles}
                contentLabel="alert"
            >
                <div className={"alertPopup"}>{children}</div>
            </Modal>
        </div>
    );
};
