import React from "react";
import ReactDOM from "react-dom";
import "remixicon/fonts/remixicon.css";
import App from "./App";
// import i18n (needs to be bundled)
import "./i18n";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import "react-app-polyfill/ie11";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-NTPCM26'
}

TagManager.initialize(tagManagerArgs)
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
