import PropTypes from "prop-types";
import React from "react";
import "./ToggleableContent.scss";

export const HideDirection = {
    LEFT: 0,
    RIGHT: 1,
    UP: 2,
    DOWN: 3
};

// const getIcon = (contentHidden) => {
//     const isVisible = !contentHidden;
//     const className = (isVisible ? 'ri-checkbox-indeterminate-line' : 'ri-add-box-line') + ' ri-2x'
//     return (<i className={className}></i>)
// }

const getMarginClassName = (hideDirection, contentHidden) => {
    const { LEFT, RIGHT, UP, DOWN } = HideDirection;
    if (contentHidden) {
        switch (hideDirection) {
            case LEFT:
                return "leftFix";
            case RIGHT:
                return "rightFix";
            case DOWN:
                return "bottomFix";
            case UP:
            default:
                return "";
        }
    } else {
        return contentHidden + "";
    }
};

export const ToggleableContent = ({ children, hideDirection }) => {
    if (
        hideDirection === undefined ||
        hideDirection < 0 ||
        hideDirection > Object.keys(HideDirection).length
    ) {
        throw Error("Invalid hide-direction " + hideDirection);
    }
    // let [contentHidden, setContentHidden] = useState(false);
    let contentHidden = false;

    return (
        <div className={getMarginClassName(hideDirection, contentHidden)}>
            {/* <div className="toggleButton" onClick={() => setContentHidden(!contentHidden)}>
                {getIcon(contentHidden, hideDirection)}
            </div> */}
            <div className={"toggleableContent " + (contentHidden ? "hidden" : "")}>{children}</div>
        </div>
    );
};

ToggleableContent.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
    hideDirection: PropTypes.number
};

export default ToggleableContent;
