import PropTypes from "prop-types";
import React from "react";
import "./index.scss";

export const InputWrapper = ({
    label,
    inputType,
    name,
    value,
    onChange,
    disabled = false,
    ...rest
}) => {
    return (
        <div className={"input-wrapper"} {...rest}>
            <label htmlFor={name}>
                {label}
                <input
                    type={inputType}
                    name={name}
                    id={name}
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                />
            </label>
        </div>
    );
};

InputWrapper.propTypes = {
    disabled: PropTypes.bool,
    inputType: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any
};
