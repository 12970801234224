import React, { useEffect } from "react";
import PropTypes from "prop-types";

import GuestInputsScene from "../../../scenes/3_guest_inputs";

export const Step3Route = ({ stateTracked, setStateTracked, autoSaveState }) => {
    useEffect(() => {
        autoSaveState();
    }, []);

    const step3ReadOnly = {
        areaInSquareMeters: stateTracked.totalArea,
        circumfenceInMeters: stateTracked.step2.stateObj.totalCircumfence
    };

    /**
     * Step 3
     * @param {Event} onChangeEvent
     */
    function formChangeHandler(onChangeEvent) {
        const { name, value } = onChangeEvent.target;
        setStateTracked(prevState => {
            return {
                ...prevState,
                step3: {
                    ...prevState.step3,
                    [name]: value
                }
            };
        });
    }

    return (
        <GuestInputsScene
            form={stateTracked.step3}
            onFormChangeHandler={e => {
                formChangeHandler(e);
            }}
            readOnlyFields={step3ReadOnly}
        />
    );
};

Step3Route.propTypes = {
    stateTracked: PropTypes.object,
    setStateTracked: PropTypes.func
};

export default Step3Route;
