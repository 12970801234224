window.debug = window.debug ? window.debug : {};
const uMC = window.debug.unimplementedMethodCalls;
window.debug.unimplementedMethodCalls = uMC ? uMC : 0;

export class OverridableMethod {
    constructor() {
        this.method = () => {
            // Method not yet overriden
            if (++window.debug.unimplementedMethodCalls > 10) {
                // console.error("More then 10 unimplemented method calls, each should only call ~once");
            }
        };
    }
    // "=>" makes "this" available
    invokeMethod = () => {
        this.method();
    };
    overrideMethod(fn) {
        this.method = fn;
    }
}
