//@flow
import turfDestination from "@turf/destination";
import { turfPoint } from "@turf/helpers";
import { round6 } from "../../../../common/Utils";

const turfConst = {
    NORTH: 0,
    WEST: -90,
    EAST: 90
};

export class GenericCoord {
    get longitude() {
        return this.lng;
    }
    get latitude() {
        return this.lat;
    }
    set longitude(newLng) {
        this.lng = newLng;
    }
    set latitude(newLat) {
        this.lat = newLat;
    }

    /**
     * Standard array form for '@turf/*' methods
     */
    get lngLat() {
        return [this.lng, this.lat];
    }
    get latLng() {
        return [this.lat, this.lng];
    }

    /**
     * @returns {google.maps.LatLngLiteral}
     */
    get literal() {
        return { lat: this.lat, lng: this.lng };
    }

    /**
     *
     * @returns {google.maps.LatLng}
     */
    get latLngFn() {
        const google = window.google ? window.google : undefined;
        if(google === undefined) {
            /**
             * @type {any}
             */
            const selfImpl = {
                lat: () => this.lat, lng: () => this.lng,
                toJSON() {
                    return `{lat:${this.lat}, lng:${this.lng}}`
                },
                toString() {
                    return `(${this.lat},${this.lng})`
                },
                toUrlValue(precision) {
                    return `${this.lat},${this.lng}`
                }
            };
            return selfImpl;
        }
        // eslint-disable-next-line no-undef
        return new window.google.maps.LatLng(this.lat, this.lng);
    }

    get turfPoint() {
        return turfPoint(this.lngLat);
    }

    /**
     * Get a coord west relative to this
     * @param {number} distanceCm Distance in negative LNG direction
     * @return {GenericCoord} coord 'distanceCm' west of this
     */
    west(distanceCmParam) {
        const destinationPoint = turfDestination(
            this.lngLat,
            Math.abs(distanceCmParam) / 100,
            distanceCmParam > 0 ? turfConst.WEST : turfConst.EAST,
            { units: "meters" }
        );
        const retval = new GenericCoord({ turfPoint: destinationPoint });
        return retval;
    }
    east(distanceCm) {
        return this.west(-distanceCm);
    }

    /**
     * Get a coord north relative to this
     * @param {number} distanceCm Distance in positive LAT direction
     * @return {GenericCoord} coord 'distanceCm' north of this
     */
    north(distanceCm) {
        const destinationPoint = turfDestination(this.lngLat, distanceCm / 100, turfConst.NORTH, {
            units: "meters"
        });
        const retval = new GenericCoord({ turfPoint: destinationPoint });
        return retval;
    }
    south(distanceCm) {
        return this.north(-distanceCm);
    }

    /**
     * LatLng diff;
     * @param {GenericCoord} other
     * @returns (this - other)
     */
    diff(other) {
        const lng = this.lng - other.lng;
        const lat = this.lat - other.lat;
        const lit = { lng, lat };
        return new GenericCoord({ literal: lit });
    }

    /**
     * LatLng addition this+other
     * @param {GenericCoord} other
     */
    add(other) {
        const lng = this.lng + other.lng;
        const lat = this.lat + other.lat;
        const lit = { lng, lat };
        return new GenericCoord({ literal: lit });
    }

    /**
     * Scale this lat & lng coord by the given scalar (useful when combining with diff e.g. foo.diff(other).scale(2))
     * @param {number} scalar
     */
    scale(scalar) {
        this.lat *= scalar;
        this.lng *= scalar;
        return this;
    }

    /**
     * @param {GenericCoord} other
     * @returns true when coords equal numerically
     */
    equals(other) {
        return this.lng === other.lng && this.lat === other.lat;
    }

    toString() {
        return `GenericCoord: [lng: ${round6(this.lng)}, lat: ${round6(this.lat)}]`;
    }

    /**
     * @typedef {object} GenericCoordParam
     * @property {google.maps.LatLng} [fns] = google
     * @property {google.maps.LatLngLiteral} [literal] = google
     * @property {number[]} [lngLatArr] = Turf
     * @property {number[]} [latLngArr] = Internal
     * @property {object} [turfPoint] = Turf
     */
    /**
     * @class
     * @param {GenericCoordParam} param0
     */
    constructor({ fns, literal, lngLatArr, latLngArr, turfPoint }) {
        if (turfPoint) {
            const coords = turfPoint.geometry.coordinates;
            this.lng = coords[0];
            this.lat = coords[1];
        } else {
            this.lng = fns
                ? fns.lng()
                : literal
                ? literal.lng
                : lngLatArr
                ? lngLatArr[0]
                : latLngArr[1];
            this.lat = fns
                ? fns.lat()
                : literal
                ? literal.lat
                : lngLatArr
                ? lngLatArr[1]
                : latLngArr[0];
        }
    }
}
/**
 *
 * @param {GenericCoordParam} params
 */
export function genericCoord(params) {
    return new GenericCoord(params);
}

/**
 * @param {google.maps.LatLng|google.maps.LatLngLiteral|Array.<number>} something
 * Ducktyping of the given 'something' to either an lngLat array, a {lat:num, lng:num}-object
 * or an {lat:()=>num, lng:()=>num}-object or an error.
 */
export function genericCoordAutoLngLat(something) {
    if (!something) {
        throw Error("Passed undef/null to genericCoordAutoLngLat");
    }
    const isType = (e, a, b, t) => typeof e[a] === t && typeof e[b] === t;

    if (isType(something, "0", "1", "number")) {
        // array
        return new GenericCoord({ lngLatArr: something });
    } else if (isType(something, "lat", "lng", "number")) {
        return new GenericCoord({ literal: something });
    } else if (isType(something, "lat", "lng", "function")) {
        return new GenericCoord({ fns: something });
    } else {
        throw Error("Can't convert " + something);
    }
}
export default genericCoord;
