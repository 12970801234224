/**
 * Central point of change for api routes.
 * Used by setupProxy for local dev env call proxying.
 * Used by ... to bind actual routes.
 */

// have to use old syntax here since this is loaded by setupProxy.js which is loaded without the whole transpiling fun
// eslint-disable-next-line no-undef
module.exports = {
    calculation: "/api/calculation",
    state: "/api/state",
    inquiry: "/api/inquiry",
    config: "/api/config"
};
