import React, { useEffect, useRef } from 'react';
import './index.scss';
import { previewMapOptionDefaults } from '../../../Configuration';
import { ARC_TYPE } from '../../2_setup_designer/designer/elements/arc/ArcConsts';
import { ArcCreationParams } from '../../2_setup_designer/designer/elements/arc/ArcCreationParams';
import { RectangleCreationParams } from '../../2_setup_designer/designer/elements/rectangle/RectangleCreationParams';
import polyRotation from '../../2_setup_designer/designer/elements/common/logic/polyRotation';
import drawArc from '../../2_setup_designer/designer/elements/arc/ArcDrawing';
import createRectGooglePoly from '../../2_setup_designer/designer/elements/rectangle/RectangleDrawing';

const initGoogleMapsElement = (gMapRef, google_, mapRef, center) => {
  gMapRef.current = new google_.maps.Map(mapRef.current, {
    ...previewMapOptionDefaults(google_),
    center
  });
};

const recreateElementsOntoGMap = (google_, elements, gMapRef) => {
  const minimalElBench = {
    google: google_
  };

  // swiped from SaveStateManager#restoreStateFromLocalStorage
  for (const staticElement of elements) {
    let recreatedElement = null;
    switch (staticElement.type) {
      case ARC_TYPE:
        recreatedElement = drawArc(minimalElBench, new ArcCreationParams(staticElement));
        break;
      case 'rectangle':
        recreatedElement = createRectGooglePoly(minimalElBench, new RectangleCreationParams(staticElement));
        break;
      default:
        console.error('No creator for type ', staticElement.type, staticElement);
        continue;
    }
    const { rotation } = staticElement;

    recreatedElement.setMap(gMapRef.current);

    polyRotation(recreatedElement, rotation, () => {
      // NOP
    });

    // Disable broken interactions, just in case the overlay "fails"
    google_.maps.event.clearListeners(recreatedElement, 'dragstart');
    google_.maps.event.clearListeners(recreatedElement, 'dragend');
    recreatedElement.draggable = false;
  }
};

/**
 * This "MapPreview" element implies that a global "google" is defined.
 * @returns {JSX.Element}
 * @constructor
 */
export const MapPreview = () => {
  // We enter this render method multiple times:
  // 1. the mapRef,gMapRef is not yet defined:
  // - parse the localStorage data (unnecessary here)
  // - skip the useEffect blocks and render the element the first time
  // - while doing so the mapRef is being set
  // 2. the mapRef is defined
  // - parse the localStorage data -> {center, elements}
  // - initialize a google-maps map object using this mapRef and the lS-center
  // - bind that to the gMapRef
  // - skip the second effect block
  // 3. the mapRef and gMapRef are defined
  // - parse the localStorage data -> {center, elements}
  // - skip the first effect block
  // - use the lS-elements to recreate the elements on the gMapRef-map
  // - now the user should have a map with the rendered ice-rink

  // eslint-disable-next-line no-undef
  const google_ = google;
  const mapRef = useRef(null);
  const gMapRef = useRef(null);

  const state02 = localStorage.getItem('state-02');
  const { center, elements } = JSON.parse(state02 ? state02 : '{}');

  useEffect(() => {
    if (center && mapRef.current) {
      initGoogleMapsElement(gMapRef, google_, mapRef, center);
    }
  }, [mapRef, gMapRef, center, google_]);

  useEffect(() => {
    if (elements && mapRef.current && gMapRef.current) {
      recreateElementsOntoGMap(google_, elements, gMapRef);
    }
  }, [mapRef, gMapRef, elements]);

  return (
    <div className='mapPreview'>
      <div
        className='previewMapsEl'
        id='previewMap'
        ref={mapRef}
      />
      <div id={'staticMapOverlay'} />
    </div>
  );
};
