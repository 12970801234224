import { tlog } from "../../debugging";
// eslint-disable-next-line no-unused-vars
import RectangleClass from "../../rectangle/RectangleClass";

/**
 *
 * @param element
 * @param {number}twoPointFiveMeterExpansionCount 1 or -1
 * @param {boolean} expandEast
 * @param {function} handleChange
 * @returns {boolean}
 */
export const polyExpand = (element, twoPointFiveMeterExpansionCount, expandEast, handleChange) => {
    if (!element) {
        return;
    }
    let result = false;
    if (element.creationParams.type === "rectangle") {
        /**
         * @type {RectangleClass}
         */
        const rectangleClass = element.parent;
        rectangleClass.expand(twoPointFiveMeterExpansionCount, expandEast);
        // result = rectangleExpansion(element, twoPointFiveMeterExpansionCount, expandEast);
    } else {
        // polyArc
        if(twoPointFiveMeterExpansionCount > 0) {
            element.creationParams.stretch(expandEast);
        } else {
            element.creationParams.squeeze(expandEast);
        }
    }
    handleChange();
    tlog("Ignored PolyExpand result", result);
    return true;
};

export default polyExpand;
