import defaultPolySettings from "./common/DefaultPolySettings";
import createPopupClass from "./PopupClass";
import {genericCoordAutoLngLat} from "./common/GenericCoord";

export const profilerCallbackHandler = (
    id, // the "id" prop of the Profiler tree that has just committed
    phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
    actualDuration, // time spent rendering the committed update
    baseDuration, // estimated time to render the entire subtree without memoization
    startTime, // when React began rendering this update
    commitTime, // when React committed this update
    interactions // the Set of interactions belonging to this update
) => {
    // console.table({
    //     'the "id" prop of the Profiler tree that has just committed': id,
    //     'either "mount" (if the tree just mounted) or "update" (if it re-rendered)': phase,
    //     "time spent rendering the committed update": actualDuration,
    //     "estimated time to render the entire subtree without memoization": baseDuration,
    //     "when React began rendering this update": startTime,
    //     "when React committed this update": commitTime,
    //     "the Set of interactions belonging to this update": JSON.stringify(interactions)
    // });

    tlog(
        `Profiler[${phase}]:`, // 'either "mount" (if the tree just mounted) or "update" (if it re-rendered)'
        id, // 'the "id" prop of the Profiler tree that has just committed'
        `${actualDuration}(${baseDuration})`, // "time spent rendering the committed update" ("estimated time to render the entire subtree without memoization")
        `${startTime}-${commitTime}`, // "when React began rendering this update" - "when React committed this update"
        interactions
    );
};

// prettier-ignore
const bindDebugShortcuts = () => {
    const s = localStorage;
    const l = function() {tlog(arguments)}
    const reload = () => window.location.reload();
    // prettier-ignore
    const set = (key) => {s.setItem(key, true);l("set "+key);reload();};
    // prettier-ignore
    const unset = (key) => {s.removeItem(key);l("unset "+key);reload();};
    window.nak = () => set("noAPIKey");
    window.ak = () => unset("noAPIKey"); 
    window.d = () => {window.doLog = true;set("debug");set("trace");set("debugUI");}
    window.nd = () => {window.doLog = false;unset("debug");unset("trace");unset("debugUI");} 
}
bindDebugShortcuts();

export const getWindowDebug = () => {
    // @ts-ignore
    window.debug = window.debug ? window.debug : {};
    // @ts-ignore
    const debug = window.debug;
    if (!debug) {
        console.error("window.debug undefined");
    }
    return debug;
};

export const setDebugMessages = messages => {
    getWindowDebug().messages = messages;
};
getWindowDebug().setDebugMessages = setDebugMessages;

if (localStorage.getItem("debug")) {
    setTimeout(() => {
        const debug = getWindowDebug();
        // eslint-disable-next-line no-undef
        debug["debugLine"] = debug["debugLine"]
            ? debug["debugLine"]
            : // eslint-disable-next-line no-undef
              new google.maps.Polyline({
                  ...defaultPolySettings,
                  strokeColor: "#0F0",
                  strokeWeight: 4,
                  map: getWindowDebug().map,
                  path: []
              });
    }, 1000);
}

export const moveLine = path => {
    const debug = getWindowDebug();
    debug["debugLine"].setPath(path);
};
getWindowDebug()["moveLine"] = moveLine;

export const line = (path, color) => {
    const debug = getWindowDebug();

    // eslint-disable-next-line no-undef
    return new google.maps.Polyline({
        ...defaultPolySettings,
        strokeColor: color ? color : "#F0F",
        strokeWeight: 1,
        map: debug.map,
        path: path
    });
};
getWindowDebug()["line"] = line;

/**
 *
 * @param {google.maps.LatLngLiteral[]}path
 * @param {string} color
 * @param {boolean?} markPoints
 */
export const tempLine = (path, color, markPoints) => {
    if (path[1] === undefined) {
        console.log("Path shall be an array with >= 2 els", path);
    }
    if (markPoints) {
        path.forEach((e, i) => {
            tempMark(e, `i: ${i}`);
        });
    }
    const linePoly = line(path, color);
    setTimeout(() => {
        linePoly.setMap(null);
    }, 5000);
};
getWindowDebug()["tempLine"] = tempLine;

export const debugPoly = path => {
    const debug = getWindowDebug();

    const container = {};

    // eslint-disable-next-line no-undef
    container.poly = new google.maps.Polyline({
        ...defaultPolySettings,
        strokeColor: "#F0F",
        strokeWeight: 1,
        map: debug.map,
        path: path
    });
    setTimeout(() => {
        console.warn("deleting debug poly after 1 sec");
        container.poly.setMap(null);
        delete container.poly;
    }, 1000);
    return container.poly;
};

/**
 * Add a marker that disappears after 10 seconds
 * @param {google.maps.LatLng|google.maps.LatLngLiteral} pos
 * @param {string} text
 */
export const tempMark = (pos, text) => {
    const debug = getWindowDebug();
    // eslint-disable-next-line no-undef
    const marker = new google.maps.Marker({
        position: pos,
        map: debug.map,
        title: text ? text : "tempMark"
    });
    setTimeout(() => {
        marker.setMap(null);
    }, 10000);
};
window.debug.tempmark = tempMark;

// #64
export const tempPopup = (pos, text) => {
    const {map} = getWindowDebug();

    pos = pos ? pos : map.getCenter();
    const posFn = genericCoordAutoLngLat(pos).latLngFn;

    text = text ? text : "Hello World";
    // eslint-disable-next-line no-undef
    const Popup = createPopupClass(google);
    const popup = new Popup(posFn, text);
    popup.setMap(map);
}
getWindowDebug().tempPopup = tempPopup;
getWindowDebug().helloWorld = () => {
    tempPopup(debug.map.getCenter(), "Hello World");
}
// setTimeout(() => {
//     getWindowDebug().helloWorld();
// }, 10000);

export const isDebugging = () => {
    return !!localStorage.getItem("debug");
};

export const showDebugUI = () => {
    return !!localStorage.getItem("debugUI");
};

const startTime = +new Date();
export const getTimeDiff = () => {
    return +new Date() - startTime;
};

window.doLog = isDebugging();

// const old = tlog;
// tlog = function () {
//     const timeDiff = getTimeDiff().toString().padStart(5, "0");
//     if (arguments[0] && arguments[0].includes && arguments[0].includes("%c")) {
//         old("-----");
//         const x = { ...arguments };
//         delete x[0];
//         const rest = Object.keys(x).map(key => arguments[key]);
//         old(rest);
//         old(timeDiff + " " + arguments[0], ...rest);
//     } else {
//         old(timeDiff, ...arguments);
//     }
// };

export const traceLogEnabled = () => !!localStorage.getItem("trace");

export const tlog = function () {
    if (traceLogEnabled()) {
        const caller = (new Error()).stack.split("\n")[1].replace(/@.*:/,":?:")
        console.log(...arguments, "\t\t", `(${caller})`);
        // console.log(...arguments);
    }
};

/**
 * usage: clog(() => console.log(...))
 */
export const dbg = function (consoleLogFn) {
    if (traceLogEnabled()) {
        consoleLogFn();
    }
};

const debug = getWindowDebug();
debug.lastElement = () => (debug.elements2 ? debug.elements2[debug.elements2.length - 1] : null);
