import {latLngFnToNum} from "../../../../../common/Utils";

/**
 * @typedef {import("../CommonDrawing").RectanglePoly} RectanglePoly
 * @typedef {import("../CommonDrawing").PolyArcPoly} PolyArcPoly
 */

/**
 * Fix offset after scaling/rotating rectangles by moving it by the delta.
 * @param {RectanglePoly} elementToBeTranslated
 * @param {google.maps.LatLngLiteral} targetPositionLatLngLiteral
 * @param {any} sourcePositionLatLngLiteral
 *
 * Examples:
 * recenter => (gridPolyLine, newCenter, calculateGridCenter())
 * rectangleRotation => (recreatedElement, staticElement.center, undefined);
 *
 */
export const polyTranslation = (
  elementToBeTranslated,
  targetPositionLatLngLiteral,
  sourcePositionLatLngLiteral
) => {
  if (
    typeof targetPositionLatLngLiteral.lat !== "number" ||
    typeof targetPositionLatLngLiteral.lng !== "number" ||
    (sourcePositionLatLngLiteral !== undefined &&
      (typeof sourcePositionLatLngLiteral.lat !== "number" ||
        typeof sourcePositionLatLngLiteral.lng !== "number"))
  ) {
    console.error(
      "targetPositionLatLngLiteral",
      targetPositionLatLngLiteral,
      "sourcePositionLatLngLiteral",
      sourcePositionLatLngLiteral
    );
    throw Error("invalid type passed");
  }
  if (elementToBeTranslated && elementToBeTranslated.getPaths) {
    const innerAndOuterPath = elementToBeTranslated.getPaths().getArray();

    for (let idx = 0; idx < innerAndOuterPath.length; idx++) {
      /**
       * @type {google.maps.MVCArray<google.maps.LatLng>} polyPath Path of the 'element' param
       */
      const elementPath = innerAndOuterPath[idx];
      const refFromPoly = sourcePositionLatLngLiteral
        ? sourcePositionLatLngLiteral
        : latLngFnToNum(elementPath.getAt(0));

      const delta = {
        lat: targetPositionLatLngLiteral.lat - refFromPoly.lat,
        lng: targetPositionLatLngLiteral.lng - refFromPoly.lng
      };

      if (isNaN(delta.lat) || isNaN(delta.lng)) {
        console.error("NaN:", targetPositionLatLngLiteral, refFromPoly, delta);
        throw Error("NaN");
      }

      for (let i = 0; i < elementPath.length; i++) {
        /** @type {google.maps.LatLng} */
        const currentPoly = elementPath.getAt(i);
        const newPoly = {
          lat: () => currentPoly.lat() + delta.lat,
          lng: () => currentPoly.lng() + delta.lng
        };
        elementPath.setAt(i, newPoly);
      }
    }
  }
};

/**
 *
 * @param {google.maps.MVCArray<google.maps.LatLng>} elementPath -  Path of the 'element' param
 * @param {google.maps.LatLngLiteral} delta - Lat/Lng-Translation
 */
export const translateSinglePath = (elementPath, delta) => {
  if (isNaN(delta.lat) || isNaN(delta.lng)) {
    throw Error("NaN " + JSON.stringify(delta));
  }

  for (let i = 0; i < elementPath.length; i++) {
    /** @type {google.maps.LatLng} */
    const currentPoly = elementPath.getAt(i);
    const newPoly = {
      lat: currentPoly.lat() + delta.lat,
      lng: currentPoly.lng() + delta.lng
    };
    elementPath.setAt(i, new window.google.maps.LatLng(newPoly));
  }
};

export default polyTranslation;
