import PropTypes from "prop-types";
import React from "react";
import "./index.scss";
import variables from "./index.scss";

/**
 * Polar = Angles, Cartesian = X,Y
 * SVG expects Cartesian coordinates so we use this conversion method
 */
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians)
    };
}
/**
 * Describe an SVG arc starting at [x,y] ranging 'radius' from 'startAngle' to 'endAngle'
 * @param {*} x - start-coordinate
 * @param {*} y - start-coordinate
 * @param {*} radius - arc radius
 * @param {*} startAngle - startAngle
 * @param {*} endAngle - endAngle
 */
function describeArc(x, y, radius, startAngle, endAngle) {
    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    const { round } = Math;
    const startX = round(start.x);
    const startY = round(start.y);
    const endX = round(end.x);
    const endY = round(end.y);

    var d = ["M", startX, startY, "A", radius, radius, 0, largeArcFlag, 0, endX, endY].join(" ");
    return d;
}

/**
 * A button with an arc on it with the given arcRadius
 */
export const ArcButton = ({ arcRadius, ...rest }) => {
    const drawParams = {
        fill: "none",
        stroke: `${variables.designerElementButtonColor}`,
        strokeWidth: "2"
    };

    return (
        <svg {...rest} width="40" height="40" xmlns="http://www.w3.org/2000/svg">
            {
                // SVG-Arc apparently can't be a full circle so we are using circle here
                parseInt(arcRadius) === 2 ? (
                    <circle cx="20" cy="20" r="15" {...drawParams} />
                ) : (
                    <path d={describeArc(20, 20, 15, 0, 180 * arcRadius)} {...drawParams} />
                )
            }
        </svg>
    );
};

ArcButton.propTypes = {
    arcRadius: PropTypes.number
};
export default ArcButton;
