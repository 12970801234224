import turfIntersect from "@turf/intersect";
import { objToArrLngLat } from "scenes/common/Utils";
import turfPolygon from "turf-polygon";
import { arcError } from "../elements/arc/ArcErrorCalculation";
import { defaultErrorColor, defaultPolySettings } from "../elements/common/DefaultPolySettings";
import { tlog } from "../elements/debugging";
import { rectangleError } from "../elements/rectangle/RectangleErrorCalculation";

const WITH_ERROR = true;
const WITHOUT_ERROR = false;

/** 
 * @typedef {google.maps.Polygon & DecoratedPoly} DecoratedPolygon 

 */

/**
 *
 * @param {MVCArray<LatLng>} mvcArrayLatLng
 * @returns {Polygon}
 */
const mvcArrayLngLatToTurfPoly = mvcArrayLatLng => {
    const arr = mvcArrayLatLng.getArray();
    const turfLngLat = arr.map(objToArrLngLat);
    // turf expects polygons to close with the start
    turfLngLat.push(turfLngLat[0]);
    const turfPoly = turfPolygon([turfLngLat]); // polygon requires tripple nested arrays
    return turfPoly;
};

/**
 *
 * @param {DecoratedPolygon} el1
 * @param {DecoratedPolygon} el2
 * @returns {boolean} true on at least one intersection
 */
export const elementsHaveIntersection = (el1, el2, withError) => {
    /**
     * As per request from intercom we'll ignore the intersection for now
     */
    return false;
    const paths1 = el1.getPaths().getArray();
    const paths2 = el2.getPaths().getArray();

    // Convention: Outer path is first in array
    const outerPath1 = paths1[0];
    const outerPath2 = paths2[0];

    // Iterate over outer polygons
    // if any intersect, return true
    const turf1 = mvcArrayLngLatToTurfPoly(outerPath1);
    const turf2 = mvcArrayLngLatToTurfPoly(outerPath2);

    let intersection;
    if (withError) {
        let turf1WithError =
            el1.creationParams.type === "rectangle"
                ? rectangleError(turf1, el1)
                : arcError(turf1, el1);
        let turf2WithError =
            el2.creationParams.type === "rectangle"
                ? rectangleError(turf2, el2)
                : arcError(turf2, el2);
        intersection = turfIntersect(turf1WithError, turf2WithError);
    } else {
        intersection = turfIntersect(turf1, turf2);
    }

    return intersection !== null;
};

/**
 * On 'dragend' check if any elements overlap,
 * if any overlap mark those in a new color, e.g. purple
 * Todo: optimize should this eat an ipad battery
 * @param {DecoratedPolygon[]} elements
 * @returns {boolean} true if has overlaps
 */
export const hasAnyOverlappingElements = elements => {
    if (elements.length <= 1) {
        return false;
    }
    elements.forEach(e => e.setOptions({ strokeColor: defaultPolySettings.strokeColor }));

    let hasOverlaps = false;
    for (let idx = 0; idx < elements.length; idx++) {
        const currElement = elements[idx];

        for (let innerIdx = 0; innerIdx < elements.length; innerIdx++) {
            if (idx === innerIdx) {
                continue;
            }
            const cmpElement = elements[innerIdx];
            if (elementsHaveIntersection(currElement, cmpElement, WITHOUT_ERROR)) {
                currElement.setOptions({ strokeColor: defaultErrorColor });
                cmpElement.setOptions({ strokeColor: defaultErrorColor });
                hasOverlaps = true;
            }
        }
    }
    return hasOverlaps;
};

/**
 * On 'dragend' check if any elements overlap this element,
 * if any overlap return true
 * Todo: optimize should this eat an ipad battery
 * @param {DecoratedPolygon[]} elements
 * @returns {boolean} true if has overlaps
 */
export const hasElementOverlappingElements = (cmpElement, elements) => {
    if (elements.length <= 1) {
        return false;
    }
    for (let idx = 0; idx < elements.length; idx++) {
        const elOfEls = elements[idx];
        if (elOfEls === cmpElement) {
            continue;
        }
        if (elementsHaveIntersection(elOfEls, cmpElement, WITHOUT_ERROR)) {
            return true;
        }
    }
    return false;
};

/**
 * @param {DecoratedPolygon[]} elements
 */
export const allElementsAreConnected = elements => {
    if (elements.length <= 1) {
        return true;
    }

    const define = (el, key) => {
        el[key] = el[key] ? el[key] : {};
    };

    const intersections = {};
    /**
     * @type {number[]}
     */
    const mustBeVisited = [];

    elements.forEach((e, i) => {
        e.comparisonKey = i;
        mustBeVisited.push(i);
    });

    const compareElements = [...elements];
    // For all elements

    for (const element of elements) {
        // setup undefined object
        const eKey = element.comparisonKey;
        define(intersections, eKey);

        for (const cmpElement of compareElements) {
            // Skip self
            if (element === cmpElement) {
                continue;
            }

            // setup undefined object
            const cKey = cmpElement.comparisonKey;
            define(intersections, cKey);

            // Skip already compared items (A∩B => B∩A)
            if (intersections[eKey][cKey]) {
                continue;
            }

            if (elementsHaveIntersection(element, cmpElement, WITH_ERROR)) {
                // cache as two-way intersection to skip intersection above
                // one of the following lines should be redundant
                intersections[eKey][cKey] = true;
                intersections[cKey][eKey] = true;
            }
        }
    }

    // Walk the intersection tree from one key to reach all other keys
    const walk = (nodeKey, intersections, visitedRef) => {
        // already visited this node
        if (visitedRef[nodeKey]) {
            return;
        }
        // ----

        // mark current node not to be visited again
        visitedRef[nodeKey] = true;

        // ["0", "2"]
        const childNodeKeys = Object.keys(intersections[nodeKey]);

        // continue walking on all connected nodes
        for (const childNodeKey of childNodeKeys) {
            // ("0", $seeBelow, visitedRef)
            walk(childNodeKey, intersections, visitedRef);
        }
    };

    /**
     * intersections {
     *  "0" : {
     *     "1": true
     *  }
     *  "1" : {
     *     "0": true,
     *     "2": true
     *  }
     *  "2" : {
     *     "1": true,
     *  }
     *  "3" : {
     *     "4": true
     *  }
     *  "4" : {
     *     "3": true
     *  }
     * }
     *
     * (0)-(1)-(2)   (3)-(4)
     * mustBeVisited = [0,1,2,3,4]
     */

    // A∩B, A∩C, A∩C
    const visited = {};
    const keys = Object.keys(intersections);
    const firstNodeKey = keys[0];

    walk(firstNodeKey, intersections, visited);

    const evaluate = () => {
        for (const compairsonKey of mustBeVisited) {
            if (!visited[compairsonKey]) {
                return false;
            }
        }
        return true;
    };
    const result = evaluate();
    tlog("Intersect##", firstNodeKey, intersections, visited, result);

    return result;
};
