import { tlog } from "../debugging";
import genericCoord from "./GenericCoord";
import { translateSinglePath } from "./logic/polyTranslation";
import { ARC_TYPE } from "../arc/ArcConsts";

/**
 * @typedef {Object} CreationParams
 * @prop {Object} center - center
 * @prop {number} rotation - rotation
 * @prop {number} scale - scale
 */

/**
 * @typedef {Object} RectangleCreationParamsImpl
 * @property {number} rectangleDistCmX - Edge-length in X-direction
 * @property {number} rectangleDistCmY - Edge-length in Y-direction
 * @property {boolean} useRoundedCorners - true -> draw arcs instead of sharp edges
 */

/**
 * @typedef {Object} PolyArcCreationParamsImpl
 * @prop {number} radiusInCm - radiusInCm
 * @prop {number} angleStart - angleStart
 * @prop {number} angleEnd - angleEnd
 * @prop {boolean?} withHole - withHole
 */

/**
 * @typedef {CreationParams & RectangleCreationParamsImpl} RectangleCreationParams
 * @typedef {CreationParams & PolyArcCreationParamsImpl} PolyArcCreationParams
 */

/**
 * @typedef {Object & google.maps.Polygon} RectanglePoly
 * @prop {RectangleCreationParams} creationParams Holds data for poly recreation
 * @prop {any} parent
 */

/**
 * @typedef {Object & google.maps.Polygon} PolyArcPoly
 * @prop {PolyArcCreationParams} creationParams Holds data for poly recreation
 */

/**
 * @typedef {Object} CustomPoly
 * @prop {RectangleCreationParams|PolyArcCreationParams} creationParams Holds data for poly recreation
 */

/**
 *
 * @param {*} elBench
 * @param {google.maps.Polygon & CustomPoly} poly
 */
export const handleDragCenterUpdates = (elBench, poly) => {
    // recalculate center on each drag
    let dragStartLatLng = undefined;
    let dragEndLatLng = undefined;
    elBench.google.maps.event.addListener(poly, "dragstart", evt => {
        dragStartLatLng = evt.latLng;
    });
    elBench.google.maps.event.addListener(poly, "dragend", evt => {
        const { aSMgmt } = elBench;

        const isArc = poly.creationParams.type === ARC_TYPE;

        // Check for new overlaps
        const polyIsOverlapping = aSMgmt.hasElementOverlappingElements(poly);
        if (polyIsOverlapping) {
            //restorePreviousPosition
            if (isArc) {
                const arcPoly = poly;
                const delta = genericCoord({
                    fns: arcPoly.creationParams.lastPositionFirstPoly
                }).diff(genericCoord({ fns: arcPoly.getPaths().getAt(0).getAt(0) }));
                arcPoly
                    .getPaths()
                    .getArray()
                    .forEach(path => translateSinglePath(path, delta.literal));
            } else {
                const rectangleClass = poly.parent;
                rectangleClass.translateToPreviousPosition();
            }
            return;
        } else {
            // Update the position history
            if (isArc) {
                poly.creationParams.updateLastPosition();
                // calculate the origin
                poly.creationParams.setCenter(poly.creationParams.calculateCenter());
                // update popup
                poly.creationParams.updatePopup();
            } else {
                dragEndLatLng = evt.latLng;
                const delta = genericCoord({ fns: dragEndLatLng }).diff(
                    genericCoord({ fns: dragStartLatLng })
                );
                poly.parent.handleDrag(delta);
            }
        }
        tlog("🔁 handleDragCenterUpdates>handleDataChange");
        elBench.handleDataChange();
    });
};
