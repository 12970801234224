import React, {useCallback, useEffect, useState} from "react";
import {BrowserRouter as Router, Redirect, Route, Switch, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import ReactGA from "react-ga";

import {Button} from "components/Button/Button";
import {showDebugUI, tlog} from "scenes/2_setup_designer/designer/elements/debugging";
import {AppState} from "../AppState";
import DebugStateDisplay from "../components/DebugStateDisplay";
import FooterIntercom from "../components/FooterIntercom";
import HeaderIntercom from "../components/HeaderIntercom";
import {defaultStartPosition} from "../Configuration";
import "./index.scss";
import {LocationPickerRoute} from "./Routes/LocationPickerRoute";
import ResultsRoute from "./Routes/ResultsRoute";
import SetupDesignerRoute from "./Routes/SetupDesignerRoute";
import Step3Route from "./Routes/Step3Route";
import {paths} from "./route_consts";
import {postData} from "Utils2";
import apiRoutes from "apiRoutes";
import {ConfigPage} from "scenes/5_config/ConfigPage";

/**
 * The actual app
 */
export function IceRinkApp({loadedState}) {
  const {t} = useTranslation();
  tlog("%c🧩 IceRinkApp re-/render", "background:#ffd8d8;width:100vw;display:block;");

  let initialPageLoadState;
  if (loadedState == null) {
    // No state via stateId => use default
    initialPageLoadState = new AppState(defaultStartPosition);
  } else {
    // State via stateId => use that
    initialPageLoadState = loadedState;
  }

  // Note this only sets stateTracked = new AppState in the first run
  // after that it sets stateTracked = $lastStateTracked
  const [stateTracked, setStateTracked_] = useState(initialPageLoadState);

  /**
   * Browser history used to add/read/remove "?saveId" params
   */
  const browserHistory = useHistory();

  /**
   * Wraps the setStateTracked_ method to modify the history on change.
   * @param {object} data
   */
  const setStateTracked = data => {
    if (stateTracked !== data) {
      if (window.location.search !== "") {
        tlog(
          "Modification, setting search param to ?modified ",
          data,
          window.location.search
        );
        browserHistory.push(window.location.pathname + "");
      } else {
        tlog("Modification, no save since last modification", data, window.location.search);
      }
      setStateTracked_(data);
      setStateTracked_(data);
    }
  };

  const params = new URLSearchParams(window.location.search);
  const saveIdFromParams = params.get("saveId");
  let saveId = saveIdFromParams;
  const [isSaving, setIsSaving] = useState(false);
  const [isSaveModalVisible, setIsSaveModalVisible] = useState(false);
  const showSaveModal = useCallback(() => setIsSaveModalVisible(true));
  const hideSaveModal = useCallback(() => setIsSaveModalVisible(false));
  const [savedId, setSavedId] = useState("");

  const [isAlertModalVisible, setisAlertModalVisible] = useState(false);
  const showAlert = useCallback(message => {
    setAlertMessage(message);
    setisAlertModalVisible(true);
  });
  const hideAlert = useCallback(() => setisAlertModalVisible(false));
  const [alertMessage, setAlertMessage] = useState("");

  const saveState = () => {
    if (isSaving) {
      return;
    }
    return new Promise((resolve, reject) => {
      // In:  class WriteStateRequest(val json: String)
      // Out: class WritexporteStateResult(val uuid: String?, val error: String?)
      //delete state.saveKey;
      setIsSaving(true);
      if (!saveId) {
        setIsSaving(true);
        postData(apiRoutes.state, {json: JSON.stringify(stateTracked)})
          .then(res => res.json())
          .then(writeStateResult => {
            if (writeStateResult.error || !writeStateResult.uuid) {
              reject(writeStateResult.error);
            } else {
              const saveIdLink =
                window.location.pathname + "?saveId=" + writeStateResult.uuid;
              browserHistory.push(saveIdLink);
              return writeStateResult.uuid;
            }
          })
          .catch(error => {
            showAlert(t("result.saveState.error.server"));
          })
          .then(saveId => {
            ReactGA.event({
              category: "IceRink",
              action: "save",
              label: saveId,
              nonInteraction: false
            });
            setSavedId(saveId);
            showSaveModal();
          })
          .catch(error => {
            console.error(error);
            showAlert(t("result.saveState.error.server"));
          })
          .finally(() => {
            setIsSaving(false);
          });
      } else {
        showSaveModal();
        setIsSaving(false);
      }
    });
  };

  const autoSaveState = async () => {
    return new Promise((resolve, reject) => {
      postData(apiRoutes.state, {json: JSON.stringify(stateTracked)})
        .then(res => res.json())
        .then(writeStateResult => {
          if (writeStateResult.error || !writeStateResult.uuid) {
            reject(writeStateResult.error);
          } else {
            return writeStateResult.uuid;
          }
        })
        .then(saveId => {
          ReactGA.event({
            category: "IceRink",
            action: "auto_save",
            label: saveId,
            nonInteraction: true
          });
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          setIsSaving(false);
        });
    });
  };

  // /**
  //  * If there is a '?saveId' param, use it to retrieve a saved state.
  //  */
  // whenStateLoadedViaURLParam(browserHistory, stateTracked, setStateTracked).then(bindState);

  /**
   * The different routes/pages need to be able to read and write parts of the state
   */
  const routeParams = {stateTracked, setStateTracked, saveState, autoSaveState};

  const routesSwitch = (
    <Switch>
      <Route exact path={paths.step2}>
        {/* /icerink/designer */}
        <SetupDesignerRoute {...routeParams} />
      </Route>
      <Route exact path={paths.step3}>
        {/* /icerink/pricing */}
        <Step3Route {...routeParams} />
      </Route>
      <Route exact path={paths.step4}>
        {/* /icerink/result */}
        <ResultsRoute {...routeParams} />
      </Route>
      <Route path={paths.config}>
        <ConfigPage/>
      </Route>
      <Route exact path={paths.step1}>
        {/* /icerink/location */}
        <LocationPickerRoute {...routeParams} />
      </Route>
      <Route>
        <Redirect to={paths.root}/>;
      </Route>
    </Switch>
  );
  return (
    <div>
      <div id="appContainer">
        {/* Header on every page */}
        <Router history={browserHistory}>
          <HeaderIntercom/>
          <SavePopup modalOpen={isSaveModalVisible} onClose={hideSaveModal}>
            <h1>{t("actions.save.modalTitle")}</h1>
            <p>{t("actions.save.success")}</p>
            <span className={"saveId"}>{saveId}</span>
            <span className={"saveId"}>
                            {`${window.location.origin}/designer?saveId=${saveId}`}
                        </span>
            <div onClick={hideSaveModal} className={"modal-close"}>
              <Button color={"orange"}>{t("app.general.close")}</Button>
            </div>
          </SavePopup>
          <AlertPopup modalOpen={isAlertModalVisible} onClose={hideAlert}>
            <h1>{t("modals.alert.title")}</h1>
            <p>{alertMessage}</p>
            <div onClick={hideAlert} className={"modal-close"}>
              <Button color={"orange"}>{t("app.general.close")}</Button>
            </div>
          </AlertPopup>
          <React.Fragment>{routesSwitch}</React.Fragment>
          {/* localStorage.setItem("debug", true) */}
          {showDebugUI() && <DebugStateDisplay state={() => stateTracked}/>}
          {/* Footer on every page */}
          <FooterIntercom saveState={saveState}/>
        </Router>
      </div>
    </div>
  );
}

const customStyles = {
  content: {
    position: "absolute",
    top: "40px",
    left: "40px",
    right: "40px",
    bottom: "40px",
    border: "1px solid #ccc",
    background: "#fff",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "4px",
    outline: "none",
    padding: "20px",
    height: "600px",
    margin: "0 auto",
    width: "700px"
    // top: '50%',
    // left: '50%',
    // right: 'auto',
    // bottom: 'auto',
    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)'
  }
};

const SavePopup = ({modalOpen, onClose, children}) => {
  useEffect(() => {
    Modal.setAppElement("#appContainer");
  });

  return (
    <div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={onClose}
        style={customStyles}
        contentLabel="saveId"
      >
        <div className={"savePopup"}>{children}</div>
      </Modal>
    </div>
  );
};

const AlertPopup = ({modalOpen, onClose, children}) => {
  useEffect(() => {
    Modal.setAppElement("#appContainer");
  });

  return (
    <div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={onClose}
        style={{
          content: {...customStyles.content, ...{height: "300px"}}
        }}
        contentLabel="alert"
      >
        <div className={"alertPopup"}>{children}</div>
      </Modal>
    </div>
  );
};
