import polyRotation from "../common/logic/polyRotation";
import {ARC_TYPE, MINIMUM_BORDER_THICKNESS} from "./ArcConsts";

/**
 * Instead of modifying the polyPath (which lead to various issues) we now just
 * re-create the rectangle on toggle with the useRoundedCorners param toggled.
 * @param {R2GM} logic
 * @param {*} arcEl
 */
export const toggleArcHole = (logic, arcEl) => {
    if (!arcEl || arcEl.creationParams.type !== ARC_TYPE) {
        return;
    }

    const crParams = arcEl.creationParams;
    const { angleEnd, angleStart, radiusInCm, withHole, borderThickness, rotation } = crParams;
    const angleLength = angleEnd - angleStart;
    const radiusInM = radiusInCm / 100;

    const newArcEl = logic.addPolyArc(
        angleLength,
        radiusInM,
        !withHole,
        // see issue #100, use crParams.borderThickness
        // when implementing toggling with current params
        MINIMUM_BORDER_THICKNESS,
        crParams.getCenter()
    );
    polyRotation(newArcEl, rotation, () => {});
    logic.elBench.aSMgmt.removeSpecific(arcEl);
};

export default toggleArcHole;
