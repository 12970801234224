import PropTypes from "prop-types";
import React, { Profiler } from "react";
import { profilerCallbackHandler } from "../../elements/debugging";
import "./index.scss";

const DesignerMap = ({ asmgmt, mapRef }) => {
    const handleMapKeyPressCapture = event => {
        if (["Backspace", "Delete"].includes(event.key)) {
            asmgmt.removeSelected();
        }
    };

    // this element is replaced on translation switch because the 't'-text switches as such it removes the bound 'google maps'-map
    // so we don't localize the "Loading map" string for now
    return (
        <Profiler id="DesignerMap" onRender={profilerCallbackHandler}>
            <div
                onKeyDownCapture={handleMapKeyPressCapture}
                className="mapsEl"
                id="designerMap"
                ref={mapRef}
            >
                Loading map
            </div>
        </Profiler>
    );
};

DesignerMap.propTypes = {
    asmgmt: PropTypes.object,
    mapRef: PropTypes.any
};

export default DesignerMap;
