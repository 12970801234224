import {dbg} from "../../debugging";
import polyExpand from "./polyExpand";
import { ARC_TYPE, ONE_CELL, THREE_CELLS } from "../../arc/ArcConsts";
const {log} = console;

/**
 * Add to a radius to get ONE_CELL more diameter
 * @type {number}
 */
const HALF_A_CELL = ONE_CELL/2;

const isArcAndTooSmall = (posOrNegOne, crParams) => {
    const { type, radiusInCm, directionalRadiusInCm: dirRad, withHole, borderThickness } = crParams;
    if (type === ARC_TYPE) {
        if(crParams.isFullCircle() && posOrNegOne === -1) {
            /**
             * For ellipsoids we check if one of the radii
             * is too small. E.g. either the hole-diameter
             * is less then 250cm or the whole ellipsoid-
             * diameter is less then 250cm.
             */
            if(withHole) {
                /*
                 * Examples for 'isTooSmall'=true:
                 * -#-
                 * #o#
                 * -#-
                 *
                 * --#-- --#-- --##--
                 * -#o#- -###- -####-
                 * #ooo# ##o## ##oo##
                 * -#o#- -###- -####-
                 * --#-- --#-- --##--
                 * bT=1  bT=2
                 */
                /*
                * Example:
                * Pre-Scale:
                *                 xoox
                * Diameter: 4     |--|
                * Radius: 2         ||
                * Border: 1          |
                * HoleDiameter:2   ||
                * HoleRadius:1      |
                * Post-Scale:
                *                  x o x
                * Diameter: 3      |---|
                * Radius: 1.5        |-|
                * Border: 1            |
                * HoleDiameter: 1    |
                * HoleRadius: 0.5    .
                * */
                const newHoleDiameterX = 2*((dirRad.x-HALF_A_CELL)-borderThickness);
                const newHoleDiameterY = 2*((dirRad.y-HALF_A_CELL)-borderThickness);
                let result = newHoleDiameterX < ONE_CELL || newHoleDiameterY < ONE_CELL;
                return result;
            } else {
                /*
                 * Examples for 'isTooSmall'=true:
                 *     x=1  x=2
                 *     _________
                 * y=1| #  | ## |
                 *    |---------
                 * y=2| #  |
                 *    | #  |
                 *     ----
                 */
                const ellipseDiameterX = 2*(dirRad.x);
                const ellipseDiameterY = 2*(dirRad.y);
                let result = ellipseDiameterX <= ONE_CELL || ellipseDiameterY <= ONE_CELL;
                return result;
            }
        }

        const diameter = 2 * radiusInCm;
        const posOrNegONE_CELL = ONE_CELL * posOrNegOne;
        const newSize = diameter + posOrNegONE_CELL;

        return withHole
            ? newSize < THREE_CELLS //  With hole -> at least three-cells diameter
            : newSize < ONE_CELL; // No hole -> at least one-cell diameter
    }
    return false;
};

const isRectangleAndTooSmall = (scale, crParams) => {
    if (crParams.type !== "rectangle") {
        return false;
    }
    const block = 250;
    const minSize = crParams.withHole ? block * 3 : block;
    return (
        crParams.rectangleDistCmX + block * scale < minSize ||
        crParams.rectangleDistCmY + block * scale < minSize
    );
};

/**
 *
 * @param {*} element
 * @param {number} posOrNegOne
 * @param {function} handleChange
 */
export const polyScale = (element, posOrNegOne, handleChange) => {
    if (![-1, 1].includes(posOrNegOne)) {
        dbg(() => console.error(`Invalid scale param ${posOrNegOne}, valid : [-1, 1]`));
    }
    if (element === undefined) {
        // case: user has not yet added any elements
        dbg(() => console.error("Trying to scale an undefined element", element, posOrNegOne, handleChange));
        return;
    }

    const crParams = element.creationParams;
    if (posOrNegOne === undefined) {
        dbg(() => console.warn("Scale missing, using scale=1", element));
        posOrNegOne = 1;
    }
    if (isArcAndTooSmall(posOrNegOne, crParams) || isRectangleAndTooSmall(posOrNegOne, crParams)) {
        dbg(() => log(
            `not scaling lower than 250cm; scale='${posOrNegOne}' crParams:`,
            crParams.type === "rectangle"
                ? [crParams.rectangleDistCmX, crParams.rectangleDistCmY, crParams.singleCornerWidth]
                : crParams.radiusInCm
        ));
        return;
    }
    if (element && element.getPath()) {
        const crParams = element.creationParams;

        // update the creationParams to keep them in-sync
        if (crParams.type === ARC_TYPE) {
            dbg(() => log(
                `scaling; scale='${posOrNegOne}' crParams:`,
                crParams.withHole
                    ? JSON.stringify([crParams.borderThickness, crParams.directionalRadiusInCm])
                    : crParams.radiusInCm
            ));


            // 1.) the arc is small and we remember where its center (o) is
            const preChangeCenter = crParams.getCenter();
            if(crParams.isFullCircle()) {
                // Full circles only use directionalRadiusInCm
                // Full circles stay full so we never use the radiusInCm var for them.
                // Full circles can be stretched/squeezed to be ellipses
                const {directionalRadiusInCm: dirRad} = crParams;
                dirRad.x += posOrNegOne*(HALF_A_CELL)
                dirRad.y += posOrNegOne*(HALF_A_CELL)
            } else {
                // We just re-calculate the whole circle with new scale-factor.
                // recalculating applies the new scale and changes the calculated center
                // this means that that we need to translate the arc back from the newCenter to the oldCenter
                // |   | 1. | 2.  | 3.  |
                // |   |    |     |  .  |
                // | o | +) | o.  | + ) |
                // | n | n  | + ) | n.  |
                // |   |    |  .  |     |
                // o = oldCenter    ; ) = small arc
                // n = newCenter    ; .
                // + = actualCenter ;  ) = bigger arc
                //                  ; .
                //
                crParams.radiusInCm += posOrNegOne*HALF_A_CELL;
            }
            // 2.) we scale and re-calculate the bigger arc
            element.creationParams.recalculateAndApplyPaths();
            // 3.) we move translate the arc by the difference of the two remembered centers
            element.creationParams.returnToPreChangeCenter(preChangeCenter);

            dbg(() => log(`Resized to ${
                crParams.isFullCircle() 
                    ? (crParams.radiusInCm / 50) 
                    : ((crParams.directionalRadiusInCm.x/50))+'mx'+((crParams.directionalRadiusInCm.y/50))
            }m`));
        } else if (crParams.type === "rectangle") {
            // Using the old scaleLogic for scaling rounded rectangles resulted in
            // distorted corners, so we just call polyExpand once for each axis and
            // have a non-distorted scaling

            // Skip handleChange calls for expand calls, as we are calling handleChange for the polyScale
            const NOP = () => {};

            // keep ratio of rectangle by not scaling down when one of the sides won't be scaled
            const lessEast = crParams.rectangleDistCmX < crParams.rectangleDistCmY;
            if (polyExpand(element, posOrNegOne, lessEast, NOP)) {
                polyExpand(element, posOrNegOne, !lessEast, NOP);
            }
        } else {
            console.error("Scaling not implemented for type ", crParams.type);
        }
        // notify the state of the update
        handleChange();
    }
};
export default polyScale;
