import PropTypes from "prop-types";
import React from "react";
import SetupDesignerScene from "scenes/2_setup_designer";
import { tlog } from "scenes/2_setup_designer/designer/elements/debugging";

export const SetupDesignerRoute = ({ stateTracked, setStateTracked, saveState }) => {
    tlog("🧩-🚏 SetupDesignerRoute");

    /**
     * When any changes happen to the map-elements/geometry in step3, this
     * method shall be called to update the global state.
     * @param {string} newJSONStingState
     */
    const step2Change = newState => {
        if (newState === {}) {
            return;
        }
        const oldElements = stateTracked.step2.stateObj
            ? stateTracked.step2.stateObj.elements
            : undefined;
        const newElements = newState.elements;
        if (oldElements !== newElements) {
            stateTracked.step2.stateObj = newState;
            setStateTracked(prevState => ({
                ...prevState,
                step2: {
                    ...prevState.step2,
                    stateObj: newState
                }
            }));
        }
    };

    return (
        <SetupDesignerScene
            commonChangedCB={newCommon => {
                setStateTracked(prevState => ({
                    ...prevState,
                    common: { ...newCommon }
                }));
            }}
            step1Changed={newStep1 => {
                setStateTracked(prevState => ({
                    ...prevState,
                    step1: {
                        ...prevState.step1,
                        ...newStep1
                    }
                }));
            }}
            step2Change={newJSONStringState => {
                step2Change(newJSONStringState);
            }}
            stateTracked={stateTracked}
            setStateTracked={setStateTracked}
            saveState={saveState}
        />
    );
};

SetupDesignerRoute.propTypes = {
    stateTracked: PropTypes.object,
    setStateTracked: PropTypes.func
};

export default SetupDesignerRoute;
