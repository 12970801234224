import React, {Profiler, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";

import {useGA} from "scenes/common/useGA";
import {Button} from "components/Button/Button";
import {paths} from "IceRinkApp/route_consts";
import LocationPicker from "scenes/1_location_picker/locationPicker";
import {profilerCallbackHandler} from "scenes/2_setup_designer/designer/elements/debugging";
import "./index.scss";
import {LoadDesignerCodeModal} from "../../components/LoadDesignerCodeModal";

export const BUTTON_WIDTH_PLAN_HERE = 200;

export const LocationPickerScene = ({...params}) => {
  const {t} = useTranslation();
  const [isLoadingModalVisible, setIsLoadingModalVisible] = useState(false);
  const showLoadModal = useCallback(() => {
    setIsLoadingModalVisible(true);
  }, [setIsLoadingModalVisible]);
  const hideLoadModal = useCallback(() => setIsLoadingModalVisible(false), [setIsLoadingModalVisible]);
  useGA();

  return (
    <Profiler id="LocationPickerSceneProfiler" onRender={profilerCallbackHandler}>
      <LoadDesignerCodeModal isOpen={isLoadingModalVisible} onClose={hideLoadModal}/>
      <div className="container">
        {/*  display step 1 -> Location Picker */}
        <div>
          {/* Eisbahn nach Ihrem Wunsch designen. */}
          <h1 className="introText tc1">{t("locationPicker.texts.explanation")}</h1>
          {/* Tipp: um die vorhandene Fläche noch besser zu nutzen, können Sie auch mehrere Formen miteinander kombinieren */}
          <h3 className="introText tc2 highlight">
            {t("locationPicker.texts.intro.line2")}
          </h3>
          <h3 className="introText tc2 highlight">
            {t("locationPicker.texts.intro.line1")}
          </h3>
        </div>
        <div className="innerContainer">
          <LocationPicker {...params} />
          <div className="lowerArea">
            <div className="navbar">
              {/* For padding space */}
              <Button color={"orange"} className={"prev"}>
                <div onClick={() => {
                  showLoadModal()
                }}>{t("locationPicker.buttons.loadFromCode")}</div>

              </Button>
              <Button color={"orange"} className={"next"}>
                <NavLink to={paths.step2}>
                  {t("locationPicker.buttons.acceptLocation")}
                </NavLink>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Profiler>
  );
};

export default LocationPickerScene;
