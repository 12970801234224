export class ElBench {
    /**
     * @class ElBench
     * @param {google} google
     * @param {AmazingStateManagement} aSMgmt
     * @param {google.maps.Map<Element>} map
     * @param {OriginProvider} originProvider
     */
    constructor(google, aSMgmt, map, originProvider, handleDataChange) {
        this.google = google;
        this.aSMgmt = aSMgmt;
        this.map = map;
        this.originProvider = originProvider;
        this.handleDataChange = handleDataChange;
    }
}
