import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import apiRoutes from "apiRoutes";
import { Button } from "components/Button/Button";
import { paths } from "IceRinkApp/route_consts";
import { NavLink } from "react-router-dom";
import { tlog } from "scenes/2_setup_designer/designer/elements/debugging";
import { postData } from "../../Utils2";
import "./index.scss";
import { ResultPopup } from "./ResultPopup";
import { SlotmachineHoffman } from "./SlotmachineHoffman";
import { InputWrapper } from "components/InputWrapper";
import { FormGroup } from "components/FormGroup";

import calIcon from "assets/Icon_Kalender.png";
import ticketIcon from "assets/Icon_Ticket.png";
import skateIcon from "assets/Icon_Schlittschuhe.png";
import skateAidIcon from "assets/Icon_Eislaufhilfe.png";
import locationIcon from "assets/Icon_Ortschaft.png";
import percentageIcon from "assets/Icon_Percentage.png";

import gallery1 from "scenes/0_landing_page/assets/aachen.jpg";
import gallery2 from "scenes/0_landing_page/assets/eppan.jpg";
import gallery3 from "scenes/0_landing_page/assets/art_on_ice.jpg";
import gallery4 from "scenes/0_landing_page/assets/zagreb.jpg";
import { useGA } from "scenes/common/useGA";
import ReactGA from "react-ga"
import { MapPreview } from './MapPreview';

export const ResultsScene = ({ state, saveKeyChanged, saveStateFn }) => {
    useGA();

    const [isAtBottomOfPage, setIsAtBottomOfPage] = useState(false);
    const [city, setCity] = useState("");
    const [form, setForm] = useState({ firstName: "", lastName: "", mail: "", phoneNumber: "" });
    const [isSending, setIsSending] = useState(false);
    const [inquirySend, setInquirySend] = useState(false);

    const stateProp = state;
    const { t } = useTranslation();
    // Todo: More user feedback on saving-state?
    // eslint-disable-next-line no-unused-vars
    const [finishedSpinning, _setFinishedSpinning] = useState(false);
    const setFinishedSpinning = args => {
        tlog("_setFinishedSpinning", args);
        _setFinishedSpinning(args);
    };
    const [slotActive, _setSlotActive] = useState(false);
    const [slotWin, setIsSlotMachineWin] = useState(false);
    const [modalActive, setModalActive] = useState(false);
    const setSlotActive = args => {
        tlog("_setSlotActive", args);
        _setSlotActive(args);
    };
    const [calculationResults, _setCalculationResults] = useState(undefined);
    const setCalculationResults = args => {
        tlog("_setCalculationResults", args);
        _setCalculationResults(args);
    };
    const slotMachineRef = useRef(null);

    window.onscroll = function (ev) {
        setIsAtBottomOfPage(false);
        if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
            setIsAtBottomOfPage(true);
        }
    };

    const onModalCloseRequest = inquiryFormSend => {
        if (inquiryFormSend) {
            setInquirySend(true);
            setTimeout(() => {
                setModalActive(false);
            }, 500);
        } else {
            setModalActive(false);
        }
    };

    const saveState = state => {
        return new Promise((resolve, reject) => {
            tlog("💾 Saving state", state);

            // In:  class WriteStateRequest(val json: String)
            // Out: class WriteStateResult(val uuid: String?, val error: String?)
            // delete state.saveKey;
            postData(apiRoutes.state, {
                json: JSON.stringify(state)
            })
                .then(res => res.json())
                .then(writeStateResult => {
                    if (writeStateResult.error || !writeStateResult.uuid) {
                        reject(writeStateResult.error);
                    } else {
                        saveKeyChanged(writeStateResult.uuid); // stateTracked.saveKey = writeStateResult.uuid
                        resolve(writeStateResult.uuid);
                    }
                })
                .catch(error => {
                    reject("Unexpected: " + error);
                })
        });
    };

    const slotMachineParams = {
        active: { slotActive },
        calculationDone: false,
        finishedSpinningCB: hasWon => {
            setIsSlotMachineWin(hasWon);
            setFinishedSpinning(true);
        }
    };
    const handleForm = e => {
        const { value, name } = e.target;
        setForm({ ...form, [name]: value });
    };

    const formIsValid = () => {
        return (
            form.firstName !== "" &&
            form.lastName !== "" &&
            form.phoneNumber !== "" &&
            form.mail !== ""
        );
    };
    const handleScoll = e => {
        //console.log(slotMachineRef.current.scrollHeight);
    };

    useEffect(() => {
        const userInputs = {
            rentalWeeks: state.step3.rentalTimeInWeeks,
            entryFees: {
                children: state.step3.entryFeeChildren,
                adults: state.step3.entryFeeAdults,
                skateRental: state.step3.rentalFeeSkates,
                skatingAidsRental: state.step3.rentalFeeSkatingAids
            },
            iceRinkArea: state.totalArea,
            iceRinkCircumference: state.step2.stateObj.totalCircumfence
        };
        postData(apiRoutes.calculation, { userInputs })
            .then(res => res.json())
            .then(data => {
                setCalculationResults(
                    t("result.calculation.default", {
                        val0: Math.max(
                            0,
                            data.from).toLocaleString(undefined, { maximumFractionDigits: 0 }
                        ),
                        val1: Math.max(
                            0,
                            data.to).toLocaleString(undefined, { maximumFractionDigits: 0 }
                        )
                    })
                );
            })
            .catch(e => {
                // Ignore issues and suggest contacting directly
                setTimeout(() => {
                    setSlotActive(false);
                }, 250);
            });
        fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${state.step1.lat},${
                state.step1.lng
            }&key=${atob("QUl6YVN5QXBtMG9ZVG1vb0swMHlYdmxXaE1LenhOQWMxZVpaQnlZ")}`
        )
            .then(res => res.json())
            .then(data => {
                data.results.forEach(result => {
                    if (result.types.includes("locality") && city === "") {
                        setCity(result.formatted_address);
                    }
                });
            });
        window.addEventListener("scroll", handleScoll);
    }, []);

    return (
        <div className="container">
            {isSending && (
                <div className="blocking-fullscreen">
                    <div className="loading-spinner" />
                </div>
            )}
            {/* Step4 */}
            <div className="innerContainer">
                <div className="smallSideMargin">
                    <div className={"pre-slot-machine-content"}>
                        <h1>{t("app.bullet.result")}</h1>
                        <h3 className="introText tc2 highlight">{t("app.description.results")}</h3>
                        <FormGroup
                          title={t("result.summary.location", { city: city })}
                          icon={
                            <MapPreview/>
                          }
                        >
                            <div>
                                <img src={locationIcon} />
                                <div>
                                    <h3>{t("parameters.titles.area")}</h3>
                                    <p>{t("result.summary.area", { area: state.totalArea })}</p>
                                </div>
                            </div>
                            <div>
                                <img src={calIcon} />
                                <div>
                                    <h3>{t("parameters.titles.rentTime")}</h3>
                                    <p>
                                        {t("result.summary.rentalTimeInWeeks", {
                                            weeks: state.step3.rentalTimeInWeeks
                                        })}
                                    </p>
                                    <p>
                                        {t("result.summary.rentalTimeInDays", {
                                            days: state.step3.rentalTimeInWeeks * 7
                                        })}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <img src={skateIcon} />
                                <div>
                                    <h3>{t("parameters.titles.rentalFees")}</h3>
                                    <p>
                                        {t("result.summary.rentalFeeSkates", {
                                            fee: state.step3.rentalFeeSkates
                                        })}
                                    </p>
                                    <p>
                                        {t("result.summary.rentalFeeSkateAids", {
                                            fee: state.step3.rentalFeeSkatingAids
                                        })}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <img src={ticketIcon} />
                                <div>
                                    <h3>{t("parameters.titles.entryFee")}</h3>
                                    <p>
                                        {t("result.summary.entryFeeAdults", {
                                            fee: state.step3.entryFeeAdults
                                        })}
                                    </p>
                                    <p>
                                        {t("result.summary.entryFeeChildren", {
                                            fee: state.step3.entryFeeChildren
                                        })}
                                    </p>
                                </div>
                            </div>
                        </FormGroup>
                        {calculationResults === undefined ? null : (
                            <div className={"content-group flex-center"}>
                                <h2 style={{ flexBasis: "auto", fontSize: "40px" }}>
                                    {calculationResults}
                                </h2>
                            </div>
                        )}
                        <div className={"content-group flex-center"}>
                            <h2 style={{ flexBasis: "auto", fontSize: "40px" }}>
                                {t("result.texts.requestinformation")}
                            </h2>
                        </div>
                        {/* <div className={"content-group flex-center text-center"}>
                            <div className={"arrow-container"}>
                                <h4>{t("result.texts.tryYourLuck")}</h4>
                                <p>{t("result.texts.slotMachineWinExplaination")}</p>
                                <div
                                    className={"arrow-down"}
                                    onClick={() => {
                                        slotMachineRef.current.scrollIntoView({
                                            behavior: "smooth",
                                            block: "center",
                                            inline: "center"
                                        });
                                        setTimeout(() => {
                                            setSlotActive(true);
                                        }, 500);
                                    }}
                                >
                                    <i className={"ri-arrow-down-line"}></i>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="lowerArea">
                        <div className="navbar">
                            <Button color={"orange"} className={"prev"}>
                                <NavLink to={paths.step3}>{t("app.buttons.previousStep")}</NavLink>
                            </Button>
                            <Button color={"orange"} disabled={slotActive} className={"next"}>
                                <span
                                    onClick={() => {
                                        saveStateFn();
                                    }}
                                >
                                    {t("footer.titles.saveProgress")}
                                </span>
                            </Button>
                            <Button color={"orange"} disabled={slotActive} className={"next"}>
                                <span
                                    onClick={() => {
                                        setModalActive(true);
                                    }}
                                >
                                    {t("result.buttons.requestestimate")}
                                </span>
                            </Button>
                        </div>
                    </div>
                    <div className={"content-group single-item flex-center"}>
                        <div>
                            <h2>{t("landingPage.gallery.title")}</h2>
                        </div>
                    </div>
                    <div className={"gallery-wrapper"}>
                        <div className={"gallery-element"}>
                            <a
                                href={"http://www.leitner.it/hof"}
                                target={"_blank"}
                                rel={"noopener noreferrer"}
                            >
                                <img src={gallery1} alt={"aachen auf eis - deutschland"} />
                            </a>
                            <div className={"text-content"}>
                                <h3>{t("landingPage.gallery.aachen.title")}</h3>
                                <p>{t("landingPage.gallery.aachen.subtitle")}</p>
                                <a
                                    href={"http://www.leitner.it/hof"}
                                    target={"_blank"}
                                    rel={"noopener noreferrer"}
                                >
                                    <div className={"btn-link"}>
                                        <i className="ri-arrow-right-line"></i>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className={"gallery-element"}>
                            <a
                                href={"http://www.leitner.it/hof"}
                                target={"_blank"}
                                rel={"noopener noreferrer"}
                            >
                                <img src={gallery2} alt={"eisstadion des hc eppan - italien"} />
                            </a>
                            <div className={"text-content"}>
                                <h3>{t("landingPage.gallery.eppan.title")}</h3>
                                <p>{t("landingPage.gallery.eppan.subtitle")}</p>
                                <a
                                    href={"http://www.leitner.it/hof"}
                                    target={"_blank"}
                                    rel={"noopener noreferrer"}
                                >
                                    <div className={"btn-link"}>
                                        <i className="ri-arrow-right-line"></i>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className={"gallery-element"}>
                            <a
                                href={"http://www.leitner.it/hof"}
                                target={"_blank"}
                                rel={"noopener noreferrer"}
                            >
                                <img src={gallery3} alt={"art on ice - basel"} />
                            </a>
                            <div className={"text-content"}>
                                <h3>{t("landingPage.gallery.basel.title")}</h3>
                                <p>{t("landingPage.gallery.basel.subtitle")}</p>
                                <a
                                    href={"http://www.leitner.it/hof"}
                                    target={"_blank"}
                                    rel={"noopener noreferrer"}
                                >
                                    <div className={"btn-link"}>
                                        <i className="ri-arrow-right-line"></i>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className={"gallery-element"}>
                            <a
                                href={"http://www.leitner.it/hof"}
                                target={"_blank"}
                                rel={"noopener noreferrer"}
                            >
                                <img src={gallery4} alt={"zagreb-kroatien"} />
                            </a>
                            <div className={"text-content"}>
                                <h3>{t("landingPage.gallery.zagreb.title")}</h3>
                                <p>{t("landingPage.gallery.zagreb.subtitle")}</p>
                                <a
                                    href={"http://www.leitner.it/hof"}
                                    target={"_blank"}
                                    rel={"noopener noreferrer"}
                                >
                                    <div className={"btn-link"}>
                                        <i className="ri-arrow-right-line"></i>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={"content-group"}>
                        <div>
                            <h2>{t("landingPage.contentGroup3.title")}</h2>
                        </div>
                        <div>
                            <p>{t("landingPage.contentGroup3.texts.firstBlock")}</p>
                            <p>{t("landingPage.contentGroup3.texts.secondBlock")}</p>
                            <p>{t("landingPage.contentGroup3.texts.thirdBlock")}</p>
                            <Button color={"orange"}>
                                <a
                                    href={t("landingPage.contentGroup3.texts.hrefToCatalogue")}
                                    target={"_blank"}
                                    rel={"noopener noreferrer"}
                                >
                                    {t("landingPage.contentGroup3.texts.linkToCataloge")}
                                </a>
                            </Button>
                        </div>
                    </div>
                    <div className={"content-group flex-center text-center"}>
                        {slotWin && <h3>{t("result.texts.slotMachineWinText")}</h3>}
                        {inquirySend && <h3>{t("result.texts.inquirySend")}</h3>}
                    </div>
                </div>

                <ResultPopup modalOpen={modalActive} onClose={() => onModalCloseRequest(false)}>
                    <div className="smallSideMargin">
                        <h1>{t("result.contactform.title")}</h1>
                        <hr />
                        <InputWrapper
                            name={"firstName"}
                            label={`${t("result.contactform.firstName")}*`}
                            type={"text"}
                            value={form.firstName}
                            onChange={handleForm}
                        />
                        <InputWrapper
                            name={"lastName"}
                            label={`${t("result.contactform.lastName")}*`}
                            type={"text"}
                            value={form.lastName}
                            onChange={handleForm}
                        />
                        <InputWrapper
                            name={"phoneNumber"}
                            label={`${t("result.contactform.phoneNumber")}*`}
                            type={"tel"}
                            value={form.phoneNumber}
                            onChange={handleForm}
                        />
                        <InputWrapper
                            name={"mail"}
                            label={`${t("result.contactform.mail")}*`}
                            type={"mail"}
                            value={form.mail}
                            onChange={handleForm}
                        />
                        <div
                            onClick={() => {
                                if (formIsValid() && !isSending) {
                                    setIsSending(true);
                                    saveState(stateProp).then(saveId => {
                                        postData(apiRoutes.inquiry, {
                                            ...form,
                                            slotMachineWinner: slotWin,
                                            city: city,
                                            sssId: saveId
                                        })
                                            .then(() => onModalCloseRequest(true))
                                            .finally(() => {
                                                setIsSending(false);
                                                ReactGA.event({
                                                    category: "IceRink",
                                                    action: "inquiry-send",
                                                    label: saveId,
                                                    nonInteraction: false
                                                });}
                                            );
                                    });
                                }
                            }}
                        >
                            <Button className={"right"} color={"orange"}>
                                {t("result.texts.getaquote")}
                            </Button>
                        </div>
                    </div>
                </ResultPopup>
            </div>
            {!isAtBottomOfPage && (
                <div className={"arrow-container fixed-bottom animated"}>
                    <div className={"arrow-down"}>
                        <i className={"ri-arrow-down-line"}></i>
                    </div>
                </div>
            )}
        </div>
    );
};

ResultsScene.propTypes = {
    saveKeyChanged: PropTypes.func,
    state: PropTypes.object.isRequired
};

export default ResultsScene;
