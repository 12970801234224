import drawArc from "../elements/arc/ArcDrawing";
import { tlog } from "../elements/debugging";
import { Grid } from "../elements/grid/GridClass";
import createRectGooglePoly from "../elements/rectangle/RectangleDrawing";
import AmazingStateManagement from "../state/AmazingStateManagement";
import { ElBench } from "./ElBench";
import { ONE_CELL, RECTANGLE_ROUNDING_OFFSET } from "../elements/rectangle/RectangleConsts";
import { RectangleCreationParams } from "../elements/rectangle/RectangleCreationParams";
import ArcCreationParams from "../elements/arc/ArcCreationParams";

/**
 * "React to Google Maps" class for relaying react calls to the relevant other classes.
 * Might be redundant.
 */
export class R2GM {
    /**
     * @param {google.maps.Map<Element>} map
     * @param {google.maps.LatLng | google.maps.LatLngLiteral} firstCenter
     * @param {OriginProvider} originProvider
     */
    constructor(map, google, firstCenter, originProvider, handleDataChange) {
        tlog("R2GM Constructor");
        const aSMgmt = new AmazingStateManagement({
            map,
            originProvider,
            handleDataChange
        });
        this.originProvider = originProvider;
        this.elBench = new ElBench(google, aSMgmt, map, originProvider, handleDataChange);
        aSMgmt.polyconCreator.setElBench(this.elBench);
        this.elBench.aSMgmt.setGrid(new Grid(this.elBench, firstCenter));
        window.debug.logic = this; // todo remove me
    }

    updateHandleDataChangeImpl(fn) {
        this.elBench.handleDataChange = fn;
    }

    getASMgmt() {
        return this.elBench.aSMgmt;
    }

    /**
     * @param {number} rectangleEdgeLengthInMetersX
     * @param {number} rectangleEdgeLengthInMetersY
     * @param {boolean} useRoundedCorners - true ->draw arcs instead of sharp edges
     * @param {boolean} withHole - true ->add an additional smaller rectangle to the paths
     * @param {google.maps.LatLngLiteral} alternativeOrigin - alternative origin to use instead of the elBench.origin
     * @returns {object} newly created element
     */
    addRectangle(
        rectangleEdgeLengthInMetersX,
        rectangleEdgeLengthInMetersY,
        useRoundedCorners,
        withHole,
        alternativeOrigin
    ) {
        const eb = this.elBench;
        const crParams = new RectangleCreationParams({
            origin: alternativeOrigin ? alternativeOrigin : eb.originProvider.getOrigin(),
            rectangleDistCmX: rectangleEdgeLengthInMetersX * 100,
            rectangleDistCmY: rectangleEdgeLengthInMetersY * 100,
            withHole,
            useRoundedCorners,
            roundingOffsetInCm: RECTANGLE_ROUNDING_OFFSET,
            borderThickness: ONE_CELL
        });
        const param = createRectGooglePoly(eb, crParams);
        eb.aSMgmt.addElement(param);
        return param;
    }
    /**
     * @param {number} arcLength
     * @param {number} circleRadiusInMeters
     * @param {boolean} withHole
     * @param {number} borderThickness
     * @param {google.maps.LatLngLiteral?} alternativeOrigin or undefined
     * @returns {object} newly created element
     */
    addPolyArc(arcLength, circleRadiusInMeters, withHole, borderThickness, alternativeOrigin) {
        const eb = this.elBench;
        const currentOrigin = alternativeOrigin ? alternativeOrigin : eb.originProvider.getOrigin();

        const radius = circleRadiusInMeters * 100;
        const arcCreationParams = new ArcCreationParams({
            centerPoint: currentOrigin,
            radiusInCm: radius,
            directionalRadiusInCm: { x: radius, y: radius },
            angleStart: 0,
            angleEnd: arcLength,
            withHole: withHole,
            borderThickness: borderThickness
        })

        const param = drawArc(eb, arcCreationParams);
        eb.aSMgmt.addElement(param);
        return param;
    }

    // minimal parameters
    updateCenter(center) {
        const mapCenter = this.elBench.map.getCenter();
        if (mapCenter.lat() === center.lat && mapCenter.lng() === center.lng) {
        } else {
            this.elBench.map.setCenter(center);
            this.originProvider.setOrigin(center);
            tlog("🔁 updateCenter>handleDataChange");
            this.elBench.handleDataChange();
        }
    }

    ready = true;
}
