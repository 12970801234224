import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Modal from "react-modal";
import "./index.scss";

const customStyles = {
    content: {
        position: "absolute",
        top: "40px",
        left: "40px",
        right: "40px",
        bottom: "40px",
        border: "1px solid #ccc",
        background: "#fff",
        overflow: "auto",
        WebkitOverflowScrolling: "touch",
        borderRadius: "4px",
        outline: "none",
        padding: "20px",
        height: "650px",
        margin: "0 auto",
        width: "700px"
        // top: '50%',
        // left: '50%',
        // right: 'auto',
        // bottom: 'auto',
        // marginRight: '-50%',
        // transform: 'translate(-50%, -50%)'
    }
};

export const ResultPopup = ({ modalOpen, onClose, children }) => {
    useEffect(() => {
        Modal.setAppElement("#appRoot");
    });

    return (
        <div>
            <Modal
                isOpen={modalOpen}
                onRequestClose={onClose}
                style={customStyles}
                contentLabel="contactForm"
            >
                <div className={"resultPopup "}>{children}</div>
            </Modal>
        </div>
    );
};

ResultPopup.propTypes = {
    children: PropTypes.element.isRequired,
    active: PropTypes.object
};

export default ResultPopup;
