import { RectangleClass } from "./RectangleClass";
import createPopupClass from "../PopupClass";

/**
 * Creates a new googlePoly representing a rectangle, the RectangleClass is attached as *.parent
 * @param {ElBench} elBench
 * @param {RectangleCreationParams} creationParams
 * @returns {google.maps.Polygon}
 */
const createRectGooglePoly = (elBench, creationParams) => {
    // Pass the validated params to create a new RectangleClass
    return new RectangleClass({ creationParams, elBench, createPopupClass }).googlePoly;
};
export default createRectGooglePoly;
