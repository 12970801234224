import React, { useState, useEffect, useCallback } from "react";
import Modal from "react-modal";
import ReactGA from "react-ga";

import { Button } from "components/Button/Button";
import { defaultGridSizeInBlocks, locationPickerMapOptionDefaults } from "Configuration";
import { paths } from "IceRinkApp/route_consts";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { tlog } from "scenes/2_setup_designer/designer/elements/debugging";
import { USING_GOOGLE_MAPS_API_KEY } from "../../../Configuration";
import { dismissKeyMissingPopup } from "../../2_setup_designer/designer/dismissKeyMissingPopup";
import { defaultStep1RectangleSettings } from "../../2_setup_designer/designer/elements/common/DefaultPolySettings";
import whenGoogleIsLoaded from "../../2_setup_designer/designer/WhenGoogleIsLoaded";
import { latLngFnToNum, latWest, lngNorth, round3, waitForElement } from "../../common/Utils";
// import '../gmaps_debug.css';
import arrow from "./assets/Icon_Pfeil.png";
import "./index.scss";

class MockGeoCoder {
    constructor() {
        this.result = [
            {
                geometry: {
                    location: {
                        lat: 51.001,
                        lng: 9.581
                    }
                }
            }
        ];
    }
    geocode = (address, callback) => {
        callback(this.result, "OK");
    };
}

let mockGeoCoder = new MockGeoCoder();

/**
 * Todo:
 * - Location init via https://developers-dot-devsite-v2-prod.appspot.com/maps/documentation/javascript/examples/map-geolocation e.g. via locate me button
 * - Geocoding Service API key
 * - reduce foo bar construct
 */
class Geocoding {
    constructor(geocoder, resultsMap) {
        this.geocoder = geocoder;
        this.resultsMap = resultsMap;
    }
    geocodeAddress = (e, successCallback) => {
        tlog("locationPicker|GeoCoding.geocodeAddress: retrieved request", e);
        const self = this;
        var address = document.getElementById("address").value.trim();
        if (address.length === 0) {
            return;
        }
        this.geocoder.geocode({ address: address }, function (results, status) {
            if (status === "OK") {
                const resultLatLng = results[0].geometry.location;
                tlog(
                    "locationPicker|GeoCoding.geocodeAddress: OK -> ",
                    results,
                    latLngFnToNum(resultLatLng)
                );
                self.resultsMap.setCenter(resultLatLng);
                successCallback(resultLatLng);
                const map = self.resultsMap;
                // Todo: for some reason the first setCenter doesn't have the
                // desired move-the-map effect, so we just fire it twice then (for now)
                if (map.getCenter() !== resultLatLng) {
                    tlog("First #find setCenter call");
                    self.resultsMap.setCenter(resultLatLng);
                }
            } else if (status === "ZERO_RESULTS") {
                tlog("Google::GeoCode => No results");
            } else {
                const { t } = useTranslation();
                console.error(t("exceptions.geocoder.failedWithReason", { var1: status }));
            }
        });
    };
}

const geocodingWrapper = {
    geocodingInstance: {
        geocodeAddress: (e, gcCB) => {
            console.error("geocodingWrapper#geocodingInstance#geocodeAddress");
        }
    },
    locate: () => {
        console.error("geocodingWrapper#locate");
    }
};

export default ({ step1, step1ChangedCB, common, commonChangedCB }) => {
    const { t } = useTranslation();

    const booleanVar = !!navigator.geolocation;
    let [hasLocatorFoo, setHasLocatorFoo] = useState(booleanVar);
    let [isInitialized, setIsInitialized] = useState(false);
    let [parsedStep1Once, setParsedStep1Once] = useState(false);
    let { lat, lng } = { ...step1 };

    const [isAlertModalVisible, setisAlertModalVisible] = useState(false);
    const showAlert = useCallback(message => {
        setAlertMessage(message);
        setisAlertModalVisible(true);
    });
    const hideAlert = useCallback(() => setisAlertModalVisible(false));
    const [alertMessage, setAlertMessage] = useState("");

    tlog("step1", step1, isInitialized, !!window.debug.pickMap);

    if (!isInitialized) {
        setIsInitialized(true);
        whenGoogleIsLoaded
            .then(google => {
                const mapInit = mapElement => {
                    // origin defined in state
                    const origin = {
                        lat: parseFloat(lat),
                        lng: parseFloat(lng)
                    };

                    const map = new google.maps.Map(mapElement, {
                        ...locationPickerMapOptionDefaults(google),
                        zoom: common.zoom,
                        center: origin,
                        mapTypeId: google.maps.MapTypeId.HYBRID
                    });

                    window.debug.pickMap = map;

                    const marker = new google.maps.Marker({
                        position: map.center,
                        map,
                        title: "Hello World!"
                    });

                    const calcBounds = () => {
                        const ref = (defaultGridSizeInBlocks * 250) / 2;
                        const turfCenter = [map.center.lat(), map.center.lng()];
                        const bounds = {
                            north: latWest(turfCenter, ref),
                            south: latWest(turfCenter, -ref),
                            east: lngNorth(turfCenter, ref),
                            west: lngNorth(turfCenter, -ref)
                        };
                        return bounds;
                    };

                    const gridBorder = new google.maps.Rectangle({
                        ...defaultStep1RectangleSettings,
                        map: map,
                        bounds: calcBounds()
                    });

                    map.addListener("zoom_changed", () => {
                        commonChangedCB({ ...common, zoom: map.getZoom() });
                        // zooming via mouse (instead of zoom controls) also changes location
                        const { lat, lng } = map.getCenter();
                        step1ChangedCB({ ...step1, lat, lng });
                    });
                    map.addListener("dragend", () => {
                        const { lat, lng } = map.getCenter();
                        step1ChangedCB({ ...step1, lat, lng });
                    });

                    map.addListener("center_changed", () => {
                        marker.setPosition(map.center);
                        gridBorder.setBounds(calcBounds());
                    });

                    var geocoder = USING_GOOGLE_MAPS_API_KEY
                        ? new google.maps.Geocoder()
                        : mockGeoCoder;
                    geocodingWrapper.geocodingInstance = new Geocoding(geocoder, map);

                    geocodingWrapper.locate = () => {
                        navigator.geolocation.getCurrentPosition(
                            position => {
                                var pos = {
                                    lat: position.coords.latitude,
                                    lng: position.coords.longitude
                                };
                                map.setCenter(pos);
                            },
                            e => {
                                console.error(true, map.getCenter(), e);
                                showAlert(e.message);
                                setHasLocatorFoo(false);
                            }
                        );
                    };

                    if (google.maps.places) {
                        window.debug.places = window.debug.places ? window.debug.places : [];
                        waitForElement(
                            el => {
                                var input = el.querySelector("#address");

                                const autocomplete = new google.maps.places.Autocomplete(input);
                                // ⚠️💸⚠️- if setFields isn't called we are billed for all fields - ⚠️💸⚠️
                                autocomplete.setFields(["geometry"]);
                                // ⚠️💸⚠️- if setFields isn't called we are billed for all fields - ⚠️💸⚠️
                                autocomplete.bindTo("bounds", map);
                                autocomplete.addListener("place_changed", function () {
                                    const place = autocomplete.getPlace();
                                    tlog(place);
                                    if (!place || !place.geometry) {
                                        return;
                                    }
                                    window.debug.places.push(place);

                                    // If the place has a geometry, then present it on a map.
                                    if (place.geometry.viewport) {
                                        map.fitBounds(place.geometry.viewport);
                                    } else {
                                        map.setCenter(place.geometry.location);
                                        map.setZoom(17);
                                    }
                                });
                                window.autocomplete = autocomplete;

                                // Remove the auto-complete-dropdown should we get any "api-key-and-billing-errors"
                                {
                                    const getError = () =>
                                        document.querySelector(".pac-container.hdpi td a");
                                    const handleInput = () => {
                                        const removeError = i => {
                                            setTimeout(() => {
                                                const error = getError();
                                                if (
                                                    error &&
                                                    (error.href + "").includes(
                                                        "api-key-and-billing-errors"
                                                    )
                                                ) {
                                                    google.maps.event.clearInstanceListeners(
                                                        autocomplete
                                                    );
                                                    error.parentElement.parentElement.parentElement.parentElement.remove();
                                                    input.removeEventListener("input", handleInput);
                                                } else if (i > 0) {
                                                    removeError(i - 1);
                                                } else {
                                                    tlog("iError", i);
                                                }
                                            }, 100);
                                        };
                                        removeError(10);
                                    };
                                    input.addEventListener("input", handleInput);
                                }

                                //map.controls[TOP_CENTER].push(el);
                            },
                            () => document.getElementById("searchBox")
                        );
                    } else {
                        console.warn("[google] Places-API disabled, not enabling auto-complete");
                    }

                    const { TOP_CENTER, RIGHT_BOTTOM } = google.maps.ControlPosition;

                    waitForElement(
                        el => {
                            map.controls[TOP_CENTER].push(el);
                        },
                        () => document.getElementById("searchBox")
                    );
                    // waitForElement((el) => { map.controls[BOTTOM_CENTER].push(el); }, () => document.getElementById('acceptLocationBox'));
                    waitForElement(
                        el => {
                            map.controls[RIGHT_BOTTOM].push(el);
                        },
                        () => document.getElementById("locateBox")
                    );

                    !USING_GOOGLE_MAPS_API_KEY && dismissKeyMissingPopup();
                };

                waitForElement(mapInit, () => document.getElementById("locationPickerMap"));
            })
            .catch(() => {
                tlog("Failed to load 'google' variable - maybe the script tag is missing?");
            });
    } else {
        // todo: don't use window.debug
        if (window.debug.pickMap && !parsedStep1Once) {
            const center = window.debug.pickMap.getCenter();
            if (center.lat() !== step1.lat || center.lng() !== step1.lng) {
                window.debug.pickMap.setCenter({
                    lat: step1.lat,
                    lng: step1.lng
                });
                setParsedStep1Once(true);
            }
        }
    }
    const preMapAttachmentStyle = { position: "fixed", visibility: "hidden" };

    const debuggingEnabled = !!JSON.parse(window.localStorage.getItem("debug"));

    const onGeoCodeMapMove = latLngObj => {
        step1ChangedCB({ ...step1, ...latLngObj });
        console.log(latLngObj);
        fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLngObj.lat()},${latLngObj.lng()}&key=${atob(
                "QUl6YVN5QXBtMG9ZVG1vb0swMHlYdmxXaE1LenhOQWMxZVpaQnlZ"
            )}`
        )
            .then(res => res.json())
            .then(data => {
                data.results.forEach(result => {
                    if (result.types.includes("locality")) {
                        ReactGA.event({
                            category: "IceRink",
                            action: "picked_location",
                            label: `${result.formatted_address}`,
                            nonInteraction: false
                        });
                    }
                });
            });
    };

    const findLocation = e => {
        geocodingWrapper.geocodingInstance.geocodeAddress(e, onGeoCodeMapMove);
    };

    return (
        <div id="locationPicker">
            <AlertPopup modalOpen={isAlertModalVisible} onClose={hideAlert}>
                <h1>{t("modals.alert.title")}</h1>
                <p>{alertMessage}</p>
                <div onClick={hideAlert} className={"modal-close"}>
                    <Button color={"orange"}>{t("app.general.close")}</Button>
                </div>
            </AlertPopup>
            <div id="locationPickerMap"></div>
            <div
                id="PlaceToHideBeforeAttachingToMap"
                style={{
                    position: "fixed",
                    visibility: "hidden",
                    top: 0,
                    left: 0
                }}
            >
                <div id="searchBox" style={preMapAttachmentStyle}>
                    <span className={"descriptionText"}>
                        {t("locationPicker.texts.textBeforeArrow")}
                    </span>
                    <img src={arrow} alt={"arrow"} />
                    <input
                        id="address"
                        type="text"
                        placeholder={t("locationPicker.texts.targetAddress")}
                        onKeyUp={e => {
                            if (e.keyCode === 13) {
                                findLocation(e);
                            }
                        }}
                    />
                    <span id="find" onClick={findLocation}>
                        <Button color={"orange"}>{t("locationPicker.buttons.find")}</Button>
                    </span>
                </div>
                <div id="acceptLocationBox" style={preMapAttachmentStyle}>
                    <Link id="acceptLocation" to={paths.step2}>
                        <Button color={"orange"}>
                            {t("locationPicker.buttons.acceptLocation")}
                        </Button>
                    </Link>
                    {debuggingEnabled && (
                        <p>
                            Lat: {round3(lat)}, Lng: {round3(lng)}
                        </p>
                    )}
                </div>
                {
                    <div id="locateBox" style={preMapAttachmentStyle}>
                        <button
                            id="locate"
                            className={hasLocatorFoo ? "works" : "broken"}
                            onClick={() => {
                                geocodingWrapper.locate();
                            }}
                        ></button>
                    </div>
                }
            </div>
        </div>
    );
};

const customStyles = {
    content: {
        position: "absolute",
        top: "40px",
        left: "40px",
        right: "40px",
        bottom: "40px",
        border: "1px solid #ccc",
        background: "#fff",
        overflow: "auto",
        WebkitOverflowScrolling: "touch",
        borderRadius: "4px",
        outline: "none",
        padding: "20px",
        height: "300px",
        margin: "0 auto",
        width: "700px"
        // top: '50%',
        // left: '50%',
        // right: 'auto',
        // bottom: 'auto',
        // marginRight: '-50%',
        // transform: 'translate(-50%, -50%)'
    }
};

const AlertPopup = ({ modalOpen, onClose, children }) => {
    useEffect(() => {
        Modal.setAppElement("#appRoot");
    });

    return (
        <div>
            <Modal
                isOpen={modalOpen}
                onRequestClose={onClose}
                style={customStyles}
                contentLabel="alert"
            >
                <div className={"alertPopup"}>{children}</div>
            </Modal>
        </div>
    );
};
