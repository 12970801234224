import { point } from "@turf/helpers";
import turfEllipse from "@turf/ellipse";
import turfLineArc from "@turf/line-arc";
import { GenericCoord } from "../common/GenericCoord";
import {objToArrLngLat, lngLatArrToObj} from "../../../../common/Utils";

const turf = {
    lineArc: turfLineArc,
    point
};

const ARC_DETAIL = 10;

/**
 *
 * @param {Object} centerPoint {lat,lng}
 * @param {number} radiusInCm
 * @param {{x: number, y:number}} directionalRadiusInCm
 * @param {number} angleStart Start of angle (0 = right of center Point)
 * @param {number} angleEnd End of angle (Math.PI = half-circle)
 * @param {boolean} withHole
 * @param {number} borderThickness
 * @returns {{lng: number, lat: number}[][]}
 */
/**
 * @param {ArcCreationParams} creationParams
 * @returns {LatLngLiteral[][]}
 */
const calculateArc = (creationParams) => {
    // Validate params
    creationParams.validate();
    const {
        centerPoint,
            radiusInCm,
            angleStart,
            angleEnd,
            withHole,
            borderThickness,
            directionalRadiusInCm
    } = creationParams;

    // Center point for non-circles
    const centerLngLat = new GenericCoord({ literal: centerPoint }).lngLat;
    // turf doesn't expect radients
    const rad2Grad = rad => (rad / Math.PI) * 180;

    // Method to calculate the coordinates for the given arc
    const calcArc = radius =>
        turf.lineArc(centerLngLat, radius, rad2Grad(angleStart), rad2Grad(angleEnd), {
            steps: Math.PI * ARC_DETAIL,
            units: "centimeters"
        });

    const isFullCircle = angleEnd === Math.PI * 2;
    // Given the radius of the outer circle and the border-thickness,
    // calculate the radius of the inner circle
    // by subtracting the border-thickness from the radius of the outer circle
    const innerRadius = radiusInCm - borderThickness;

    let arcOuter_, arcInner_;
    if(isFullCircle) {
        const center = objToArrLngLat(creationParams.centerPoint); //center: Coord,

        const ellipseForRadius = (directionalRadiusInCm) => {
            const {x,y} = directionalRadiusInCm;
            if(x === undefined || y === undefined) {
                throw Error(`DirRad child undef ${x}x${y}`);
            }
            const xSemiAxis = x; //xSemiAxis: number,
            const ySemiAxis = y; //ySemiAxis: number,

            /**
             * With this detail level the user can squeeze the ellipse to 14:1 ratio
             * with it still being somewhat round
             * @type {number} steps=coords per ellipse
             */
            const STILL_ROUNDED_AT_14_TO_1_RATIO = 512;
            const options = {
                steps: STILL_ROUNDED_AT_14_TO_1_RATIO, //steps?: number; 64 ;
                units: 'centimeters' //units?: Units;
                //properties?: Properties;
            }
            const ellipse = turfEllipse(center,xSemiAxis,ySemiAxis,options);
            ellipse.geometry.coordinates = ellipse.geometry.coordinates[0];
            return ellipse;
        }
        // const coords = ellipse.geometry.coordinates//;.map(coord => lngLatArrToObj(coord));
        console.log("DirectionalRadiusInCm", creationParams, directionalRadiusInCm)
        const {x,y} = directionalRadiusInCm;
        arcOuter_ = ellipseForRadius(directionalRadiusInCm);
        if(withHole) {
            const innerX = (x - borderThickness);
            const innerY = (y - borderThickness);
            if(innerX <= 0 || innerY <= 0) { throw Error(`Negative or zero hole radius x:${innerX}, y:${innerX}`)}
            arcInner_ = ellipseForRadius({x: innerX, y: innerY});
        } else {
            arcInner_ = {geometry:{coordinates:[]}}
        }
    } else {
        arcOuter_ = calcArc(radiusInCm);
        arcInner_ = calcArc(innerRadius);
    }

    const arcOuter = arcOuter_;
    const arcInner = arcInner_;


    /**
     * @type {number[][][]}
     * [center+$outerCircle] or [$outerCircle] or [$outerCircle, $innerCircle] or [$innerCircle+$outerCircle]
     */
    let resultLngLat;

    if (withHole) {
        if (isFullCircle) {
            // [OuterArc, InnerArc]
            resultLngLat = [
                [...arcOuter.geometry.coordinates],
                [...arcInner.geometry.coordinates.reverse()]
            ];
        } else {
            // [InnerArc+OuterArc]
            resultLngLat = [
                [...arcInner.geometry.coordinates, ...arcOuter.geometry.coordinates.reverse()]
            ];
        }
    } else {
        if (isFullCircle) {
            // [OuterArc] (don't add center point)
            resultLngLat = [[...arcOuter.geometry.coordinates]];
        } else {
            // [OuterArc+CenterPoint] (with center point)
            resultLngLat = [[centerLngLat, ...arcOuter.geometry.coordinates]];
        }
    }

    return resultLngLat.map(innerOrOuter => innerOrOuter.map(lngLatArrToObj));
};

export default calculateArc;
