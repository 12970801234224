import apiRoutes from "apiRoutes";
import { dbg, tlog } from "scenes/2_setup_designer/designer/elements/debugging";
import {LS_STATE_02_KEY} from "../scenes/2_setup_designer/designer/DesignerConstants";

/**
 * Depends on localStorage, console
 *
 * @param {string} saveId Hash of the save-state to be loaded
 * @returns {Promise} stateFromJSON
 */
function loadSaveStateViaGET(saveId) {
    return new Promise((resolve, reject) => {
        fetch(`${apiRoutes.state}?hash=${saveId}`)
            .then(response => response.json())
            .then(data => {
                tlog(data);
                if (data.json) {
                    const stateFromJSON = JSON.parse(data.json);
                    stateFromJSON.dataSource = "StateRetrieval#loadSaveStateViaGET";
                    dbg(() => console.log("StateRetrieval#loadSaveStateViaGET", stateFromJSON));
                    resolve(stateFromJSON);
                } else {
                    reject(data.message);
                }
            })
            .catch(e => {
                console.error("StateRetrieval#loadSaveStateViaGET", e);
                reject("StateRetrieval#loadSaveStateViaGET" + JSON.stringify(e));
            });
    });
}

/**
 *
 * @param {object} browserHistory
 * @param {object} stateTrackedWrapped {stateTracked: stateTracked} so it can be overwritten
 * @param {function} setStateTracked
 * @returns {Promise} null | {holdsNewState: true, stateTracked: {...}}
 */
export const whenStateLoadedViaURLParam = () => {
    const saveId = new URLSearchParams(window.location.search).get("saveId");

    return new Promise((resolve, reject) => {
        // e.g. result?saveId=...

        const saveIdHandled = new Promise((resolve, reject) => {
            if (saveId == null) {
                //the_usual
                resolve(null);
            } else {
                tlog(`SaveId '${saveId}' in url.search, attempting to load save-state`);
                loadSaveStateViaGET(saveId).then(resolve).catch(reject);
            }
        });

        saveIdHandled
            .then(stateFromDB => {
                if (stateFromDB === null) {
                    resolve({ holdsNewState: false });
                } else {
                    // Todo: redundant?
                    localStorage.setItem(LS_STATE_02_KEY, JSON.stringify(stateFromDB.step2.stateObj));
                    resolve({ holdsNewState: true, stateTracked: stateFromDB });
                }
            })
            .catch(reject);
        // promise-end
    });
};
