import PropTypes from "prop-types";
import React, { Suspense, useEffect, useState } from "react";
import Routing from "../Routing";
import "./index.scss";

/**
 * The DelayedError shows a placeholder for 1.5 seconds and then should it
 * not yet have been replaced displays an error (child-elements).
 * This way the App can be loaded without flickering an error while loading.
 *
 * @param {import('react').Component[]} children
 */
const DelayedError = ({ children }) => {
    const [errorTimeout, setErrorTimeout] = useState(false);
    const timeoutDelayedError = setTimeout(() => {
        setErrorTimeout(true);
    }, 1500);
    useEffect(() => {
        return () => {
            clearTimeout(timeoutDelayedError);
        };
    });
    return errorTimeout ? <div>{children}</div> : <div id="appPlaceholder" />;
};

DelayedError.propTypes = {
    children: PropTypes.any
};

/**
 * This is the main entry point into our application.
 * First we load the localization for i18next, while displaying the `DelayedError`-Element.
 * When the localization is loaded we instead show the `Routing`-Element, now with fitting i18n.
 */
export default function App() {
    // While loading the localization display gray blob, on fail display "Localization could not be loaded."
    return (
        <div id="appRoot">
            <Suspense fallback={<DelayedError>Localization could not be loaded.</DelayedError>}>
                <Routing />
            </Suspense>
        </div>
    );
}
