import PropTypes from "prop-types";
import React from "react";
import { getWindowDebug } from "scenes/2_setup_designer/designer/elements/debugging";
import { round3 } from "scenes/common/Utils";
import "./index.scss";
import {setDebugMessages} from "../../scenes/2_setup_designer/designer/elements/debugging";

/**
 * Debugging display included  in Routing.jsx enabled by setting localstorage item "debug" to true
 */
export const DebugStateDisplay = ({ state }) => {
    const appStateObj = state();
    const elementTypes = appStateObj.step2.stateObj.elements
        .map(e => (e.type === "rectangle" ? "◼️" : "⚫"))
        .join(" ");
    const text = JSON.stringify(appStateObj, undefined, "    ");
    setDebugMessages(JSON.stringify(appStateObj.step2.stateObj.elements.map(e => e.borderThickness), undefined, "    "));

    let i = 0;
    let total = 0;
    const mapped = appStateObj.step2.stateObj.elements.map(crParams => {
        if (crParams.type === "rectangle") {
            const area = round3(
                ((crParams.rectangleDistCmX / 100) * crParams.rectangleDistCmY) / 100
            );
            total += area;
            const result = (
                <tr key={i++}>
                    <td>{`◼️ ${crParams.rectangleDistCmX / 100}m x ${
                        crParams.rectangleDistCmY / 100
                    }m`}</td>
                    <td>{`${area}m²`}</td>
                </tr>
            );
            return result;
        } else {
            const angleDiff = crParams.angleEnd - crParams.angleStart;
            const partOfFullCircle = angleDiff / (Math.PI * 2);
            const area =
                round3(Math.pow(crParams.radiusInCm / 100, 2) * Math.PI) * partOfFullCircle;
            total += area;
            const result = (
                <tr key={i++}>
                    <td>{`⚫ r: ${crParams.radiusInCm * 2}`}</td>
                    <td>{`${area}m²`}</td>
                </tr>
            );
            return result;
        }
    });

    let debuggingEnabled = !!JSON.parse(window.localStorage.getItem("debug"));
    const messageAsJSONOrNot = () => {
        try {
            JSON.parse(getWindowDebug().messages)
            return getWindowDebug().messages;
        } catch(error) {
            return "window.debug " + JSON.stringify(getWindowDebug().messages, null, "    ");
        }
    };

    return debuggingEnabled ? (
        <div className="debugStateDisplay">
            <table>
                <thead>
                    <tr>
                        <th>Sizes</th>
                        <th>Area</th>
                    </tr>
                </thead>
                <tbody>{mapped}</tbody>
            </table>
            <hr />
            <span>{`~${round3(total)}m²`}</span>
            <p>{elementTypes}</p>
            <pre>{text}</pre>
            <div className="floatingWindow">
                <pre>{messageAsJSONOrNot()}</pre>
            </div>
        </div>
    ) : (
        <div></div>
    );
};

DebugStateDisplay.propTypes = {
    state: PropTypes.func
};

export default DebugStateDisplay;
