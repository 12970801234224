/* eslint-disable no-use-before-define */

// noinspection ES6ConvertVarToLetConst
var window = window ? window : {location: {origin: "http://localhost:3000"}};
/* eslint-enable no-use-before-define */

const pagePrefix = window.location.origin;

export const paths = {
  root: "/",
  step1: "",
  step2: "/designer",
  step3: "/pricing",
  step4: "/result",
  config: "/config"
};

const urls_ = {};
Object.keys(paths).forEach(key => (urls_[key] = pagePrefix + paths[key]));
/**
 * @type {Object<string, string>} urls
 */
export const urls = urls_;
