let whenGoogleIsLoaded;
{
    let i = 0;
    whenGoogleIsLoaded = new Promise((resolve, reject) => {
        const waitForGoogleMaps = () => {
            if (typeof google === "undefined") {
                if (i > 8 * 15) {
                    reject("Failed to load google maps within " + (125 * 8 * 15) / 1000 + " sec");
                } else {
                    i++;
                    setTimeout(waitForGoogleMaps, 125);
                }
            } else {
                // eslint-disable-next-line no-undef
                resolve(google);
            }
        };
        waitForGoogleMaps();
    });
}

export default whenGoogleIsLoaded;
