import React from "react";

export const getInlineSVGsColorized = (fillColor, bgColor) => {
    const svgParams = {
        xmlns: "http://www.w3.org/2000/svg",
        className: "inlineSvg",
        fill: fillColor
    };

    const ResizeExpandBlock = () => (
        <svg {...svgParams} viewBox="0 0 5 5">
            <rect x="0" y="0" width="5" height="5" />
        </svg>
    );

    const ResizeShrinkBlock = () => (
        <svg {...svgParams} viewBox="0 0 5 5">
            <rect x="1" y="1" width="3" height="3" />
        </svg>
    );

    const ScaleDownHorizontal = () => (
        <svg {...svgParams} viewBox="0 0 9 6">
            <polygon points="0,0 0,2 2,3 0,4 0,6 4,3 0,0" />
            <polygon points="9,0 9,2 7,3 9,4 9,6 5,3 9,0" />
        </svg>
    );

    const ScaleDownVertical = () => (
        <svg {...svgParams} viewBox="0 0 6 9">
            <polygon points="0,0 2,0 3,2 4,0 6,0 3,4 0,0" />
            <polygon points="0,9 2,9 3,7 4,9 6,9 3,5 0,9" />
        </svg>
    );
    const ScaleUpVertical = () => (
        <svg {...svgParams} viewBox="0 0 6 9">
            <polygon points="0,4 2,4 3,2 4,4 6,4 3,0 0,4" />
            <polygon points="0,5 2,5 3,7 4,5 6,5 3,9 0,5" />
        </svg>
    );
    const ScaleUpHorizontal = () => (
        <svg {...svgParams} viewBox="0 0 9 6">
            <polygon points="4,0 4,2 2,3 4,4 4,6 0,3 4,0" />
            <polygon points="5,0 5,2 7,3 5,4 5,6 9,3 5,0" />
        </svg>
    );

    const RotateRight = () => (
        <svg {...svgParams} viewBox="0 0 12 12">
            <g>
                <polygon points="3,10 6,8 6,12" />
                <path
                    d="M 6 11
               A 1 1 0 0 0 6 1
               M 6 3
               A 1 1 0 0 1 6 9
               "
                />
            </g>
        </svg>
    );
    const RotateLeft = () => (
        <svg {...svgParams} viewBox="0 0 12 12">
            <g>
                <polygon points="9,10 6,8 6,12" />
                <path
                    d="M 6 3
             A 1 1 0 0 0 6 9
             M 6 11
             A 1 1 0 0 1 6 1
             "
                />
            </g>
        </svg>
    );

    /**
     *      xxxxx
     *   x  xxxxx
     *  xxx xxxxx
     *   x  xxxxx
     *      xxxxx
     */
    const RectanglePlus = () => (
        <svg {...svgParams} viewBox="0 0 12 12">
            <g>
                <rect x="2" y="2" width="8" height="8" />
            </g>
        </svg>
    );

    /**
     *       xxxxx
     *    x  x   x
     *   xxx x   x
     *    x  x   x
     *       xxxxx
     */
    return {
        ResizeExpandBlock,
        ResizeShrinkBlock,
        ScaleDownHorizontal,
        ScaleDownVertical,
        ScaleUpVertical,
        ScaleUpHorizontal,
        RotateRight,
        RotateLeft,
        RectanglePlus
    };
};
