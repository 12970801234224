export const defaultSelectionColor = "#2e55a0";
export const defaultErrorColor = "red";

export const defaultPolySettings = {
    geodesic: true,
    strokeColor: "#F58220",
    fillColor: "#FFF",
    fillOpacity: "0.625",
    strokeOpacity: 1.0,
    strokeWeight: 2,
    draggable: false,
    clickable: false
};

export const defaultInteractivePolySettings = {
    ...defaultPolySettings,
    draggable: true,
    clickable: true
};

export const defaultArcSettings = {
    ...defaultInteractivePolySettings
};

export const defaultRectangleSettings = {
    ...defaultInteractivePolySettings
};

export const defaultStep1RectangleSettings = {
    strokeColor: "#F58220",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#F58220",
    fillOpacity: 0.35,
    clickable: false
};

export const defaultGridSettings = {
    ...defaultPolySettings,
    strokeOpacity: 0.25,
    strokeColor: "#31599d"
};

export const defaultAxisSettings = {
    ...defaultPolySettings,
    strokeOpacity: 0.7,
    strokeWeight: 2
};

export default {
    defaultPolySettings,
    defaultInteractivePolySettings,
    defaultArcSettings,
    defaultRectangleSettings,
    defaultStep1RectangleSettings,
    defaultGridSettings,
    defaultAxisSettings
};
