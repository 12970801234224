import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, withRouter } from "react-router-dom";

import { paths } from "IceRinkApp/route_consts";
import "./index.scss";

/**
 * _________________________________________
 *    Fortschritt Speichern   Kontakt aufnehmen   Folgen Sie uns
 *           [sa]              [ph]      [ma]     [li] [fb] [in]
 */
const FooterIntercom = ({ saveState, history }) => {
    const { t } = useTranslation();
    const location = useLocation();

    const [showSave, setShowSave] = useState(false);

    useEffect(() => {
        setShowSave(location.pathname !== paths.root && location.pathname !== paths.step1);
    }, [history.location.key, location.pathname]);

    return (
        <div id="footer">
            <React.Fragment>
                <div className="blockContainer">
                    <div className="leftBlock">
                        <h4>{t("footer.titles.contact")}</h4>
                        <ul>
                            <li>{t("footer.contact.street")}</li>
                            <li>{t("footer.contact.postCode")}</li>
                            <li>{t("footer.contact.country")}</li>
                            <li>
                                <a href="tel:+390472765512">{t("footer.contact.phone")}</a>
                            </li>
                            <li>
                                <a href="fax:+390472766749">{t("footer.contact.fax")}</a>
                            </li>
                            <li>
                                <a href="mailto:realice@realice.info">E-Mail: realice@realice.info</a>
                            </li>
                            <li>
                                <a
                                    href="https://www.realice.info"
                                    target={"blank"}
                                    rel={"noopener noreferrer"}
                                >
                                  www.realice.info
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="centerBlock">
                        <h4>{t("footer.titles.service")}</h4>
                        <ul>
                            <li>
                                <a href={t("footer.service.urls.contact")}>
                                    {t("footer.service.contact")}
                                </a>
                            </li>
                            <li>
                                <a href={t("footer.service.urls.aboutUs")}>
                                    {t("footer.service.aboutUs")}
                                </a>
                            </li>
                            <li>
                                <a href={t("footer.service.urls.services")}>
                                    {t("footer.service.services")}
                                </a>
                            </li>
                            <li>
                                <a href={t("footer.service.urls.projects")}>
                                    {t("footer.service.projects")}
                                </a>
                            </li>
                            <li>
                                <a href={t("footer.service.urls.whereToFind")}>
                                    {t("footer.service.whereToFind")}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="rightBlock">
                        {showSave && (
                            <React.Fragment>
                                <h4>{t("footer.titles.saveProgress")}</h4>
                                <div id="saveButton" onClick={saveState} className="iconRow center">
                                    <div className="icon-wrapper">
                                        <i className="ri-save-3-fill"></i>
                                    </div>
                                    <p>{t("actions.save.title")}</p>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </React.Fragment>
            <div className={"legal-footer"}>
                <ul>
                    <li>© 2020 Intercom Dr. Leitner GmbH</li>
                    <li>MwSt-Nr. 00534750211</li>
                    <li>
                        <a href={t("footer.legal.imprint.url")}>
                            {t("footer.legal.imprint.title")}
                        </a>
                    </li>
                    <li>
                        <a href={t("footer.legal.cookie.url")}>{t("footer.legal.cookie.title")}</a>
                    </li>
                    <li>
                        <a href={t("footer.legal.privacy.url")}>
                            {t("footer.legal.privacy.title")}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};
export default withRouter(FooterIntercom);
