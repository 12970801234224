/**
 * @typedef {import("../types/customTypes").LatLngNum} LatLngNum
 */
import turfDestination from "@turf/destination";
import turfLineArc from "@turf/line-arc";
import {
    arrToObj,
    latLngFnToNum,
    latWest,
    lngLatArrToObj,
    lngNorth,
    objToArr,
    objToArrLngLat
} from "../../../../common/Utils";
import { tlog } from "../debugging";
import { INNER_CLOCKWISE, RECTANGLE_ROUNDING_OFFSET } from "./RectangleConsts";
/**
 * @typedef {Object} RectMeta
 * @property {LatLngNum} outerOriginNum
 * @property {LatLngNum?} innerOriginNum
 * @property {number?} borderThickness
 */

/**
 * Rectangle init
 *
 * c-----x     +--lng+
 * |     |     |
 * y-----+     lat-
 *
 * @param {LatLngNum} origin Top-Left corner of rectangle
 * @param {number} rectangleDistCmX Edge-Length in X direction, relative to initial rotation
 * @param {number} rectangleDistCmY Edge-Length in Y direction, relative to initial rotation
 * @param {any} withHole - ...
 * @param {any} useRoundedCorners - ...
 * @param {any} roundingOffsetInCm - ...
 * @returns {{meta: RectMeta,paths:Array.<Array.<LatLngNum>>}} Either [OuterRectCoords] or [OuterRectCoords, InnerRectCoords]
 */
export const calculateRectangle = ({
    origin,
    rectangleDistCmX,
    rectangleDistCmY,
    withHole,
    useRoundedCorners,
    roundingOffsetInCm,
    borderThickness
}) => {
    const originNum = latLngFnToNum(origin);
    let meta = {
        outerOriginNum: originNum
    };
    /**
     * c-----x     +--lng+
     * |     |     |
     * y-----+     lat-
     */
    // prettier-ignore
    const outerRectCoords = calcRectangleCoordinates(originNum, rectangleDistCmX, rectangleDistCmY);

    let result = [outerRectCoords];
    if (withHole) {
        const inner = calculateInnerRectangle(
            originNum,
            rectangleDistCmX,
            rectangleDistCmY,
            borderThickness
        );
        meta = { ...meta, ...inner.meta };
        result.push(inner.path);
    }
    if (useRoundedCorners) {
        if (withHole) {
            result = [
                calculateCurvedRect(result[0], roundingOffsetInCm, false),
                calculateCurvedRect(result[1], roundingOffsetInCm, true)
            ];
        } else {
            result = [calculateCurvedRect(result[0], roundingOffsetInCm, false)];
        }
    }

    return {
        meta,
        paths: result
    };
};

export default calculateRectangle;

/**
 * The order of polygons is important, otherwise the hole isn't used as a hole but as an overlaying poly.
 * @param {google.maps.LatLngLiteral} originNum
 * @param {number} rectangleDistCmX
 * @param {number} rectangleDistCmY
 * @returns {{path: Array.<google.maps.LatLngLiteral>, meta: Object}} Coordinate-Array of inner rectangle
 */
function calculateInnerRectangle(originNum, rectangleDistCmX, rectangleDistCmY, borderThickness) {
    const diagonalDist = Math.sqrt(Math.pow(borderThickness, 2) + Math.pow(borderThickness, 2));
    const innerOriginNum = arrToObj(
        turfDestination(objToArrLngLat(originNum), diagonalDist, 135, {
            units: "centimeters"
        }).geometry.coordinates.reverse()
    );
    let innerPath = calcRectangleCoordinates(
        innerOriginNum,
        rectangleDistCmX - borderThickness * 2,
        rectangleDistCmY - borderThickness * 2
    );

    // re-arrange indices to keep origin at 0 but reverse the order to enable 'hole-punching'
    if (INNER_CLOCKWISE) {
        // => outer_counter_clockwise
        innerPath = [innerPath[0], innerPath[3], innerPath[2], innerPath[1]];
    } else {
        throw Error("not yet implemented");
    }
    return {
        meta: {
            innerOriginNum,
            borderThickness: borderThickness
        },
        path: innerPath
    };
}

/**
 *
 * @param {google.maps.LatLng[]} rectangleCoordinates exactly four LatLng-obj coordinates
 * @param {number} offsetCm - 250/4 = 62.5
 * @param {boolean} isInnerRectangle true for inner, false for outer rectangle, defines the idx order
 */
function calculateCurvedRect(rectangleCoordinates, offsetCm, isInnerRectangle) {
    if (offsetCm !== RECTANGLE_ROUNDING_OFFSET) {
        throw Error("Not yet implemented: Variable rounding-offsets");
    }

    let referenceCoordinates = [...rectangleCoordinates];

    const curvedRectangleCoordinates = [];

    const corner = (index, direction, startAngle, endAngle, isInnerRectangle) => {
        // Convert the google LatLng to simple [lng,lat] array
        const cornerCoord = objToArrLngLat(referenceCoordinates[index]);
        /**
         * <pre>
         * cornerCoord
         *    |      offsetCm
         *    |       \/
         *    └----->+--|-------+
         * offsetCm->|\ .       |
         *           | \.       |
         *           -..d <------ dest
         *           |          |
         *           |          |
         *           |          |
         *           |          |
         *           +----------+
         * </pre>
         * diagonalDistanceInCm = (via Pythagorean Theorem, "d=s * sqrt(2)") d=offsetCm * sqrt(2)
         * @type {number}
         */
        const diagonalDistanceInCm = offsetCm * Math.sqrt(2);
        // Calculate the center of the circle
        const dest = turfDestination(cornerCoord, diagonalDistanceInCm, direction, {
            units: "centimeters"
        });
        // Calculate the coordinates representing the arc from * to *
        const arcCoords = turfLineArc(dest, offsetCm, startAngle, endAngle, {
            steps: Math.PI * 10, // => 10 steps
            units: "centimeters"
        }).geometry.coordinates.map(c => lngLatArrToObj(c));

        // visualize creation
        // setTimeout(() => {
        //     tempLine([ ...referenceCoordinates, referenceCoordinates[0]], "#FF5")
        //     tempLine([ referenceCoordinates[index], ...arcCoords, referenceCoordinates[index]])
        // }, 250)

        tlog(
            "DestCalc, cornerCoords, dest, arcCoords",
            cornerCoord,
            dest.geometry.coordinates,
            arcCoords
        );
        // add the new coordinates to the result array
        if (isInnerRectangle) {
            curvedRectangleCoordinates.push(...arcCoords);
        } else {
            curvedRectangleCoordinates.push(...arcCoords.reverse());
        }
    };

    // top-left, go diagonal(-45°) ↘️ and then from that origin
    // draw an arc north(90°) to west(180°)
    if (isInnerRectangle) {
        // [TL,TR,BR,BL]
        // 0--1
        // |  |
        // 3--2
        corner(0, 135, 270, 360, true); // top-left,
        corner(1, -135, 0, 90, true); // top-right,
        corner(2, -45, 90, 180, true); // bottom-right,
        corner(3, 45, 180, 270, true); // bottom-left,
    } else {
        // [TL,BL,BR,TR]
        // 0--3
        // |  |
        // 1--2
        corner(0, 135, 270, 360, false); // bottom-right, 270°=↖️
        corner(1, 45, 180, 270, false); // bottom-left,   45°=↗️
        corner(2, -45, 90, 180, false); // top-left,  (-45°) ↘️
        corner(3, -135, 0, 90, false); // top-right,     -135°=↙️
    }

    return curvedRectangleCoordinates;
}

export function calcRectangleCoordinates(originLatLngNum, rectangleDistCmX, rectangleDistCmY) {
    /** @param {number} dist */
    const latCalc = dist => latWest(objToArr(originLatLngNum), dist);

    /** @param {number} dist */
    const lngCalc = dist => lngNorth(objToArr(originLatLngNum), dist);

    return [
        // top-left_____(c)
        originLatLngNum,
        // bottom-left__(y)
        { lat: latCalc(-rectangleDistCmY), lng: originLatLngNum.lng },
        // bottom-right_(+)
        { lat: latCalc(-rectangleDistCmY), lng: lngCalc(rectangleDistCmX) },
        // top-right____(x)
        { lat: originLatLngNum.lat, lng: lngCalc(rectangleDistCmX) }
    ];
}
