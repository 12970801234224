import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { tlog } from "scenes/2_setup_designer/designer/elements/debugging";
import { ResultsScene } from "../../../scenes/4_results";

export const ResultsRoute = ({ stateTracked, setStateTracked, saveState, autoSaveState }) => {
    useEffect(() => {
        autoSaveState();
    }, []);
    /**
     * When the current state is saved and a new key is created that way
     * attach it to the state
     * @param {string} newKey
     */
    const saveKeyChanged = newKey => {
        if (newKey !== stateTracked.saveKey) {
            setStateTracked(prevState => {
                tlog("saveKeyChanged", prevState.saveKey, stateTracked.saveKey, newKey);
                return {
                    ...prevState,
                    saveKey: newKey
                };
            });
        }
    };

    return (
        <ResultsScene
            state={stateTracked}
            saveKeyChanged={saveKeyChanged}
            saveStateFn={saveState}
        />
    );
};

ResultsRoute.propTypes = {
    stateTracked: PropTypes.object,
    setStateTracked: PropTypes.func
};

export default ResultsRoute;
