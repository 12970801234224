import React from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";

export const LanguageSwitcher = () => {
    const { t, i18n } = useTranslation();

    const setLang = lang => {
        i18n.changeLanguage(lang);
    };

    return (
        <div id="languageSelection">
            <ul>
                {["de", "en", "it"].map(l2 => (
                    <li
                        key={l2}
                        id={l2}
                        onClick={() => {
                            setLang(l2);
                        }}
                        className={i18n.language === l2 ? "active" : ""}
                    >
                        {t("app.languages." + l2)}
                    </li>
                ))}
            </ul>
        </div>
    );
};
export default LanguageSwitcher;
