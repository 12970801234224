import turfDistance from "@turf/distance";
import { defaultGridSizeInBlocks } from "Configuration";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { round1 } from "scenes/common/Utils";
import { defaultStartPosition, USING_GOOGLE_MAPS_API_KEY } from "../../../Configuration";
import { latLngFnToNum, objToArrLngLat } from "../../common/Utils";
import DesignerMap from "./components/DesignerMap";
import ElementPanel from "./components/ElementPanel";
import GlobalOperationsPanel from "./components/GlobalOperationsPanel";
import SelectionOperationsPanel from "./components/SelectionOperationsPanel";
import { dismissKeyMissingPopup } from "./dismissKeyMissingPopup";
import { polyCircumfence } from "./elements/common/logic/polyCircumfence";
import { dbg, tlog } from "./elements/debugging";
// import '../gmaps_debug.css';
import "./index.scss";
import "./elements/PopupClass.scss";
import { MapSetup } from "./logic/MapSetup";
import { OriginProvider } from "./logic/OriginProvider";
import { OverridableMethod } from "./OverridableMethod";
// eslint-disable-next-line no-unused-vars
import SaveStateManager from "./state/SaveStateManager";
import { RECTANGLE_TYPE } from "./elements/rectangle/RectangleConsts";
import { LS_STATE_02_KEY } from "./DesignerConstants";

/**
 * Calculates the area of each element and returns the sum of those values.
 * @param {array.<google.maps.Polygon>} elements
 * @returns sum of all element-areas in m²
 */
const totalAreaFromGooglePolys = elements => {
    let totalArea = 0;
    for (const element of elements) {
        const elArea =
            element.creationParams.type === RECTANGLE_TYPE
                ? element.parent.calculateArea() // RectangleClass
                : element.creationParams.calculateArea(); // ArcDrawing.creationParams
        totalArea += elArea;
    }
    return totalArea;
};

const isEqual = (a, b) => a.lat === b.lat && a.lng === b.lng;

/**
 * Google Maps based ice rink designer module
 * @param {*} param0 {lat,lng} where the Designer shall init its map
 * IceRinkApp>DesignerScene>Designer
 */
export function Designer({
    stateTracked,
    step1Changed,
    step2Change,
    navMethodOverride,
    discardAllElementsOnPageExitOMOverride,
    commonChangedCB,
    updateAllFieldsConnected,
    updateHasOverlappingElements,
    requestDesignerUpdateOverride,
    setStateTracked,
    saveState
}) {
    const { t } = useTranslation();

    const [totalArea, setTotalArea] = useState(-1);
    // shared, but mostly ignored zoom-level
    const common = stateTracked.common;

    // todo enforce zoom level 19, but only on first call to this
    const didAlreadyChangeZoomLevelStatic = useRef(false);

    // center from step1
    const latLngCenter = stateTracked.step1;

    // origin that is initialized w/ step1.center once
    const origin = useRef({
        lat: parseFloat(latLngCenter.lat),
        lng: parseFloat(latLngCenter.lng)
    });

    /**
     * The logic(=R2GM)
     * First the logic is set to undefined
     * then in the first 'designer'-draw-call, in which its state is
     * set to the real logic, it can't yet be used.
     * After that the 'logic' variable is correctly bound and will
     * behave as expected
     */
    const [logic, setLogic] = useState(undefined);
    if (logic !== undefined) {
        logic.updateCenter(origin.current);
    }

    const stateManagement = logic ? logic.getASMgmt() : undefined;
    const step2LocalStorageMgmt = stateManagement ? stateManagement.polyconCreator : undefined;

    const updateOvFn = new OverridableMethod();

    if (step2LocalStorageMgmt) {
        navMethodOverride(() => {
            step2LocalStorageMgmt.writeStateToLocalStorage();
        });

        // Override the page-method for clearing the state on navigation back to step-01
        discardAllElementsOnPageExitOMOverride(
            // clear elements and local-storage data of step2
            () => step2LocalStorageMgmt.clearState02LocalStorageAndElements()
        );

        // ---
        const lsState = localStorage.getItem(LS_STATE_02_KEY);
        if (lsState && lsState !== "{}") {
            const lsStateObj = JSON.parse(lsState);
            const lsStateCenter = lsStateObj.center;
            const hasSavedElements = lsStateObj.elements.length > 0;
            const newLatLngCenter = latLngCenter;

            if (hasSavedElements) {
                handleSavedElementsCases(newLatLngCenter, lsStateCenter, isEqual);
            } else {
                tlog("5️⃣ No saved elements = no new center issues");
            }
        }

        // ---

        //Todo: currently creates infinite loop: saveMgmt.loadFromLocalStorage();
        updateOvFn.overrideMethod(() => {
            // This triggers the state reloads
            const oldState = step2LocalStorageMgmt.getStateAsJSON();
            const newState = JSON.stringify(stateTracked.step2.stateObj);
            if (oldState === newState) {
                return;
            } else {
                const newStateObj = JSON.parse(oldState);
                tlog("updateOvFn.overrideMethod -- new State - applying");
                step2Change(newStateObj); // polyconCreator
            }
        });

        requestDesignerUpdateOverride(() => {
            tlog("%c Requesting designer update", "background:teal");

            // might be redundant
            step2Change(JSON.parse(step2LocalStorageMgmt.getStateAsJSON())); // polygonCreator

            // calculate checks
            const allElementsAreConnected = stateManagement.hasAllElementsConnected();
            updateAllFieldsConnected(allElementsAreConnected);
            const hasOverlappingElements = stateManagement.hasOverlappingElements();
            updateHasOverlappingElements(hasOverlappingElements);

            const sceneMightRedirectToStep3 = !hasOverlappingElements;
            if (sceneMightRedirectToStep3) {
                // might redirect to step3, so we have to calculate area and circumfence

                const totalArea = round1(totalAreaFromGooglePolys(stateManagement.elements));

                // prettier-ignore
                const polyCircumfenceResult = polyCircumfence(stateManagement.elements);
                const { withBuffer, withoutBuffer } = polyCircumfenceResult;

                setStateTracked(prevState => ({
                    ...prevState,
                    step2: {
                        ...prevState.step2,
                        stateObj: {
                            ...prevState.step2.stateObj,
                            totalCircumfence: withoutBuffer,
                            totalCircumfenceWithBuffer: withBuffer
                        }
                    },
                    totalArea: totalArea
                }));
            }
        });

        tlog("%c Overwriting handleDataChange impls", "color: red");
        logic.updateHandleDataChangeImpl(updateOvFn.invokeMethod);
        stateManagement.updateHandleDataChangeImpl(updateOvFn.invokeMethod);
    }
    const mapRef = useRef(null);

    useEffect(() => {
        if (logic !== undefined) {
            const totalArea = round1(totalAreaFromGooglePolys(stateManagement.elements));
            setTotalArea(totalArea);
        }

        if (!didAlreadyChangeZoomLevelStatic.current) {
            // force zoom to 19
            commonChangedCB({
                ...common,
                zoom: 19
            });
            stateTracked.common.zoom = 19;
            didAlreadyChangeZoomLevelStatic.current = true;
        }
        const zoom = stateTracked.common.zoom; // Use 'common.zoom' if you want to use the zoom from the previous step
        if (logic === undefined) {
            /**
             * The original intend of this method is to bind the "logic" implementation
             * after we have waited for both the "map" element and the "google" variable
             * Main issue here being, that I have not yet figured out how to do that
             * without leaving react's magical state wheel.
             * e.g. in this scope here mapRef.current is bound, when "whenGoogleIsLoaded"
             * eventually invokes its callback mapRef.current is null.
             *
             */

            /**
             * First try: just imply that everything exists and we don't need to go async here
             */
            /**
             * @type {google}
             */
            // eslint-disable-next-line no-undef
            const googleGlobal = google;
            {
                !USING_GOOGLE_MAPS_API_KEY && dismissKeyMissingPopup();

                // Set logic for next call; logic is not yet bound
                const mapEl = mapRef.current;
                const designerOriginProvider = new OriginProvider(latLngFnToNum(origin.current));
                // Initialize the map div
                const logicImpl = new MapSetup(designerOriginProvider, zoom, googleGlobal).initMap(
                    mapEl,
                    function () {
                        // This would be called when the map is updated, but is overriden above via
                        // logic.updateHandleDataChangeImpl & stateManagement.updateHandleDataChangeImpl
                        updateOvFn.invokeMethod();
                    }
                );
                const map = logicImpl.elBench.map;
                map.addListener("zoom_changed", () => {
                    tlog("zoom_changed", map.getZoom());
                    commonChangedCB({
                        ...common,
                        zoom: map.getZoom()
                    });
                });
                setLogic(logicImpl);
            }
        }
    }, [mapRef, common, commonChangedCB, origin, logic, updateOvFn, stateTracked.common.zoom]);

    return (
        <div className="App" id="designer" data-testid="designer">
            <DesignerMap asmgmt={stateManagement} mapRef={mapRef} />

            {stateManagement === undefined ? (
                <div>{t("designer.loading.logic")}</div>
            ) : (
                <div
                    id="PlaceToHideBeforeAttachingToMap"
                    style={{
                        position: "fixed",
                        visibility: "hidden",
                        top: 0,
                        left: 0
                    }}
                >
                    <SelectionOperationsPanel
                        asmgmt={stateManagement}
                        logic={logic}
                        saveState={saveState}
                        totalArea={totalArea}
                    />
                    <ElementPanel logic={logic} />
                    <GlobalOperationsPanel asmgmt={stateManagement} />
                </div>
            )}
        </div> // #app
    );

    /**
     * Here we attempt to duck-type the current state and what state we shall traverse to
     * @param {*} step1Center
     * @param {*} localStorageCenter
     * @param {*} isEqual
     */
    function handleSavedElementsCases(step1Center, localStorageCenter, isEqual) {
        const logDefaultEq = () => {
            const step1CenterIsDefault = isEqual(step1Center, defaultStartPosition);
            const localStorageCenterIsDefault = isEqual(localStorageCenter, defaultStartPosition);
            const step2Center = stateTracked.step2.stateObj.center;
            const step2CenterIsDefault = isEqual(step2Center, defaultStartPosition);
            dbg(() =>
                console.log(
                    "center default: step1, step2, ls",
                    step1CenterIsDefault,
                    step2CenterIsDefault,
                    localStorageCenterIsDefault
                )
            );
            // if (step2CenterIsDefault) {
            //     const newCenter = !step1CenterIsDefault
            //         ? step1Center
            //         : !localStorageCenterIsDefault
            //         ? localStorageCenter
            //         : null;
            //     if (newCenter) {
            //         setStateTracked(prevState => ({
            //             ...prevState,
            //             step2: {
            //                 ...prevState.step2,
            //                 center: newCenter
            //             }
            //         }));
            //     }
            // }
        };

        /**
         * @type {SaveStateManager}
         */
        const saveStateManager = step2LocalStorageMgmt;

        if (isEqual(step1Center, localStorageCenter)) {
            tlog(
                "0️⃣ LocalStorage.center and current.center equal, coming from step 2/3 w/ no changes => reloading localStorage"
            );
            tlog("0️⃣ Or handleChange event while staying on the page, manipulating objects");
            logDefaultEq();
            // Todo:
            // Problem ist, dass die karten center position nicht gesetzt wird und das step2.center auf default bleibt.
            // d.h. du muss suchen, wo die step2 auf karte oder karte auf step2 gesetzt wird und dann eines von beiden
            // hier setzten, damit am Ende beide gesetzt sind.

            saveStateManager.attemptInitialLoad();
        } else {
            tlog("LocalStorage.center and current.center are not equal");
            if (isEqual(step1Center, defaultStartPosition)) {
                tlog("1️⃣ current.center equals default position, assuming direct access of step2");
            } else {
                tlog("current.center doesn't equal default position, probably coming from step 1");
                // calculate distance between origin and new origin
                const distance = turfDistance(
                    objToArrLngLat(step1Center),
                    objToArrLngLat(localStorageCenter),
                    { units: "centimeters" }
                );
                const gridSizeInCm = defaultGridSizeInBlocks * 250;
                if (distance > gridSizeInCm) {
                    dbg(() =>
                        console.log(
                            "2️⃣ D-LS: Using new location, not loading from localStorage, clearing localStorage"
                        )
                    );
                    saveStateManager.clearState02LocalStorageAndElements();
                    // } else {
                    //     tlog(
                    //         "3️⃣ D-LS: Confirmed old location, loading from localStorage, moving to old location, overwriting step1"
                    //     );
                    //     const useOldLocation = true;
                    //     step1Changed(localStorageCenter);
                    //     step2LocalStorageMgmt.attemptInitialLoad(useOldLocation);
                    // }
                } else {
                    tlog(
                        "4️⃣ D-LS: Distance not very high, loading from localStorage, using new location"
                    );
                    step2LocalStorageMgmt.attemptInitialLoad(undefined, step1Center);
                }
            }
        }
    }
}

Designer.propTypes = {
    commonChangedCB: PropTypes.func,
    stateTracked: PropTypes.object,
    step2Change: PropTypes.func,
    navMethodOverride: PropTypes.func,
    step1Changed: PropTypes.func,
    updateAllFieldsConnected: PropTypes.func,
    updateHasOverlappingElements: PropTypes.func,
    requestDesignerUpdateOverride: PropTypes.func,
    setStateTracked: PropTypes.func
};

export default Designer;
