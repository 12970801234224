import { waitForElement } from "../../common/Utils";
/**
 * hide google maps note (remove for production / as soon as api key is available)
 */
export function dismissKeyMissingPopup() {
    waitForElement(
        el => el.click(),
        () => document.getElementsByClassName("dismissButton")[0]
    );
}
