import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import "./index.scss";

const BulletStepText = ({
    textStartX,
    textAlignmentOffset,
    circleRadius,
    children: textContent,
    textRef
}) => {
    const textStart = textStartX;
    const textStartY = circleRadius + textAlignmentOffset;
    return (
        <g>
            <text x={textStart} y={textStartY} fill="#333" className="lText" ref={textRef}>
                {textContent}
            </text>
        </g>
    );
};
BulletStepText.propTypes = {
    children: PropTypes.any,
    step: PropTypes.number,
    circleRadius: PropTypes.number,
    textStartX: PropTypes.number,
    bgColor: PropTypes.string,
    textAlignmentOffset: PropTypes.any,
    h: PropTypes.any,
    textRef: PropTypes.any
};

const Bullet = ({
    textAlignmentOffset,
    circleRadius,
    bgColor,
    number,
    offsetX,
    cY,
    lightColor,
    active
}) => {
    return (
        <g
            onClick={() => {
                console.log(number);
            }}
        >
            <circle
                cx={circleRadius + offsetX}
                cy={cY ? cY : circleRadius}
                r={circleRadius}
                stroke={lightColor}
                strokeWidth={active ? "2" : "1"}
                fill={bgColor}
            />
            <text
                dx={active ? -10 : -textAlignmentOffset}
                dy={active ? 10 : textAlignmentOffset}
                className={"cText " + (active ? "active" : "")}
                x={circleRadius + offsetX}
                y={cY ? cY : circleRadius}
                fill="#333"
            >
                {number}
            </text>
        </g>
    );
};
Bullet.propTypes = {
    number: PropTypes.number,
    offsetX: PropTypes.number,
    cY: PropTypes.number,
    circleRadius: PropTypes.number,
    lightColor: PropTypes.string,
    bgColor: PropTypes.string,
    active: PropTypes.bool,
    textAlignmentOffset: PropTypes.any
};

const calculateCircleOffsetX = (currentStep, totalSteps, svgWidth, ACTIVE_STEP, circleDiameter) => {
    const rightBorder = svgWidth - 2;
    // e.g. ACTIVE_STEP = 3
    if (currentStep > ACTIVE_STEP) {
        // 4
        let fromRight = currentStep === totalSteps ? 1 : (totalSteps - currentStep) * 2 + 1;
        return rightBorder - circleDiameter * fromRight;
    } else {
        // 1,2,3
        const circlePlusMargin = circleDiameter * 2; // [circle][circle_sizedMargin]
        // 1 =>    0
        // 2 => 1x circlePlusMargin
        // 3 => 2x activeCirclePlusMargin
        return (currentStep - 1) * circlePlusMargin;
    }
};

/**
 * @typedef {object} BulletStepsCustomParams
 * @property {number} width will be used +2px
 * @property {number} activeCircleHeight size of current step (e.g. 1.25 of usual circle)
 * @property {number} circleScale
 * @property {number} step 1,2,3 or 4
 * @property {string} translatedText Text of current step, should be result of t($someKey)
 */

/**
 *
 * @param {BulletStepsCustomParams} param0
 */
const BulletStepsCustom = ({ width, activeCircleHeight, circleScale, step, translatedText }) => {
    if (!(width && activeCircleHeight && circleScale && step)) {
        throw Error(width + " " + activeCircleHeight + " " + circleScale + " " + step);
    }
    const bulletTextElement = useRef(null);
    const [textWidth, setTextWidth] = useState(0);

    const totalSteps = 4;
    const ACTIVE_STEP = step;

    const scale = circleScale;

    class CircleProps {
        constructor(height, scale) {
            this.height = height;
            this.radius = (this.height / 2) * scale;
            this.diameter = this.radius * 2;
        }
    }

    const activeCircleProps = new CircleProps(activeCircleHeight, circleScale);
    const circleProps = new CircleProps(activeCircleHeight * 0.8, circleScale);

    // text offset
    const lightColor = "#666";

    /**
     * ┌───x──x──────┐
     * │x        x   │
     * x          x  │
     * x          x  │
     * │x        x   │
     * └───x──x──────┘
     * ┌───────────────┐   ┤offsetTop
     * │     x  x      │
     * │  x        x   │
     * │ x          x  │
     * │ x          x  │
     * │  x        x   │
     * │     x  x      │
     * └───────────────┘   ┤offsetBottom
     *
     * ┴
     * OffsetLeft
     *
     * offsets for rendering whole circles
     */
    const offsetRight = 2;
    const offsetBottom = 4;
    const viewBox = {
        offsetTop: circleProps.radius - activeCircleProps.radius - 2, // how much to go 'off-canvas' in -y direction
        offsetLeft: -1, // how much to go 'off-canvas' in  -x direction
        width: width + offsetRight,
        height: activeCircleProps.diameter + offsetBottom,
        toString: () => {
            const vb = viewBox;
            return `${vb.offsetLeft} ${vb.offsetTop} ${vb.width} ${vb.height}`;
        }
    };

    const subElProps = {
        textAlignmentOffset: 5 * scale, // 5*1.5 = 7.5
        circleRadius: circleProps.radius,
        bgColor: "transparent"
    };
    let textProps = {
        ...subElProps,
        svgHeight: viewBox.height
    };
    const bulletProps = {
        ...subElProps,
        lightColor,
        offsetY: 0
    };

    let bullets = [];
    let lineParts = [];

    useEffect(() => {
        if (bulletTextElement && bulletTextElement.current) {
            setTextWidth(Math.round(bulletTextElement.current.getComputedTextLength()));
        }
    }, [bulletTextElement]);

    // 1,2,3,4
    for (var currentStep = 1; currentStep <= totalSteps; currentStep++) {
        let offsetX = calculateCircleOffsetX(
            currentStep,
            totalSteps,
            viewBox.width,
            ACTIVE_STEP,
            circleProps.diameter,
            activeCircleProps.diameter
        );

        let currentBulletProps = {
            ...bulletProps,
            key: currentStep,
            number: currentStep,
            parentWidth: viewBox.width,
            offsetX
        };
        if (currentStep === ACTIVE_STEP) {
            const cr = currentBulletProps.circleRadius;
            bullets.push(
                <Bullet {...currentBulletProps} cY={cr} circleRadius={cr * 1.25} active={true} />
            );
        } else {
            bullets.push(<Bullet {...currentBulletProps} />);
        }

        // line logic
        {
            let offsetXNext = calculateCircleOffsetX(
                currentStep + 1,
                totalSteps,
                viewBox.width,
                ACTIVE_STEP,
                circleProps.diameter,
                activeCircleProps.diameter
            );
            if (currentStep === ACTIVE_STEP) {
                const circleStart = offsetX;
                const nextCircleStart = offsetXNext;
                const lineStart1 = circleStart + activeCircleProps.diameter;
                const lineEnd1 = lineStart1 + 5;
                const lineStart2 = lineEnd1 + textWidth + 10;
                const lineEnd2 = nextCircleStart;
                const centerOfCirclesY = circleProps.radius;
                lineParts.push(
                    <line
                        key={lineStart1}
                        x1={lineStart1}
                        y1={centerOfCirclesY}
                        x2={lineEnd1}
                        y2={centerOfCirclesY}
                        stroke={lightColor}
                    />
                );
                lineParts.push(
                    <line
                        key={lineStart2}
                        x1={lineStart2}
                        y1={centerOfCirclesY}
                        x2={lineEnd2}
                        y2={centerOfCirclesY}
                        stroke={lightColor}
                    />
                );
            } else if (currentStep !== totalSteps) {
                const circleStart = offsetX;
                const nextCircleStart = offsetXNext;
                const lineStart = circleStart + circleProps.diameter;
                const lineEnd = nextCircleStart; //lineStart+circleDiameter;
                const centerOfCirclesY = circleProps.radius;
                lineParts.push(
                    <line
                        key={lineStart}
                        x1={lineStart}
                        y1={centerOfCirclesY}
                        x2={lineEnd}
                        y2={centerOfCirclesY}
                        stroke={lightColor}
                    />
                );
            }
        }
    }

    const activeStepOffsetX = calculateCircleOffsetX(
        ACTIVE_STEP,
        totalSteps,
        viewBox.height,
        ACTIVE_STEP,
        circleProps.diameter
    );
    const activeStepRightSideX = activeStepOffsetX + activeCircleProps.diameter;
    textProps.textStartX = activeStepRightSideX + 8;

    return (
        <div className="BulletSteps">
            <svg width={viewBox.width} height={viewBox.height} viewBox={viewBox.toString()}>
                <style>{`.cText,.lText {font: 300 ${
                    circleProps.radius
                }px Arial;} .cText.active{font-size: ${circleProps.radius * (4 / 3)}px;}`}</style>

                {/* <line x1={circleRadius} y1={circleRadius} x2={svgWidth - circleRadius} y2={circleRadius} stroke={lightColor} /> */}
                {lineParts}
                <BulletStepText {...textProps} step={ACTIVE_STEP} textRef={bulletTextElement}>
                    {translatedText}
                </BulletStepText>
                {bullets}
            </svg>
        </div>
    );
};

BulletStepsCustom.propTypes = {
    activeCircleHeight: PropTypes.number,
    circleScale: PropTypes.number,
    step: PropTypes.number,
    translatedText: PropTypes.string,
    width: PropTypes.number
};

const BulletSteps = ({ translatedText, step, ...rest }) => {
    return (
        <BulletStepsCustom
            {...rest}
            translatedText={translatedText}
            step={step}
            width={20 * 32}
            activeCircleHeight={32 * 1.25}
            circleScale={1.5}
        />
    );
};

BulletSteps.propTypes = {
    translatedText: PropTypes.string,
    width: PropTypes.number,
    step: PropTypes.number
};

export default BulletSteps;
