const { stringify: toJSON } = JSON;
/*
 * @property {number} angleEnd - ...
 * @property {number} angleStart - ...
 * @property {any} center - ...
 * @property {Object} drawingDefaults - ...
 * @property {function} getCenter - ...
 * @property {function} getStaticCreationParams - ...
 * @property {LatLngLiteral} lastPosition - ...
 * @property {LatLngLiteral} lastPositionFirstPoly - ...
 * @property {number} radiusInCm - ...
 * @property {number} rotation - ...
 * @property {function} setCenter - ...
 * @property {string} type
 * @property {function} updateLastPosition - ...
 * @property {boolean} withHole - ...
 */
export class ArcCreationParams {
    /**
     * @class ArcCreationParams
     * @param {google.maps.LatLngLiteral} centerPoint
     * @param {number} radiusInCm
     * @param {!{x: !number, y: !number}} directionalRadiusInCm
     * @param {number} angleStart
     * @param {number} angleEnd
     * @param {boolean} withHole
     * @param {number} borderThickness
     */
    constructor({ centerPoint, radiusInCm, directionalRadiusInCm, angleStart, angleEnd, withHole, borderThickness }) {
        this.centerPoint = centerPoint;
        this.radiusInCm = radiusInCm;
        this.directionalRadiusInCm = directionalRadiusInCm;
        this.angleStart = angleStart;
        this.angleEnd = angleEnd;
        this.withHole = withHole;
        this.borderThickness = borderThickness;
        this.validate();
    }

    validate() {
        const equals = (a, b, varname) => {
            if (a !== b) {
                throw Error(`ArcCrParams[${varname}]: ${toJSON(a)} != ${toJSON(b)}`);
            }
        };
        const typeEquals = (value, type, varName) => {
            try {
                equals(typeof value, type, varName);
            } catch (e) {
                throw Error(`${e.message}, '${JSON.stringify(value)}'`);
            }
        };
        const isLatLngLiteral = (a, varName) => {
            if (!a) {
                throw Error(`ArcCrParams.${varName}: ${a}`);
            }
            if (typeof a.lat !== "number" || typeof a.lng !== "number") {
                throw Error(`ArcCrParams.${varName}: NotLatLngObj: ${toJSON(a)}`);
            }
        };
        isLatLngLiteral(this.centerPoint, "centerPoint");
        typeEquals(this.radiusInCm, "number", "radiusInCm");
        typeEquals(this.angleStart, "number", "angleStart");
        typeEquals(this.angleEnd, "number", "angleEnd");
        typeEquals(this.withHole, "boolean", "withHole");
        typeEquals(this.borderThickness, "number", "borderThickness");
        typeEquals(this.directionalRadiusInCm.x, "number", "directionalRadiusInCm.x");
        typeEquals(this.directionalRadiusInCm.y, "number", "directionalRadiusInCm.y");
    }
}

export default ArcCreationParams;
