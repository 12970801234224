import polyRotation from "../common/logic/polyRotation";

// Note: removed toggleRectangleRounding in nx-icerink#2

export const toggleRectangleHole = (logic, rectangleEl) => {
    if (!rectangleEl || rectangleEl.creationParams.type !== "rectangle") {
        return;
    }
    /**
     * @type {RectangleClass}
     */
    const rectClass = rectangleEl.parent
    if (!rectClass.holeSupported) {
        return;
    }
    const crParams = rectangleEl.creationParams;
    const {
        rectangleDistCmX: x,
        rectangleDistCmY: y,
        withHole,
        rotation,
        useRoundedCorners: isRounded
    } = crParams;
    const xInM = x / 100;
    const yInM = y / 100;

    const newRectangleEl = logic.addRectangle(
        xInM,
        yInM,
        isRounded,
        !withHole,
        crParams.getCenter()
    );
    polyRotation(newRectangleEl, rotation, () => {});

    logic.elBench.aSMgmt.removeSpecific(rectangleEl);
};
