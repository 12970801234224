import distance from "@turf/distance";
import { point, polygon } from "@turf/helpers";
import transformRotate from "@turf/transform-rotate";
import { objToArrLngLat } from "../../../../../common/Utils";
import { ARC_TYPE } from "../../arc/ArcConsts";

const turf = {
    polygon,
    transformRotate: transformRotate,
    point,
    distance
};

/**
 * Apply the rotation to the given path
 * @param {google.maps.LatLng} elementOrigin pivot point
 * @param {number} degrees
 * @param {google.maps.MVCArray<google.maps.LatLng>} polyPath
 */
const applyRotationToPath = (elementOrigin, degrees, polyPath) => {
    /**
     * Get the path as an array of turf coords (=[lng,lat])
     * @type {array<[number, number]>} turfArray
     */
    const lngLatArr = polyPath.getArray().map(objToArrLngLat);

    // turf requires the poly to equal first and last coord
    lngLatArr.push(objToArrLngLat(polyPath.getAt(0)));

    // Create a turf-poly from the coordinates
    const poly = turf.polygon([lngLatArr]);

    // Use the turf-poly to calculate the rotated coordinates
    turf.transformRotate(poly, degrees, {
        mutate: true,
        pivot: objToArrLngLat(elementOrigin)
    });

    // eslint-disable-next-line no-undef
    const gMapsLatLngFromLngLatArr = (lngLatArr) => new google.maps.LatLng(lngLatArr[1], lngLatArr[0])

    // implicitly discarding the 'firstCoord' we added before
    // by only mapping $polyPath.length elements
    const lngLatCoords = poly.geometry.coordinates[0];
    for (let i = 0; i < polyPath.getLength(); i++) {
        polyPath.setAt(i, gMapsLatLngFromLngLatArr(lngLatCoords[i]));
    }
};

/**
 * Rotate the given $element by $degrees around its $.getCenter or $origin, calling $handleChange afterwards.
 * @param {object} element
 * @param {number} degrees
 * @param {function} handleChange
 * @param {boolean} keepCRParamRotation
 */
export const polyRotation = (element, degrees, handleChange, keepCRParamRotation) => {



    if (element && element.getPaths()) {
        // Retrieve element-type specific rotation pivot/origin
        const elementOrigin =
            element.creationParams.type === ARC_TYPE
                ? element.creationParams.getCenter()
                : element.parent.creationParams.origin;

        // Apply the given rotation to all paths
        element.getPaths().forEach((polyPath) => applyRotationToPath(elementOrigin, degrees, polyPath));
        // If we want to rotate an element without changing the creationParams
        // we can set keepCRParamRotation to true, e.g. for loading purposes
        if (!keepCRParamRotation) {
            element.creationParams.rotation += degrees;
        }
        handleChange();
    }
};

export default polyRotation;
