import { defaultRectangleSettings } from "../common/DefaultPolySettings";
import { isDebugging } from "../debugging";
import { RECTANGLE_TYPE } from "./RectangleConsts";

const { stringify: toJSON } = JSON;

/**
 * @typedef {import("../common/GenericCoord").GenericCoord} GenericCoord
 */

/**
 * @property {LatLngLiteral} curvedOrigin - ...
 * @property {Object} drawingDefaults - ...
 * @property {function} getCenter - ...
 * @property {function} getStaticCreationParams - ...
 * @property {LatLngLiteral} lastPositionFirstPoly - ...
 * @property {number} rectangleDistCmX - ...
 * @property {number} rectangleDistCmY - ...
 * @property {number} rotation - ...
 * @property {function} setCenter - ...
 * @property {number} singleCornerWidth - ...
 * @property {string} type - ...
 * @property {function} updateLastPosition - ...
 * @property {boolean} useRoundedCorners - ...
 * @property {boolean} withHole - ...
 */
export class RectangleCreationParams {
    /**
     *
     * @param {google.maps.LatLngLiteral} origin
     * @param {number} rectangleDistCmX
     * @param {number} rectangleDistCmY
     * @param {boolean} withHole
     * @param {boolean} useRoundedCorners
     * @param {number} roundingOffsetInCm
     * @param {number} borderThickness
     */
    constructor({
        origin,
        rectangleDistCmX,
        rectangleDistCmY,
        withHole,
        useRoundedCorners,
        roundingOffsetInCm,
        borderThickness
    }) {
        this.type = RECTANGLE_TYPE; //string
        this.rectangleDistCmX = rectangleDistCmX; //number
        this.rectangleDistCmY = rectangleDistCmY; //number
        this.rotation = 0; //number
        this.originCoord = origin; //LatLngLiteral
        this.curvedOrigin = origin; //LatLngLiteral
        this.drawingDefaults = defaultRectangleSettings; //Object
        // should be the same for rounded and non-rounded
        this.lastPositionFirstPoly = origin; //LatLngLiteral
        this.useRoundedCorners = useRoundedCorners;
        this.withHole = withHole;
        this.roundingOffsetInCm = roundingOffsetInCm;
        this.singleCornerWidth = useRoundedCorners ? roundingOffsetInCm : 0; //number
        this.borderThickness = borderThickness;
        this.validate();
    }

    validate() {
        const equals = (a, b, varname) => {
            if (a !== b) {
                throw Error(`RectCrParams[${varname}]: ${toJSON(a)} != ${toJSON(b)}`);
            }
        };
        const typeEquals = (value, type, varname) => {
            try {
                equals(typeof value, type, varname);
            } catch (e) {
                throw Error(`${e.message}, '${JSON.stringify(value)}'`);
            }
        };
        const isLatLngLiteral = (a, varName) => {
            if (!a) {
                throw Error(`RectCrParams.${varName}: ${a}`);
            }
            if (typeof a.lat !== "number" || typeof a.lng !== "number") {
                throw Error(`RectCrParams.${varName}: NotLatLngObj: ${toJSON(a)}`);
            }
        };
        equals(this.type, "rectangle");
        typeEquals(this.rectangleDistCmX, "number", "rectangleDistCmX");
        typeEquals(this.rectangleDistCmY, "number", "rectangleDistCmY");
        typeEquals(this.rotation, "number", "rotation");
        typeEquals(this.drawingDefaults, "object", "drawingDefaults");
        isLatLngLiteral(this.originCoord, "originCoord");
        isLatLngLiteral(this.curvedOrigin, "curvedOrigin");
        isLatLngLiteral(this.lastPositionFirstPoly, "lastPositionFirstPoly");
        typeEquals(this.singleCornerWidth, "number", "singleCornerWidth");
        typeEquals(this.useRoundedCorners, "boolean", "useRoundedCorners");
        typeEquals(this.withHole, "boolean", "withHole");
        typeEquals(this.roundingOffsetInCm, "number", "roundingOffsetInCm");
        typeEquals(this.borderThickness, "number", "borderThickness");
    }

    getCenter() {
        isDebugging() && console.warn("Deprecated, use origin", this.originCoord);
        return this.originCoord;
    }

    get origin() {
        return this.originCoord;
    }

    set origin(newOrigin) {
        this.originCoord = newOrigin;
    }

    /**
     * Take a static "snapshot" of the current params
     */
    getStaticCreationParams() {
        // Get a (shallow?)copy of all paramters of this object
        const paramsCopy = { ...this };
        // Rename originCoord to origin for this copy
        delete paramsCopy.originCoord;
        paramsCopy.origin = this.originCoord;
        // Return the copy
        return paramsCopy;
    }
    setCenter() {
        throw Error("unimplemented");
    }
    updateLastPosition() {
        throw Error("unimplemented");
    }
    // instead of CommonDrawing.setCenter
    onDragEnd() {
        throw Error("unimplemented");
    }
}
