import { defaultZoomLevel } from "Configuration";

/**
 * -1 survives buggy element implementations returning 0 as their area
 */
export const initialTotalArea = -1;

/**
 * @typedef {object} Step3Data
 * @property {number} visitorCount
 */
/**
 * @class AppStateClass
 * @property {google.maps.LatLngLiteral} step1
 * @property {any} step2
 * @property {Step3Data} step3
 */
export class AppState {
    /**
     * @param {google.maps.LatLngLiteral} latLngOrigin
     */
    constructor(latLngOrigin) {
        this.dataSource = "defaultConstructor";
        this.common = { zoom: defaultZoomLevel };
        this.step1 = latLngOrigin;
        this.step2 = {
            stateObj: {
                elements: [],
                grid: true,
                center: latLngOrigin
            }
        };
        this.step3 = {
            rentalTimeInWeeks: 6,
            entryFeeChildren: 4.5,
            entryFeeAdults: 7,
            rentalFeeSkates: 3.5,
            rentalFeeSkatingAids: 4
        };
        // Bound in Designer#requestDesignerUpdateOverride (on nextBtn click)
        this.totalArea = initialTotalArea;
    }
}

export default AppState;
