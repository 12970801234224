import { designerMapOptionDefaults } from "Configuration";
import { waitForElement } from "../../../common/Utils";
import { R2GM } from "./R2GM";

/**
 * Binds google maps to element,
 * adds reset-to-center listener,
 * moves Panels onto map,
 * initializes logic module for handling the rest
 */
export class MapSetup {
    constructor(originProvider, zoom, google) {
        this.ready = true;
        this.originProvider = originProvider;
        this.zoom = zoom;
        this.google = google;
    }

    /**
     * @param {any} mapEl
     * @returns {R2GM} r2gm
     */
    initMap(mapEl, dataChanged) {
        if (mapEl === null) {
            console.trace("Trace for null map-element");
            throw new Error("Map element shall not be 'null'");
        }
        const self = this;
        const google = self.google;
        // Update center on location change
        const firstCenter = self.originProvider.getOrigin();

        // First we need to initialize the map, defining where it should display and how
        // the user can interact with it.
        /**
         * @type {google.maps.Map}
         */
        const map = new google.maps.Map(mapEl, {
            ...designerMapOptionDefaults(google),
            zoom: self.zoom,
            center: firstCenter,
            mapTypeId: google.maps.MapTypeId.HYBRID
        });

        window.debug = window.debug ? window.debug : {};
        window.debug.map = map;

        this.enableResetToCenter(map, this.originProvider);
        this.addPanelsToMap(map, google);

        // This map we then pass to the AmazingStateManagement which will handle all elements
        // we draw on this map.
        const r2gm = new R2GM(map, google, firstCenter, self.originProvider, dataChanged);
        return r2gm;
    }

    addPanelsToMap(map, google) {
        [
            ["selectionManipulation", google.maps.ControlPosition.BOTTOM],
            ["elementDrawer", google.maps.ControlPosition.LEFT],
            ["globalThings", google.maps.ControlPosition.RIGHT]
        ].forEach(elIdAndPos => {
            /**
             * @param {any} el
             */
            /**
             * @param {any} el
             */
            waitForElement(
                el => {
                    map.controls[elIdAndPos[1]].push(el);
                },
                () => document.getElementById(elIdAndPos[0])
            );
        });
    }

    /**
     *
     * @param {google.maps.Map} map
     * @param {import("scenes/common/customTypes").LatLngNum} firstCenter
     */
    enableResetToCenter(map, originProvider) {
        const mapDiv = map.getDiv();
        let positionResetTriggered = false;
        map.addListener("center_changed", e => {
            if (!positionResetTriggered) {
                positionResetTriggered = true;
                mapDiv.classList.add("outOfBounds");
                setTimeout(() => {
                    console.warn(
                        "♦️ MapSetup#resetToCenter originProvider",
                        originProvider.getOrigin()
                    );
                    map.setCenter(originProvider.getOrigin());
                    mapDiv.classList.remove("outOfBounds");
                    positionResetTriggered = false;
                }, 1000);
            }
        });
    }
}
