import React, { useEffect, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

import "./index.scss";
import apiRoutes from "apiRoutes";
import { paths } from "IceRinkApp/route_consts";

export const ConfigPage: React.FC = () => {
    const history = useHistory();
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [revenueConfig, setConf] = useState({
        adRevenueBrackets: {},
        amountOfRentalSkates: {}
    } as any);
    const [newAdBracket, setNewAdBracket] = useState({ key: "", value: "" });
    const [newRentalSkatesBracket, setNewRentalSkatesBracket] = useState({ key: "", value: "" });
    const updateConfig = useCallback(() => {
        fetch(apiRoutes.config, {
            method: "PUT",
            body: JSON.stringify(revenueConfig),
            headers: { "Content-Type": "application/json" }
        })
            .then(() => alert("Updated config"))
            .catch(() => alert("Failed to updated config"));
    }, [revenueConfig]);

    useEffect(() => {
        const authorized = prompt("Enter Secret", localStorage.getItem("secret") ?? "");
        if (authorized !== "ffm23oJD") {
            history.push(paths.root);
        } else {
            localStorage.setItem("secret", authorized);
            setIsAuthorized(true);
        }
        fetch(apiRoutes.config, { method: "GET" })
            .then(res => res.json())
            .then(conf => {
                setConf(conf);
            });
    }, []);
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        console.log({ name, value });
        setConf({ ...revenueConfig, [name]: value });
    };
    const updateRevenueBraket = (key: any, value: any) => {
        const tmpConf = { ...revenueConfig };
        tmpConf.adRevenueBrackets[key] = value;
        setConf(tmpConf);
    };
    const updateEstimatedRentalSkates = (key: any, value: any) => {
        const tmpConf = { ...revenueConfig };
        tmpConf.amountOfRentalSkates[key] = value;
        setConf(tmpConf);
    };
    const addAdBracket = () => {
        const tmpConf = { ...revenueConfig };
        tmpConf.adRevenueBrackets[newAdBracket.key] = newAdBracket.value;
        setConf(tmpConf);
    };
    const deleteAdRevenueKey = (key: any) => {
        const tmpConf = { ...revenueConfig };
        delete tmpConf.adRevenueBrackets[key];
        setConf(tmpConf);
    };
    const addRentalSkatesBracket = () => {
        const tmpConf = { ...revenueConfig };
        tmpConf.amountOfRentalSkates[newRentalSkatesBracket.key] = newRentalSkatesBracket.value;
        setConf(tmpConf);
    };
    const deleteRentalSkatesKey = (key: any) => {
        const tmpConf = { ...revenueConfig };
        delete tmpConf.amountOfRentalSkates[key];
        setConf(tmpConf);
    };
    return isAuthorized ? (
        <React.Fragment>
            <h1>Revenue Calculation Configuration</h1>
            <div className={"config"}>
                <div>
                    <label>
                        Number of Employees:
                        <input
                            type={"number"}
                            name={"numberOfEmployees"}
                            value={revenueConfig.numberOfEmployees}
                            onChange={handleChange}
                            step={0.01}
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Employee Cost per Day:
                        <input
                            type={"number"}
                            name={"employeeCostPerDay"}
                            value={revenueConfig.employeeCostPerDay}
                            onChange={handleChange}
                            step={0.01}
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Ice-Rink rent per week:
                        <input
                            type={"number"}
                            name={"iceRinkRentPerWeek"}
                            value={revenueConfig.iceRinkRentPerWeek}
                            onChange={handleChange}
                            step={0.01}
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Insurance cost per week and squaremeter
                        <input
                            type={"number"}
                            name={"insurancePerWeekAndSquareMeter"}
                            value={revenueConfig.insurancePerWeekAndSquareMeter}
                            onChange={handleChange}
                            step={0.01}
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Hut &amp; Lights per week and squaremeter
                        <input
                            type={"number"}
                            name={"hutAndLightsPerWeekAndSquareMeter"}
                            value={revenueConfig.hutAndLightsPerWeekAndSquareMeter}
                            onChange={handleChange}
                            step={0.01}
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Price per kw/h (Elictricity)
                        <input
                            type={"number"}
                            name={"electricityPrice"}
                            value={revenueConfig.electricityPrice}
                            onChange={handleChange}
                            step={0.01}
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Price per m³ of Water
                        <input
                            type={"number"}
                            name={"waterPrice"}
                            value={revenueConfig.waterPrice}
                            onChange={handleChange}
                            step={0.01}
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Estimated additional profits per week (meals and drinks)
                        <input
                            type={"number"}
                            name={"additionalProfitPerWeek"}
                            value={revenueConfig.additionalProfitPerWeek}
                            onChange={handleChange}
                            step={0.01}
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Percentage of Sponsoring
                        <input
                            type={"number"}
                            name={"shareOfAdvertising"}
                            value={revenueConfig.shareOfAdvertising}
                            onChange={handleChange}
                            step={0.01}
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Skate rental rate
                        <input
                            type={"number"}
                            name={"skateRentalRate"}
                            value={revenueConfig.skateRentalRate}
                            onChange={handleChange}
                            step={0.01}
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Skate-Aid rental rate
                        <input
                            type={"number"}
                            name={"aidRentalRate"}
                            value={revenueConfig.aidRentalRate}
                            onChange={handleChange}
                            step={0.01}
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Rent for Skates
                        <input
                            type={"number"}
                            name={"rentConstOfSkates"}
                            value={revenueConfig.rentConstOfSkates}
                            onChange={handleChange}
                            step={0.01}
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Rent for Skate-Aids
                        <input
                            type={"number"}
                            name={"rentCostOfSkateAids"}
                            value={revenueConfig.rentCostOfSkateAids}
                            onChange={handleChange}
                            step={0.01}
                        />
                    </label>
                </div>
                <div>
                    <label>
                        Sponsor €/m² &amp; week
                        <input
                            type={"number"}
                            name={"sponsorsRevenuePerSquareMeter"}
                            value={revenueConfig.sponsorsRevenuePerSquareMeter}
                            onChange={handleChange}
                            step={0.01}
                        />
                    </label>
                </div>
                <h2>Dasherboard Revenue Brakets</h2>
                {Object.entries(revenueConfig.adRevenueBrackets)
                    .sort((a: any, b: any) => {
                        return a[0] - b[0];
                    })
                    .map(([key, value]) => {
                        return (
                            <div>
                                <label>
                                    {key} qm:
                                    <input
                                        type={"number"}
                                        value={value as number}
                                        onChange={e => updateRevenueBraket(key, e.target.value)}
                                    />
                                </label>
                                <button onClick={() => deleteAdRevenueKey(key)}>
                                    Delete Bracket
                                </button>
                            </div>
                        );
                    })}
                <h4>New Bracket</h4>
                <label>
                    qm
                    <input
                        type={"number"}
                        value={newAdBracket.key as any}
                        onChange={e => setNewAdBracket({ ...newAdBracket, key: e.target.value })}
                    />
                </label>
                <label>
                    Revenue
                    <input
                        type={"number"}
                        value={newAdBracket.value as any}
                        onChange={e => setNewAdBracket({ ...newAdBracket, value: e.target.value })}
                    />
                </label>
                <button onClick={addAdBracket}>Add bracket</button>
                <h2>Number of estimated rental skates</h2>
                {Object.entries(revenueConfig.amountOfRentalSkates)
                    .sort((a: any, b: any) => {
                        return a[0] - b[0];
                    })
                    .map(([key, value]) => {
                        return (
                            <div>
                                <label>
                                    {key} qm:
                                    <input
                                        type={"number"}
                                        value={value as number}
                                        onChange={e =>
                                            updateEstimatedRentalSkates(key, e.target.value)
                                        }
                                    />
                                </label>
                                <button onClick={() => deleteRentalSkatesKey(key)}>
                                    Delete Bracket
                                </button>
                            </div>
                        );
                    })}
                <h4>New Bracket</h4>
                <label>
                    qm
                    <input
                        type={"number"}
                        value={newRentalSkatesBracket.key as any}
                        onChange={e =>
                            setNewRentalSkatesBracket({
                                ...newRentalSkatesBracket,
                                key: e.target.value
                            })
                        }
                    />
                </label>
                <label>
                    Number of Skates
                    <input
                        type={"number"}
                        value={newRentalSkatesBracket.value as any}
                        onChange={e =>
                            setNewRentalSkatesBracket({
                                ...newRentalSkatesBracket,
                                value: e.target.value
                            })
                        }
                    />
                </label>
                <button onClick={addRentalSkatesBracket}>Add bracket</button>
            </div>
            <button onClick={updateConfig} className={"saveButton"}>
                Save
            </button>
        </React.Fragment>
    ) : null;
};
