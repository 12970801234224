import turfBuffer from "@turf/buffer";
import { polygon } from "@turf/helpers";
import turfLength from "@turf/length";
import turfUnion from "@turf/union";
import { objToArrLngLat, round3 } from "scenes/common/Utils";

/**
 * Calculate the circumfence of the elements by first trying to combine as many as possible into unions
 */
/**
 * Approximately calculate the circumfence of the given elements.
 * This method first adds a 0.125m buffer around each element and then joins all intersecting elements
 * into one big element, dissolving all redundant edges.
 * If the elements do not intersect in those 0.125m the edges aren't dissolved and the circumfence is more
 * in-accurate. The buffer also adds an error to the calculation.
 * @param {Array.<google.map.Polygon>} elements
 */
const calculateCircumfenceWithUnions = elements => {
    const circumfenceMeters = feature => turfLength(feature, { units: "kilometers" }) * 1000;
    if (elements.length > 1) {
        const els = elements;

        // Array<Array<Array<Array<number>>>
        //                         number = lat or lng
        //                   Array        = LatLngTuple = Point
        //             Array              = Points = one linear ring
        //       Array                    = linear-rings of a polygon
        // Array                          = polygons
        const polygonsArr = [];

        for (let idx = 0; idx < els.length; idx++) {
            const element = els[idx];
            const paths = element.getPaths();
            const linearRings = [];
            for (let idx2 = 0; idx2 < paths.length; idx2++) {
                const path = paths.getAt(idx2);
                const oneLinearRing = path.getArray().map(coord => objToArrLngLat(coord));

                oneLinearRing.push(oneLinearRing[0]);

                linearRings.push(oneLinearRing);
            }
            polygonsArr.push(...linearRings);
        }

        let union = null;
        const buffer = poly => turfBuffer(poly, 0.125, { units: "meters" });
        for (let idx = 0; idx < polygonsArr.length - 1; idx++) {
            if (idx === 0) {
                const polyA = polygon([polygonsArr[0]]);
                const polyB = polygon([polygonsArr[1]]);
                const buffA = buffer(polyA);
                const buffB = buffer(polyB);
                union = turfUnion(buffA, buffB);
            } else {
                const polyA = union; // 0+1, (0+1)+2, (0+1+2)+3, (0+1+2+3)+4, ...
                const polyB = polygon([polygonsArr[idx + 1]]); // 2,3,4,5,...
                const buffA = buffer(polyA);
                const buffB = buffer(polyB);
                union = turfUnion(buffA, buffB);
            }
        }
        const negativeBuffer = turfBuffer(union, -0.125, { units: "meters" });

        // three digits = milimeters
        return {
            withBuffer: round3(circumfenceMeters(union)),
            withoutBuffer: round3(circumfenceMeters(negativeBuffer))
        };
    } else if (elements.length === 1) {
        const el = elements[0];
        const outerPoly = el.getPaths().getAt(0); // outer
        const turfOuterPoly = outerPoly.getArray().map(coord => objToArrLngLat(coord));
        turfOuterPoly.push(turfOuterPoly[0]);
        const turfGeometry = polygon([turfOuterPoly]);
        const circumfenceInMeters = round3(circumfenceMeters(turfGeometry));
        return {
            withBuffer: circumfenceInMeters,
            withoutBuffer: circumfenceInMeters
        };
    } else {
        return {
            withBuffer: 0,
            withoutBuffer: 0
        };
    }
};
export const polyCircumfence = polys => calculateCircumfenceWithUnions(polys);
