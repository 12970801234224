import React, {useCallback, useEffect, useState} from "react";
import {useLocation, withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {paths} from "IceRinkApp/route_consts";
import BulletSteps from "components/BulletSteps";
import LanguageSwitcher from "../LanguageSwitcher";
import "./index.scss";

/**
 * [  "intercom"   ][Space][*burgerbutton*]
 */
export const HeaderIntercom = ({history}) => {
  const {t} = useTranslation();
  const location = useLocation();
  const [step, setStep] = useState("");

  const getStepNumber = useCallback(() => {
    console.log(`Called step ${step}`)
    switch (`/${step}`) {
      case paths.step1:
        return 1;
      case paths.step2:
        return 2;
      case paths.step3:
        return 3;
      case paths.step4:
        return 4;
      default:
        return 1;
    }
  }, [step]);

  useEffect(() => {
    setStep(location.pathname.replace("/", ""));
  }, [history.location.key, location.pathname]);
  return (
    <div id="header">
      <a href="/">
        <img
          itemProp="logo"
          id="logo"
          src="/images/realice_logo.png"
          alt="Intercom Dr. Leitner GmbH"
        />
      </a>
      <BulletSteps translatedText={t(`app.bullet.${step === "" ? "location" : step}`)} step={getStepNumber()}/>
      <div className="rightSide">
        <LanguageSwitcher/>
      </div>
    </div>
  );
};
export default withRouter(HeaderIntercom);
