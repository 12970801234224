import turfDestination from "@turf/destination";
import {
    centimetersSqToMetersSq,
    latLngFnToNum,
    lngLatArrToObj,
    objToArrLngLat,
    pow2,
    round1
} from "../../../../common/Utils";
import { handleDragCenterUpdates } from "../common/CommonDrawing";
import { defaultArcSettings } from "../common/DefaultPolySettings";
import genericCoord, { genericCoordAutoLngLat } from "../common/GenericCoord";
import calculateArc from "./ArcCalculation";
import { ARC_TYPE, BORDER_STEP_SIZE, MINIMUM_BORDER_THICKNESS, ONE_CELL } from "./ArcConsts";
import { dbg, isDebugging, tlog } from "../debugging";
import polyRotation from "../common/logic/polyRotation";
import { translateSinglePath } from "../common/logic/polyTranslation";
import { round } from "@turf/helpers";
import ArcCreationParams from "./ArcCreationParams";
import createPopupClass from "../PopupClass";
const { log } = console;
/**
 * StatBlockData JsDoc definition
 * @typedef {Object} StatBlockDataJD
 * @property {string} outerDiameter - The outer diameter of this arc | "Ø 10m x 10m"
 * @property {string|null} innerDiameter - The innerDiameter of this arc | "Ø 5m x 5m" or null
 * @property {string} area - The area of this arc | "~58.9m²"
 * @property {boolean} isFullCircle - Flag to define if ar is a full circle
 */
class StatBlockData {
  /**
   * StatBlockData constructor
   * @param {string} outerDiameter x
   * @param {string|null} innerDiameter x
   * @param {string} area x
   * @param {boolean} isFullCircle x
   * @return {StatBlockDataJD}
   */
  constructor(outerDiameter, innerDiameter, area, isFullCircle,) {
    this.outerDiameter = outerDiameter;
    this.innerDiameter = innerDiameter;
    this.area = area;
    this.isFullCircle = isFullCircle;
  }
}

/**
 *
 * @param angleEnd
 * @param angleStart
 * @param radiusInCm
 * @param withHole
 * @param borderThickness
 * @returns {number} Area of arc in cm² (rounded to mm² = 0.01)
 */
export const calculateAreaFromParams = ({
    angleEnd,
    angleStart,
    radiusInCm,
    withHole,
    borderThickness,
    directionalRadiusInCm: dirRad
}) => {
    const isEllipse = angleEnd === Math.PI * 2;
    const totalAngle = angleEnd - angleStart;
    const twoPI = Math.PI * 2; // = Tau

    const circleArea = pow2(radiusInCm) * Math.PI;
    const holeArea = pow2(radiusInCm - borderThickness) * Math.PI;

    let completeArea = undefined;
    switch (Math.abs(totalAngle)) {
        case Math.PI * 2:
        case Math.PI * 1.5:
        case Math.PI * 1:
        case Math.PI * 0.5:
        case Math.PI * 0.25:
            // factor for how much area of the full circle this arc covers
            const sliceSize = totalAngle / twoPI;
            // In cm
            if (withHole) {
                if (isEllipse) {
                    // slice size = 1
                    const { x, y } = dirRad;
                    const xHole = x - borderThickness;
                    const yHole = y - borderThickness;
                    // A = pi*a*b
                    const ellipseArea = Math.PI * x * y;
                    const ellipseHoleArea = Math.PI * xHole * yHole;
                    completeArea = ellipseArea - ellipseHoleArea;
                } else {
                    // e.g. a half-circle
                    const circleAreaSlice = circleArea * sliceSize;
                    // e.g. a smaller half-circle
                    const holeAreaSlice = holeArea * sliceSize;
                    // e.g. a half-circle with a smaller half-circle cut out of it
                    completeArea = circleAreaSlice - holeAreaSlice;
                }
            } else {
                if (isEllipse) {
                    const { x, y } = dirRad;
                    // A = pi*a*b
                    const ellipseArea = Math.PI * x * y;
                    completeArea = ellipseArea * sliceSize;
                } else {
                    completeArea = circleArea * sliceSize;
                }
            }
            break;
        default:
            throw Error("Unexpected arc type");
    }
    dbg(() => console.log("area", completeArea));
    return round(completeArea, 2);
};

/**
 *
 * @param {ElBench} elBench
 * @param {ArcCreationParams} arcCreationParams
 */
const drawArc = (elBench, arcCreationParams) => {
    arcCreationParams.validate();
    const {
        centerPoint,
        radiusInCm,
        directionalRadiusInCm,
        angleStart,
        angleEnd,
        withHole,
        borderThickness
    } = arcCreationParams;

    const centerPointLiteral = genericCoordAutoLngLat(centerPoint).literal;

    const paths = calculateArc(arcCreationParams);

    const arcPoly = new elBench.google.maps.Polygon({
        ...defaultArcSettings,
        paths: paths
    });

    arcPoly.creationParams = {
        type: ARC_TYPE,

        /**
         * @see #calculateCenter
         * @returns {google.maps.LatLngLiteral}
         */
        getCenter: () => {
            return arcPoly.creationParams.calculateCenter();
        },
        /**
         * The center sadly isn't always included in the google.maps coords.
         * So we have to calculate it for some cases.
         * <br/>
         * The easy case is !withHole&&!isFullCircle, there the center is googlePoly[0][0].
         * <br/>
         * In the (!withHole&&isFullCircle) case the googlePoly[0][0] coord is the upper edge
         * of the full-circle. So we go one radius down from there and reach the center.
         * <br/>
         * In the (
         * @returns {google.maps.LatLngLiteral}
         */
        calculateCenter: () => {
            const isFullCircle = angleEnd % (2 * Math.PI) === 0;
            if (withHole) {
                const {
                    directionalRadiusInCm: dirRad,
                    radiusInCm,
                    rotation
                } = arcPoly.creationParams;
                const lastCoord = objToArrLngLat(
                    arcPoly.getPath().getAt(arcPoly.getPath().length - 1)
                );
                const direction = isFullCircle ? rotation - 90 : rotation + 180;
                const radius = isFullCircle ? dirRad.x : radiusInCm;
                return lngLatArrToObj(
                    turfDestination(lastCoord, radius, direction, {
                        units: "centimeters"
                    }).geometry.coordinates
                );
            } else {
                const isHalfCircle = angleEnd % Math.PI === 0;
                if (isFullCircle) {
                    const { directionalRadiusInCm: dirRad, rotation } = arcPoly.creationParams;

                    // right side
                    let mostEastCoord = arcPoly.getPath().getAt(0);
                    const mostEastPoint = objToArrLngLat(mostEastCoord);
                    const direction = rotation - 90;
                    const centerPoint = turfDestination(mostEastPoint, dirRad.x, direction, {
                        units: "centimeters"
                    }).geometry.coordinates;

                    return lngLatArrToObj(centerPoint);
                } else if (isHalfCircle) {
                    const currentPath = arcPoly.getPath();
                    const currentCenter = latLngFnToNum(currentPath.getAt(0));
                    arcPoly.creationParams.center = currentCenter;
                    return currentCenter;
                }
                const currentPath = arcPoly.getPath();
                return latLngFnToNum(currentPath.getAt(0));
            }
        },
        center: centerPointLiteral,
        centerPoint: centerPointLiteral,
        setCenter: function (newCenter) {
            this.center = newCenter;
            this.centerPoint = newCenter;
        },
        rotation: 0,
        radiusInCm,
        /**
         * Only to be used for fullCircles => ellipses
         */
        directionalRadiusInCm: directionalRadiusInCm,
        angleStart, // unused?
        angleEnd,
        withHole,
        drawingDefaults: defaultArcSettings,
        getStaticCreationParams: () => {
            const staticParams = { ...arcPoly.creationParams };
            delete staticParams.sizePopup;
            staticParams.center = latLngFnToNum(staticParams.center);
            return staticParams;
        },
        borderThickness
    };

    arcPoly.creationParams.lastPosition = arcPoly.creationParams.calculateCenter();
    arcPoly.creationParams.lastPositionFirstPoly = arcPoly.getPath().getAt(0);
    arcPoly.creationParams.updateLastPosition = () => {
        arcPoly.creationParams.lastPosition = arcPoly.creationParams.calculateCenter();
        arcPoly.creationParams.lastPositionFirstPoly = arcPoly.getPath().getAt(0);
    };

    /**
     * Calculates the current area of this arc, based on the creation-params.
     * @returns {number} Area in m²
     * @see designer.jsx#totalAreaFromGooglePolys
     */
    arcPoly.creationParams.calculateArea = () => {
        return centimetersSqToMetersSq(calculateAreaFromParams(arcPoly.creationParams));
    };

    /***
     * called via thicken, slim, squeeze, stretch, scale
     */
    arcPoly.creationParams.recalculateAndApplyPaths = () => {
        const crParams = arcPoly.creationParams;

        const creationParameters = new ArcCreationParams({
            ...crParams,
            centerPoint: crParams.calculateCenter()
        });
        const newPaths = calculateArc(creationParameters);
        arcPoly.setPaths(newPaths);
        polyRotation(arcPoly, arcPoly.creationParams.rotation, () => {}, true);

        // update the stat popup
        arcPoly.creationParams.updatePopup();
    };

    arcPoly.creationParams.returnToPreChangeCenter = preChangeCenter => {
        const offset = genericCoordAutoLngLat(preChangeCenter).diff(
            genericCoord({ literal: arcPoly.creationParams.calculateCenter() })
        );
        arcPoly.getPaths().forEach(path => translateSinglePath(path, offset.literal));
    };

    /**
     * A method that increases the border thickness and then applies the new coordinates
     * to the googlePolygon
     */
    arcPoly.creationParams.thickenBorder = () => {
        const crParams = arcPoly.creationParams;
        console.log("thickenBorder", crParams); // 500x500, borderthickness 500
        const { radiusInCm, borderThickness, directionalRadiusInCm: dirRad } = crParams;

        if (crParams.isFullCircle()) {
            // from polyScale#isArcAndTooSmall;fullCircle;withHole
            const TWO_CELLS = 2 * ONE_CELL;
            // #o#   ONE_CELL   -> false
            // #oo#  TWO_CELL   -> false (would result in ####)
            // ##o## ONE_CELL   -> false
            // ##oo## ONE_CELL  -> false (would result in ######)
            // #ooo# >TWO_CELLS -> true
            const holeDiameterX = 2 * (dirRad.x - borderThickness); // 2*(500 - 250) = 500
            const holeDiameterY = 2 * (dirRad.y - borderThickness); // 2*(500 - 250) = 500
            if (holeDiameterX <= TWO_CELLS || holeDiameterY <= TWO_CELLS) {
                tlog(
                    `arc.thicken not thickening to holeDiameter: ${holeDiameterX}x${holeDiameterY}`
                );
                return;
            } else {
                dbg(() => log(`Allowing ${holeDiameterX} ${holeDiameterY}`));
            }
        } else {
            const holeDiameter = (radiusInCm - (borderThickness + BORDER_STEP_SIZE)) * 2;
            if (holeDiameter <= ONE_CELL) {
                // hole would be smaller than one cell (2.5mx2.5m) => don't thicken border
                tlog("arc.thicken not thickening to holeDiameter:", holeDiameter);
                return;
            }
        }

        const preChangeCenter = crParams.getCenter();
        crParams.borderThickness += BORDER_STEP_SIZE;
        arcPoly.creationParams.recalculateAndApplyPaths();
        arcPoly.creationParams.returnToPreChangeCenter(preChangeCenter);

        tlog("arc.thicken", borderThickness, "=>", crParams.borderThickness);
    };
    /**
     * A method that decreases the border thickness and then applies the new coordinates
     * to the googlePolygon
     */
    arcPoly.creationParams.slimBorder = () => {
        const crParams = arcPoly.creationParams;
        const { borderThickness } = crParams;

        if (borderThickness <= MINIMUM_BORDER_THICKNESS) {
            return;
        }

        const preChangeCenter = crParams.getCenter();
        crParams.borderThickness -= BORDER_STEP_SIZE;
        arcPoly.creationParams.recalculateAndApplyPaths();
        arcPoly.creationParams.returnToPreChangeCenter(preChangeCenter);

        tlog("arc.slim", borderThickness, "=>", borderThickness - BORDER_STEP_SIZE);
    };

    /**
     * A method that squeezes this arcPoly in 2.5m steps, in either
     * x or y direction.<br/>
     * For this the arcPoly.creationParams are modified and then the
     * paths arcPoly.paths are recalculated.
     * @param {boolean} isXDirection - false = squeezes in Y-direction
     */
    arcPoly.creationParams.squeeze = isXDirection => {
        console.log("arcPoly.creationParams.squeeze", isXDirection);

        const { directionalRadiusInCm, withHole, borderThickness } = arcPoly.creationParams;
        const xOrY = isXDirection ? "x" : "y";
        const radInSqueezeDir = directionalRadiusInCm[xOrY];
        const diameterInSqueezeDir = 2 * radInSqueezeDir;

        // can I squeeze the ellipse into the given direction?

        /**
         * Disallow squeezing if this is true
         * @type {boolean}
         */
        let isTooSmall = false;
        if (withHole) {
            const holeDiameterInSqueezeDir = 2 * (radInSqueezeDir - borderThickness);
            isTooSmall = holeDiameterInSqueezeDir <= ONE_CELL;
        } else {
            isTooSmall = diameterInSqueezeDir <= ONE_CELL;
        }
        if (isTooSmall) {
            tlog(`Not down-scaling into ${xOrY}-Direction because ellipse is too small.`);
        } else {
            const crParams = arcPoly.creationParams;
            const preChangeCenter = crParams.getCenter();
            crParams.directionalRadiusInCm[isXDirection ? "x" : "y"] -= ONE_CELL / 2;
            crParams.recalculateAndApplyPaths();
            crParams.returnToPreChangeCenter(preChangeCenter);
        }
    };

    /**
     * A method that stretches this arcPoly in 2.5m steps, in either
     * x or y direction.<br/>
     * For this the arcPoly.creationParams are modified and then the
     * paths arcPoly.paths are recalculated.
     * @param {boolean} isXDirection - false = stretches in Y-direction
     */
    arcPoly.creationParams.stretch = isXDirection => {
        const crParams = arcPoly.creationParams;
        const preChangeCenter = crParams.getCenter();
        crParams.directionalRadiusInCm[isXDirection ? "x" : "y"] += ONE_CELL / 2;
        crParams.recalculateAndApplyPaths();
        crParams.returnToPreChangeCenter(preChangeCenter);
    };

    arcPoly.creationParams.isFullCircle = () => arcPoly.creationParams.angleEnd === Math.PI * 2;

    /**
     * Generates an html stat-block for the info-popup
     * @return {StatBlockDataJD}
     */
    arcPoly.creationParams.generateStatBlockData = () => {
        const {
            radiusInCm,
            directionalRadiusInCm: dirRad,
            withHole,
            borderThickness: bT
        } = arcPoly.creationParams;
        const fullCircle = arcPoly.creationParams.isFullCircle();
        // InnerRadius is (OuterRadius-BorderThickness)
        const borderTInM = bT / 100;
        // InnerDiameter is (OuterDiameter - (BorderThickness *2)), because
        // we intersect the border twice
        const innerFromDiameterInM = diameterInM => (diameterInM - (borderTInM * 2));

        const statBlockData = new StatBlockData("", null, "", fullCircle);

        // Calculate diameter from radius with fixed decimals e.g. 50.5 => 101.0
        const rad2Dia = rad => (rad * 2).toFixed(1)

        if (fullCircle) {
            const xRadInM = (dirRad.x / 100);
            const yRadInM = (dirRad.y / 100);

            const xDiameterInM = rad2Dia(xRadInM);
            const yDiameterInM = rad2Dia(yRadInM);

            statBlockData.outerDiameter = `Ø ${xDiameterInM}m x ${yDiameterInM}m`;
            if (withHole) {
                const xDiameterInnerInM = innerFromDiameterInM(xDiameterInM);
                const yDiameterInnerInM = innerFromDiameterInM(yDiameterInM);
                statBlockData.innerDiameter = `Ø ${xDiameterInnerInM}m x ${yDiameterInnerInM}m`;
            }
        } else {
            const outerDiameterInM = rad2Dia(radiusInCm / 100);
            statBlockData.outerDiameter  = `Ø ${outerDiameterInM}m`;
            if (withHole) {
                const innerDiameterInM = rad2Dia((radiusInCm / 100) - borderTInM);
                statBlockData.innerDiameter  = `Ø ${innerDiameterInM}m`;
            }
        }
        // e.g. "10m<br/>5m" or "10m x 10m<br/>5m x 5m"
        statBlockData.area = "~" + (round1(arcPoly.creationParams.calculateArea()).toFixed(1)) + "m²";
        return statBlockData;
    };

    /**
     * Generates an html stat-block for the info-popup
     */
    arcPoly.creationParams.generateStatBlock = () => {
        const statBlockData = arcPoly.creationParams.generateStatBlockData()

        let radii;
        radii = statBlockData.outerDiameter;
        if (withHole) {
            radii += "<br/>";
            radii += statBlockData.innerDiameter;
        }
        // e.g. "10m<br/>5m" or "10m x 10m<br/>5m x 5m"
        radii += "<br/>";
        radii += statBlockData.area;
        return radii;
    };
    arcPoly.creationParams.updatePopup = () => {
        // update popup
        const newPosition = genericCoord({ literal: arcPoly.creationParams.calculateCenter() })
            .latLngFn;
        const newHTML = arcPoly.creationParams.generateStatBlock();
        arcPoly.creationParams.sizePopup.updatePositionAndText(newPosition, newHTML);
    };

    arcPoly.creationParams.sizePopup = new (createPopupClass(elBench.google))(
        genericCoord({ literal: arcPoly.creationParams.calculateCenter() }).latLngFn,
        arcPoly.creationParams.generateStatBlock(),
        elBench.map
    );

    arcPoly.parent = arcPoly.parent ? arcPoly.parent : {};
    /**
     * Sets map of this poly and its info-popup to null
     */
    arcPoly.parent.removeFromMap = () => {
        arcPoly.setMap(null); //
        arcPoly.creationParams.sizePopup.setMap(null); //
    };

    // add drag event listeners to this poly
    handleDragCenterUpdates(elBench, arcPoly);

    // debugging
    if (isDebugging()) {
        window.debug.elements2 = window.debug.elements2 ? window.debug.elements2 : [];
        window.debug.elements2.push(arcPoly);
    }
    return arcPoly;
};
export default drawArc;
