import { GenericCoord } from "../common/GenericCoord";
import { TurfHelper } from "./TurfHelper";

/**
 * @typedef CalculateGridParams
 * @property {google.maps.LatLng | google.maps.LatLngLiteral} origin
 * @property {number} gridSizeInBlocks
 * @property {google.maps.Map} map
 */

/**
 * For the given zoomLevel give an edgeLength to use in the grid.
 * @param {number} zoomLevel 0-22 Higher = closer
 * @returns {number} multiple of 2.5m in cm e.g. 250 or 500 or 1000
 */
export const calculateEdgeLengthForZoom = zoomLevel => {
    // 20,21,22    => 1 => 2.5m
    // 19,18,17... => 2^0 = 1, 2^2 = 4, 2^3 = 8, ... => 2.5m, 5m, 10m, ...
    let scaleFactor = zoomLevel >= 20 ? 1 : Math.pow(2, 19 - zoomLevel); // 2.5m
    return 250 * scaleFactor;
};

/**
 *
 * @param {CalculateGridParams} param0
 */
const calculateGrid = ({ origin, gridSizeInBlocks, map }) => {
    if (gridSizeInBlocks < 2) {
        throw Error("Grid shall be at least 2x2");
    }

    let widthCm, heightCm;

    if (map.getBounds()) {
        const ne = map.getBounds().getNorthEast();
        const sw = map.getBounds().getSouthWest();

        const turfHelper = new TurfHelper();

        const distanceWestEastCm = turfHelper.calculateLngDistance(ne, sw) * 100;
        const distanceNorthSouthCm = turfHelper.calculateLatDistance(ne, sw) * 100;

        widthCm = distanceWestEastCm;
        heightCm = distanceNorthSouthCm;

        // Todo use distances to extend/limit grid size...
        const coords = calculateGridCoords(widthCm, heightCm, map, origin);
        return coords;
    } else {
        // const sizeFromCenter = 2 * (gridSizeInBlocks / 2); // multiple of 2 for odd / even
        // widthCm = sizeFromCenter;
        // heightCm = widthCm;
        // widthCm = 0;
        // heightCm = 0;
        return [{ lng: 90, lat: 180 }]; // ignoring grid drawing without bounds for now
    }
};

/**
 * (heightCm)
 *   ##################
 *   ##################
 *   ##################
 *   ########╳ origin #
 *   ##################
 *   ##################
 *   ##################(widthCm)
 * ╳ grid-origin
 *
 *
 * @param {number} widthCm Distance in cm in west and east direction from center
 * @param {number} heightCm Distance in cm in North and South direction from center
 * @param {google.maps.Map} map
 * @param {google.maps.LatLngLiteral} origin Center of Map/Grid
 */
const calculateGridCoords = (widthCm, heightCm, map, origin) => {
    /**
     * @type {GenericCoord} Center of this grid
     */
    const centerGeneric = new GenericCoord({ literal: origin });

    /**
     * @type {number} Cells are squares, so this value defines the length of each edge of the cell
     */
    const cellEdgeLengthCm = calculateEdgeLengthForZoom(map.getZoom()); // from one column line to the next

    const heightRoundedToCell =
        Math.round(heightCm / cellEdgeLengthCm + 0.4) * cellEdgeLengthCm + cellEdgeLengthCm; // last CELCm to avoid rounding down to visible space
    const widthRoundedToCell =
        Math.round(widthCm / cellEdgeLengthCm + 0.4) * cellEdgeLengthCm + cellEdgeLengthCm; // last CELCm to avoid rounding down to visible space

    const halfHeightRoundedToCell =
        Math.round(heightCm / 2 / cellEdgeLengthCm + 0.4) * cellEdgeLengthCm;
    const halfWidthRoundedToCell =
        Math.round(widthCm / 2 / cellEdgeLengthCm + 0.4) * cellEdgeLengthCm;

    /**
     * Calculate the grid origin, which is located south-west of the given origin
     * @type {number[]} LatLng origin of grid to start drawing from
     *
     *  ┌─────────────┐
     *  │             │
     *  │      ╳      │       (lat)
     *  │     center  │   (-lng)┼(lng)
     *  └─────────────┘      (-lat)
     * ╳ origin
     *
     * (-lng) West: widthCm/2
     * (-lat) South: heightCm / 2
     */
    const originGeneric = new GenericCoord({
        literal: {
            lat: centerGeneric.south(halfHeightRoundedToCell).latitude,
            lng: centerGeneric.west(halfWidthRoundedToCell).longitude
        }
    });

    //setTimeout(() => {
    //    line([centerGeneric.literal, originGeneric.literal])
    //}, 100)

    const gridCoords = [
        {
            lng: 1,
            lat: 0
        }
    ];

    /**
     *
     * @param {GenericCoord} originGeneric
     */
    const calculateLineCoords = (originGenericX, widthCm, heightCm, gridCoords) => {
        const originGeneric = originGenericX; //originGenericX.north(100 * 250);

        addVerticalLinesNew(originGeneric, widthCm, heightCm, cellEdgeLengthCm, gridCoords);
        addHorizontalLinesNew(originGeneric, widthCm, heightCm, cellEdgeLengthCm, gridCoords);

        return gridCoords;
    };

    /**
     * @param {GenericCoord} originGeneric where the grid begins
     * @param {number} widthCm Width in cm, long lines are drawn from 'originGeneric' to 'widthCm'
     * @param {number} heightCm lines start at bottom side and are drawn in 'cellEdgeLengthCm' steps to the top (=heightCm)
     * @param {number} cellEdgeLengthCm edgelength of one grid-cell
     * @param {GenericCoord[]} gridCoords mutation: coordinates are added into this
     */
    const addHorizontalLinesNew = (
        originGeneric,
        widthCm,
        heightCm,
        cellEdgeLengthCm,
        gridCoords
    ) => {
        // e.g. cellEdgeLengthCm = 100
        // For each vertical step 0,100,200,300..., yMax

        for (let y = 0; y < heightCm; y += cellEdgeLengthCm) {
            // alternate between drawing a line from
            const i = y / cellEdgeLengthCm;
            const even = i % 2 === 0;
            const cmY = y; // e.g. 0, 250, 500, 750, 1000, ...
            if (even) {
                // 0,2,4,6,...
                /**
                 *  widthCm
                 * ╠════════╣
                 *
                 * ..........
                 * .
                 * ..........
                 *          .
                 * o────────a  ╦
                 * .           ║
                 * ..........  ║ cmY
                 *          .  ║
                 * x.........  ╩
                 *
                 */
                const longLine = originGeneric.north(cmY).east(widthCm);

                /**
                 *  widthCm
                 * ╠════════╣
                 *
                 * ..........
                 * .
                 * .........b  ╦
                 *          |  ║ cellEdgeLengthCm
                 * o────────┘  ╬
                 * .           ║
                 * ..........  ║ cmY
                 *          .  ║
                 * x.........  ╩
                 *
                 */
                const shortLine = originGeneric.east(widthCm).north(cmY).north(cellEdgeLengthCm);
                gridCoords.push(longLine); // a
                gridCoords.push(shortLine); // b
            } else {
                // 1,3,4,5,...
                /**
                 *  widthCm
                 * ╠════════╣
                 *
                 * ..........
                 * .
                 * c────────┐  ╦
                 *          │  ║
                 * o────────┘  ║
                 * .           ║ cmY
                 * ..........  ║
                 *          .  ║
                 * x.........  ╩
                 *
                 */
                const longLine = originGeneric.north(cmY);

                /**
                 *  widthCm
                 * ╠════════╣
                 *
                 * d.........
                 * │
                 * └────────┐  ╦
                 *          │  ║
                 * o────────┘  ║
                 * .           ║ cmY
                 * ..........  ║
                 *          .  ║
                 * x.........  ╩
                 *
                 */
                const shortLine = originGeneric.north(cmY).north(cellEdgeLengthCm);
                gridCoords.push(longLine); // c
                gridCoords.push(shortLine); // d
            }
        }
    };

    /**
     * @param {GenericCoord} originGeneric where the grid begins
     * @param {number} widthCm Width in cm = lines start at left side and are drawn in 'cellEdgeLengthCm' steps to the right
     * @param {number} heightCm long lines are drawn from 'originGeneric' to 'heightCm'
     * @param {number} cellEdgeLengthCm  edgelength of one grid-cell
     * @param {GenericCoord[]} gridCoords mutation: coordinates are added into this
     */
    const addVerticalLinesNew = (
        originGeneric,
        widthCm,
        heightCm,
        cellEdgeLengthCm,
        gridCoords
    ) => {
        // e.g. cellEdgeLengthCm = 100
        // For each horizontal step 0,100,200,300..., xMax
        for (let x = 0; x < widthCm; x += cellEdgeLengthCm) {
            // alternate between drawing a line from
            const i = x / cellEdgeLengthCm;
            const even = i % 2 === 0;
            const cmX = x;

            /**
             *   b-c
             *   | |
             * o-a d
             */
            if (even) {
                // 0,2,4,6,...
                /**
                 *   ...
                 *   . .
                 * o─a .
                 */
                const shortLine = originGeneric.east(cmX);

                /**
                 *   b..
                 *   │ .
                 * o─┘ .
                 */
                const longLine = shortLine.north(heightCm);
                gridCoords.push(shortLine); // a
                gridCoords.push(longLine); // b
            } else {
                // 1,3,4,5,...

                /**
                 *   ┌─┐
                 *   │ │
                 * o─┘ d
                 */
                const longLine = originGeneric.east(cmX);

                /**
                 *   ┌─c
                 *   │ .
                 * o─┘ .
                 */
                const shortLine = longLine.north(heightCm);
                gridCoords.push(shortLine); // c
                gridCoords.push(longLine); // d
            }
        }
    };

    return calculateLineCoords(originGeneric, widthRoundedToCell, heightRoundedToCell, gridCoords);
};

export default calculateGrid;
