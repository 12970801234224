import { pow2 } from "../../../../common/Utils";
import { RECTANGLE_ROUNDING_OFFSET } from "./RectangleConsts";

/**
 *
 * <pre>
 *  CurvedRectangle  Corner-Circles   Corners
 *  +--███████--+    +--█--+--█--+    +--+--+--+--+                                    area of
 *  |███████████|    |███  .  ███|    |  |  .  |  |                                    missing
 *  █████████████    ████  .  ████    +--+  .  +--+    Virtual    area of   area of    corner
 *  █████████████    |     .     |    |     .     |    square     square    circle     space
 *  █████████████ => +...........+ => +...........+    +--+--+    ███████   +--█--+    ███ ███
 *  █████████████    |     .     |    |     .     |    |  |  |    ███████   |█████|    █     █
 *  █████████████    ████  .  ████    +--+  .  +--+ => +--+--+ =>(███████ - ███████) =
 *  |███████████|    |███  .  ███|    |  |  .  |  |    |  |  |    ███████   |█████|    █     █
 *  +--███████--+    +--█--+--█--+    +--+--+--+--+    +--+--+    ███████   +--█--+    ███ ███
 *  Resulting area in CurvedRectangle-Space
 *  ███+--+--+███
 *  █  |  .  |  █
 *  +--+  .  +--+
 *  |     .     |
 *  +...........+
 *  |     .     |
 *  +--+  .  +--+
 *  █  |  .  |  █
 *  ███+--+--+███
 * </pre>
 * @returns {number}
 */
const calculateMissingCornerArea = () => {
    /**
     * One "cell" is sized 2.5mx2.5m.
     * The minimal size of a non-hole rectangle is 1x1 cell
     * The minimal size of a hole rectangle is 3x3 cells
     * The minimal size of the rectangle representing the hole is 1x1 cell
     * The size of the corners of all rectangles is 1/4x1/4 cells.
     */

    /**
     * The quarter circles of each corner are each contained within squares with edge-length = RECTANGLE_ROUNDING_OFFSET
     * So an "virtual" rectangle containing all four corner-squares has an edge-length of RECTANGLE_ROUNDING_OFFSET*2.
     *
     * <pre>
     * +--+--+--+--+
     * |  |  .  |  |
     * +--+  .  +--+
     * |     .     |
     * +...........+    +--+--+
     * |     .     |    |  |  |
     * +--+  .  +--+ => +--+--+
     * |  |  .  |  |    |  |  |
     * +--+--+--+--+    +--+--+
     * </pre>
     */
    const edgeLengthOfVirtualRectangle = RECTANGLE_ROUNDING_OFFSET * 2;

    // Each corner has a 90°-quarter of a circle
    // combine those quarters and you get the full circle
    // draw lines between the original corner and the circle
    // quarter corner and you get a "virtual" square containing the circle.
    // Subtract the circle from the "virtual" square and the remaining area
    // is equal to the missing area of the rounded-corner rectangle
    // as all rounded rectangles have the same corner area this value
    // remains somewhat constant
    /**
     * <pre>
     * +--+--+    ███████
     * |  |  |    ███████
     * +--+--+ => ███████
     * |  |  |    ███████
     * +--+--+    ███████
     * </pre>
     * @type {number}
     */
    const areaOfSquare = pow2(edgeLengthOfVirtualRectangle); // b²
    /**
     * <pre>
     * +--+--+    +--█--+
     * |  |  |    |█████|
     * +--+--+ => ███████
     * |  |  |    |█████|
     * +--+--+    +--█--+
     * </pre>
     * @type {number}
     */
    const areaOfCircle = pow2(RECTANGLE_ROUNDING_OFFSET) * Math.PI; // r² * π
    // noinspection UnnecessaryLocalVariableJS
    /**
     * <pre>
     * +--+--+    ███████   +--█--+   ███ ███
     * |  |  |    ███████   |█████|   █     █
     * +--+--+ => ███████ - ███████ =
     * |  |  |    ███████   |█████|   █     █
     * +--+--+    ███████   +--█--+   ███ ███
     * </pre>
     * @type {number}
     */
    let remainingArea = areaOfSquare - areaOfCircle;
    return remainingArea; // 3353.153696914871 ~3353.1cm = 33531mm
};

/**
 * Cases:
 * case withHole useRoundedCorner
 *   a      0          0
 *   b      0          1
 *   c      1          0
 *   d      1          1
 *
 * a) w*h
 * b) (w*h) - (static.missinAreaOfCorners)
 * c) w*h - (inner.w * inner.h)
 * d) (w*h - static.missinAreaOfCorners) - (inner.w * inner.h - static.missinAreaOfCornersInner)
 *
 */
export const calculateAreaFromParams = ({
    rectangleDistCmX: rectX,
    rectangleDistCmY: rectY,
    useRoundedCorners,
    withHole,
    borderThickness: borderWidth
}) => {
    const outerRectArea = rectX * rectY;
    const missingCornerArea = calculateMissingCornerArea();

    if (withHole) {
        /**
         * Per edge we have two parts of the border (e.g. top+bottom or left+right)
         * @type {number}
         */
        const borderEdgeSpace = borderWidth + borderWidth;

        /**
         * Area of "hole" (=inner rectangle)
         * @type {number}
         */
        let innerRectArea = (rectX - borderEdgeSpace) * (rectY - borderEdgeSpace);

        const outerArea = useRoundedCorners ? outerRectArea - missingCornerArea : outerRectArea;
        const innerArea = useRoundedCorners ? innerRectArea - missingCornerArea : innerRectArea;
        return outerArea - innerArea;
    } else {
        if (useRoundedCorners) {
            // b) (w*h) - (static.missinAreaOfCorners)
            return outerRectArea - missingCornerArea;
        } else {
            // a) w*h
            return outerRectArea;
        }
    }
};
