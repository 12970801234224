import PropTypes from "prop-types";
import React from "react";
import genericCoord from "scenes/2_setup_designer/designer/elements/common/GenericCoord";
import { tlog } from "scenes/2_setup_designer/designer/elements/debugging";
import { LocationPickerScene } from "../../../scenes/1_location_picker";

export const LocationPickerRoute = ({ stateTracked, setStateTracked }) => {
    tlog("🧩-🚏 LocationPickerRoute");
    /**
     * If the user changes the current location in the location-picker
     * this method updates the stateTracked.step1.lat|lng coords.
     * @param {object} newStep1
     */
    const step1ChangedCB = newStep1 => {
        const newCoord = genericCoord({ fns: newStep1 });
        const oldCoord = genericCoord({ literal: stateTracked.step1 });
        if (newCoord.equals(oldCoord)) {
            tlog("No change", newCoord, oldCoord);
        } else {
            tlog("Change", newCoord, oldCoord);
            setStateTracked(prevState => ({
                ...prevState,
                step1: { ...prevState.step1, ...newCoord.literal }
            }));
        }
    };

    /**
     * If the zoom-level is changed, update the common object
     * @param {object} newCommon
     */
    const commonChangedCB = newCommon => {
        setStateTracked(prevState => ({
            ...prevState,
            common: { ...prevState.common, ...newCommon }
        }));
    };

    return (
        <LocationPickerScene
            step1={stateTracked.step1}
            step1ChangedCB={step1ChangedCB}
            initialCenter={stateTracked.step1}
            common={stateTracked.common}
            commonChangedCB={commonChangedCB}
        />
    );
};

LocationPickerRoute.propTypes = {
    stateTracked: PropTypes.object,
    setStateTracked: PropTypes.func
};
