import React from "react";
import "./JoinedButton.scss";

export const JoinedButton = ({ children, ...rest }) => {
    return (
        <div className="joinedButton" {...rest}>
            {children}
        </div>
    );
};
export default JoinedButton;
