import { centimetersSqToMetersSq, lngLatArrToObjFn, round8 } from 'scenes/common/Utils';
import { defaultRectangleSettings } from '../../elements/common/DefaultPolySettings';
import { handleDragCenterUpdates } from '../common/CommonDrawing';
import genericCoord from '../common/GenericCoord';
import { translateSinglePath } from '../common/logic/polyTranslation';
import { getWindowDebug, isDebugging, tlog } from '../debugging';
import { calculateAreaFromParams } from './RectangleAreaCalculation';
import { calculateRectangle } from './RectangleCalculation';
import transformTranslate from '@turf/transform-translate';
import { lineString } from '@turf/helpers';
import { BORDER_STEP_SIZE, MINIMUM_BORDER_THICKNESS, ONE_CELL, RECTANGLE_STEP_SIZE } from './RectangleConsts';
import polyRotation from '../common/logic/polyRotation';
import { round1 } from '../../../../common/Utils';

/**
 * Rectangle-data for the statistics-block - JsDoc definition
 * @typedef {Object} RectStatBlockDataJD
 * @property {string} outer - The outer edge lengths of the rect | "10m x 10m"
 * @property {string|null} inner - The inner edge lengths of the rect | "(7.5m x 7.5m)"
 * @property {string} areaInSqMRounded - The area of this rect | "75m²"
 */

/**
 * @typedef {import("../common/GenericCoord").GenericCoord} GenericCoord
 * @typedef {import("./RectangleCreationParams").RectangleCreationParams} RectangleCreationParams
 */

/**
 * @typedef {object} RectangleConstructorParams
 * @property {RectangleCreationParams} creationParams
 * @property {{google: object, aSMgmt: object}} elBench
 */

/**
 *
 */
export class RectangleClass {
    /**
     *
     * @param {RectangleConstructorParams} param0
     */
    constructor({ creationParams, elBench, createPopupClass }) {
        /**
         * @type {RectangleCreationParams}
         */
        this.creationParams = creationParams;

        /**
         * @type {import("./RectangleCalculation").RectMeta}
         */
        this.meta = undefined;
        this.tools = {
            google: elBench.google,
            elBench
        };
        // Calculate the coordinates of this rectangle
        this.coordinates = this.calculateCoords();
        // Use the coordinates to init a google poly
        /**
         * @type {google.maps.Polygon} Google maps polygon with added .parent & .creationParams
         */
        this.googlePoly = this.initGooglePoly();

        // Initially the rounded-origin is equal to the non-rounded-origin
        this.roundedOrigin = genericCoord({
            literal: this.creationParams.originCoord
        });

        this.sizePopup = new (createPopupClass(this.tools.google))(
            this.topLeft.latLngFn,
            this.statBlock
        );
        this.enablePopup();
        getWindowDebug().popup = this.sizePopup;

        // history
        this.updateLastKnownPosition();

        // debugging
        if (isDebugging()) {
            window.debug.elements2 = window.debug.elements2 ? window.debug.elements2 : [];
            window.debug.elements2.push(this);
        }
    }

    enablePopup () {
        this.sizePopup.setMap(this.tools.elBench.map);
    }

    /**
     * Sets map of this poly and its info-popup to null
     */
    removeFromMap() {
        this.googlePoly.setMap(null);//
        this.sizePopup.setMap(null);//
    }

    /**
     * Get the data for the statistic block for this rectangle.
     * @return {RectStatBlockDataJD}
     */
    get statBlockObj () {
      const {
        rectangleDistCmX: xInCm,
        rectangleDistCmY: yInCm,
        useRoundedCorners,
        withHole,
        borderThickness: borderThicknessInCm
      } = this.creationParams;
      const cm2m = (cm) => (cm/100).toFixed(1);
      // Inner to outer rect edge has to pass the border twice
      const calcInnerFromOuterCm = outerEdgeInCm => (outerEdgeInCm - (borderThicknessInCm * 2));

      const xInM = cm2m(xInCm);
      const yInM = cm2m(yInCm);
      // Outer edge lengths
      const outer = `${xInM}m x ${yInM}m`;

      let inner = ""
      if(withHole) {
        const xInnerInM = cm2m(calcInnerFromOuterCm(xInCm));
        const yInnerInM = cm2m(calcInnerFromOuterCm(yInCm));
        // Inner edge lengths
        inner = `${xInnerInM}m x ${yInnerInM}m`;
      }

      const areaInSqM = this.calculateArea();
      // Area: Outer - inner
      const areaInSqMRounded = useRoundedCorners ? "~"+round1(areaInSqM) : areaInSqM;
      return {outer, inner, areaInSqMRounded};
    }

    get statBlock () {
        const {outer, inner, areaInSqMRounded} = this.statBlockObj;
        const inner_ = inner === "" ? inner : ` (${inner})`;
        return `${outer}<br/> ${inner_}<br/>${areaInSqMRounded}m²`;
    }

    /**
     * Used by #thickenBorder and #slimBorder
     */
    recalculateRectangleAndUpdatePoly() {
        tlog("Recalculating rectangle and updating polygon with new data");
        this.coordinates = this.calculateCoords();
        this.googlePoly.setPaths(this.coordinates.toArray());

        const { rotation } = this.creationParams;
        polyRotation(this.googlePoly, rotation, () => {}, true);

        // Update popup
        this.handleSizeChange();
    }

    /**
     * To be triggered on any size change
     */
    handleSizeChange() {
        this.sizePopup.updatePositionAndText(this.topLeft.latLngFn, this.statBlock);
    }

    /**
     * Thickens border to either 1x1 or 2x2 hole
     * e.g. odd : 1x1, even: 2x2, because of step-size
     */
    thickenBorder() {
        if (!this.creationParams.withHole) {
            return;
        }

        const { rectangleDistCmX: x, rectangleDistCmY: y } = this.creationParams;

        // e.g. (5x3): 1250x750 => 750 => 3
        const shorterEdgeCellCount = Math.min(x, y) / RECTANGLE_STEP_SIZE;

        const current = this.creationParams.borderThickness;
        const newBorder = current + BORDER_STEP_SIZE;
        // blockedCellsByBorder: number of 250cm cells blocked by this border
        const blockedCellsByNewBorder = (2 * newBorder) / BORDER_STEP_SIZE;

        // One 'cell' is 2.5mx2.5m
        // the minimal inner rect shall be at least one cell big, otherwise it wouldn't be
        // a rectangle 'withHole'.
        // Not writing a "if too small convert to !withHole" feature unless requested.
        // 1x1, 2x1, 2x2, 3x2 => no inner possible
        // 1: 3x2 => (2 - 2*1) =  0>=1 ✘  => invalid
        // 1: 3x3 => (3 - 2*1) =  1>=1 ✔  => border:1;hole:1
        // 2: 3x3 => (3 - 2*2) = -1>=1 ✘  => invalid
        // 1: 4x3 => (3 - 2*1) =  1>=1 ✔  => border:1;hole:2x1
        // 2: 4x3 => (3 - 2*2) = -1>=1 ✘  => invalid
        // 1: 5x3 => (3 - 2*1) =  1>=1 ✔  => border:1;hole:3x1
        // 2: 5x3 => (3 - 2*2) = -1>=1 ✘  => invalid
        // 1: 4x4 => (4 - 2*1) =  2>=1 ✔  => border:1;hole:2x2
        // 2: 4x4 => (4 - 2*2) =  0>=1 x  => invalid
        // 1: 5x4 => (4 - 2*1) =  2>=1 ✔  => border:1;hole:3x2
        // 2: 5x4 => (4 - 2*2) =  0>=1 x  => invalid
        // 1: 5x5 => (5 - 2*1) =  3>=1 ✔  => border:1;hole:3x3
        // 2: 5x5 => (5 - 2*2) =  1>=1 ✔  => border:2;hole:1x1
        if (shorterEdgeCellCount - blockedCellsByNewBorder >= 1) {
            this.creationParams.borderThickness += BORDER_STEP_SIZE;
            // re-calculate rectangle presentation by re-create&replace
            this.recalculateRectangleAndUpdatePoly();
        } else {
            console.log(shorterEdgeCellCount, blockedCellsByNewBorder);
        }
    }

    /**
     * Slim border unless it would slim the border to less than 2.5m.
     */
    slimBorder() {
        if (!this.creationParams.withHole) {
            return;
        }
        const current = this.creationParams.borderThickness;
        // 500-250 = 250 == 250; => thin border
        // 250-250 = 0 < 250 => don't thin border
        if (current - BORDER_STEP_SIZE >= MINIMUM_BORDER_THICKNESS) {
            this.creationParams.borderThickness -= BORDER_STEP_SIZE;
            // re-calculate rectangle presentation by re-create&replace
            this.recalculateRectangleAndUpdatePoly();
        }
    }

    holeCanShrinkMore(isShrinkWest) {
        if (!this.creationParams.withHole) {
            return true;
        }
        // if I shrink my rectangle, it should only shrink so far that the inner rect
        // is 1x1 (2.5m) sized
        // To verify that we have this method which returns the shorter edge of the current inner rectangle
        // if this is already 2.5m we shouldn't shrink further
        const { borderThickness, rectangleDistCmX: x, rectangleDistCmY: y } = this.creationParams;

        const shorterEdgeCellCount = (isShrinkWest ? x : y) / RECTANGLE_STEP_SIZE;

        const blockedCellsByCurrentBorder = (borderThickness * 2) / BORDER_STEP_SIZE;
        const holeCellCount = shorterEdgeCellCount - blockedCellsByCurrentBorder;

        return holeCellCount > 1; // implying one can only shrink by integer 1
    }

    get holeSupported() {
        const { rectangleDistCmX: xEdge, rectangleDistCmY: yEdge } = this.creationParams;
        const COMBINED_BORDER_WIDTH_ON_ONE_EDGE = MINIMUM_BORDER_THICKNESS * 2;
        const remainingXEdge = xEdge - COMBINED_BORDER_WIDTH_ON_ONE_EDGE;
        const remainingYEdge = yEdge - COMBINED_BORDER_WIDTH_ON_ONE_EDGE;
        // for the hole to be supported, the rectangle has to be at least 3x3 cells big
        // to fit the minimum border width of one cell (1 cell = 2.5m)
        let result = remainingXEdge >= ONE_CELL && remainingYEdge >= ONE_CELL;
        return result;
    }

    /**
     * Unterscheidung rounded, !rounded + conversion to genericCoord
     * @returns {GenericCoord} top-left of the rectangle (virtual for the curved rectangle)
     */
    get topLeft() {
        let ret;
        if (this.creationParams.useRoundedCorners) {
            // rounded needs to be kept up-to-date on each drag
            ret = this.roundedOrigin;
        } else {
            // non-rounded is always just the first(outer) path, first coord
            ret = genericCoord({
                fns: this.googlePoly.getPaths().getAt(0).getAt(0)
            });
        }
        return ret;
    }

    get hasInnerRect() {
        return this.googlePoly.getPaths().getLength() === 2;
    }

    /**
     * x-----x
     * | x-x |
     * | | | |
     * | x-x |
     * 1-----2
     * @returns {{start: number, end: number}} */
    get bottomOuterHalfIndices() {
        return this.creationParams.useRoundedCorners ? { start: 9, end: 26 } : { start: 1, end: 2 }; // todo
    }

    /**
     * x-----3
     * | x-x |
     * | | | |
     * | x-x |
     * x-----2
     * @returns {{start: number, end: number}} */
    get rightOuterHalfIndices() {
        return this.creationParams.useRoundedCorners
            ? { start: 18, end: 35 }
            : { start: 2, end: 3 };
    }

    /**
     * x-----x
     * | x-x |
     * | | | |
     * | 3-2 |
     * x-----x
     * @returns {{start: number, end: number}} */
    get bottomInnerHalfIndices() {
        return this.creationParams.useRoundedCorners
            ? { start: 18, end: 35 }
            : { start: 2, end: 3 }; // todo
    }

    /**
     * x-----x
     * | x-1 |
     * | | | |
     * | x-2 |
     * x-----x
     * @returns {{start: number, end: number}} */
    get rightInnerHalfIndices() {
        return this.creationParams.useRoundedCorners ? { start: 9, end: 26 } : { start: 1, end: 2 }; // todo
    }

    calculateCoords() {
        const { meta, paths } = calculateRectangle(this.creationParams);
        // const {
        //     outerRectangleOrigin,
        //     innerOriginNum,
        //     borderThickness
        // } = meta;
        this.meta = meta;
        const [outerRectangle, innerRectangle] = paths;
        return {
            outerRectangle: outerRectangle,
            innerRectangle: innerRectangle,
            toArray: function () {
                return this.innerRectangle
                    ? [this.outerRectangle, this.innerRectangle]
                    : [this.outerRectangle];
            }
        };
    }

    /**
     * Initialize the google.maps.Polygon object representing this rectangle, as
     * well as holding the displayed coordinates.
     * @returns {google.maps.Polygon}
     */
    initGooglePoly() {
        const googlePoly = new this.tools.google.maps.Polygon({
            ...defaultRectangleSettings,
            paths: this.coordinates.toArray(),
            visible: true
        });
        // new interface
        googlePoly.parent = this;
        // downwards compatibility
        {
            // redirect rectanglePoly.creationParams to rectanglePoly.parent.creationParams
            const self = this;
            Object.defineProperty(googlePoly, "creationParams", {
                get: function creationParams() {
                    return self.creationParams;
                }
            });
        }
        tlog("initGooglePoly!", this.coordinates.outerRectangle[0]);
        handleDragCenterUpdates(this.tools.elBench, googlePoly);
        return googlePoly;
    }

    /**
     * Here we save the last known position of the top-left polygon.
     * This way we can "bounce-back" to it later, when the user missplaces this rectangle.
     */
    updateLastKnownPosition() {
        const newOrigin = this.topLeft.literal;
        this.lastKnownPosition = { outerTopLeft: newOrigin };
    }

    /**
     * Called when drag was allowed (no-overlaps)
     * @param {GenericCoord} delta between drag start and end
     */
    handleDrag(delta) {
        // Rounded corner rectangles don't have the top-left corner at paths[0][0]
        if (this.creationParams.useRoundedCorners) {
            // We have to update the "virtual" origin of the rounded rectangle
            // since it is not a coordinate of the googlePoly.paths
            this.roundedOrigin = this.roundedOrigin.add(delta);
        } else {
            // the non-rounded rectangles keep paths[0][0] as their origin
            // which is directly updated by google-maps
        }
        // since we now have completed a drag movement we need to
        // remember the new position so we can jump back there
        // should the user drop the rectangle on an invalid position
        this.updateLastKnownPosition();

        // we also need to update the creationParas.origin as some calls get their data from there
        this.creationParams.origin = this.topLeft.literal;

        // Update popup-position
        this.sizePopup.updatePositionAndText(this.topLeft.latLngFn, this.statBlock);
    }

    /**
     * This method moves the rectangle to its last known position.
     * For this we compare the current position of the (originally) top-left polygon
     * of the rectangle with the last known position of this top-left polygon.
     * For that we save the last known position of the top-left polygon on each movement.
     * The movement of the inner rectangle should be equivalent to the movement of the
     * outer rectangle.
     */
    translateToPreviousPosition() {
        const lastTopLeft = genericCoord({
            literal: this.lastKnownPosition.outerTopLeft
        });
        const gPaths = this.googlePoly.getPaths();
        const referenceIndex = 0; // top-left
        const nowTopLeft = genericCoord({
            fns: gPaths.getAt(0).getAt(referenceIndex)
        });
        const delta = lastTopLeft.diff(nowTopLeft);
        const length = gPaths.getLength();
        if (length === 1) {
            // Single Rect
            translateSinglePath(gPaths.getAt(0), delta);
        } else {
            // Rect with inner Rect
            gPaths.getArray().forEach(path => translateSinglePath(path, delta));
        }
    }

    rectTooSmall(isShrink, expandEast, crParams, widthOfCorners) {
        const minSize = crParams.withHole ? 750 : 250;
        return (
            isShrink &&
            ((expandEast && crParams.rectangleDistCmX - widthOfCorners <= minSize) ||
                (!expandEast && crParams.rectangleDistCmY - widthOfCorners <= minSize))
        );
    }

    /**
     * From the given path copy a quarter into a lineString,
     * e.g. all cords from the top-left to bottom-left corner
     * @param {number} quarter - Size of a quarter e.g. path.length/4
     * @param {google.maps.MVCArray} path
     * @param {number} start At which quarter to start
     * @param {number} end  At which quarter to end
     */
    toLineString(path, start, end) {
        const turfPolyArr = [];
        // bottom-left + bottom-right
        for (let i = start; i <= end; i++) {
            if (i > path.getLength()) {
                console.error(i, "is bigger than", path.getLength());
                throw Error("outofbounds");
            }
            const vert = path.getAt(i);
            turfPolyArr.push([vert.lng(), vert.lat()]); // turf order lngLat
        }
        return lineString(turfPolyArr);
    }

    /**
     * Method for expansion of a polygon, specifically a rectangle.
     * when expanding a rectangle we cut the rectangle polygons into half,
     * translate the polygons furthest from the origin and then bind them back into the
     * polygon-elment
     * e.g.
     *
     * Original rectangle
     *o
     * 0-----1
     * |     |
     * 2-----3
     * Cut in half
     *o
     * 0--.--1
     * |  .  |
     * 2--.--3
     * Only transform one half
     *o
     *       1
     *       |
     *       3
     * Expand that half
     * o
     *               1
     *       <expand>|
     *               3
     * Override old vertices
     *o
     * 0-------------1
     * |             |
     * 2-------------3
     *
     * Note: might seem overengineered for simple rectangle, but the same logic shall apply for rounded corners
     *
     * Except for the fact that the rounded corners have to be kept in mind when shrinking the element.
     * If you shrink the element by more then the combined width(?) of the corners they overlap.
     *  _                  _
     * (_) -> () -> )( -> )_(
     *
     *           .
     *        .  |
     *     .     .
     *   .       |
     *  . _ _  _ .
     *
     * Expand/shrink [upper-right,lower-right]-corner or [lower-left, lower-right]-corner
     * ...
     * @param twoPointFiveMeterExpansionCount - ...
     * @param expandEast - True=↔ False=↕
     */
    expand(twoPointFiveMeterExpansionCount, expandEast) {
        /**
         * Overwrite the coordinates of the path within the defined quarter with coordinates from the rectangleEdge turf-linestring.
         * @param {number} quarterCoordCount Count of coordinates in one quarter of the poly
         * @param {Feature<LineString>} rectangleEdge polygon from which we source the new coordinates
         * @param {MVCArray<LatLng>} path path which is the target for the new coordinates
         * @param {number} start quarter to start at
         * @param {number} end quarter to finish at
         */
        const bindNewCoordsToPoly = (rectangleEdge, path, start, end) => {
            const startIdx = start;
            for (let i = startIdx; i <= end; i++) {
                const k = i - startIdx;
                const lngLatArr = rectangleEdge.geometry.coordinates[k]; // modified via mutate: true
                if (!lngLatArr) {
                    console.error(
                        i,
                        "might be bigger than",
                        rectangleEdge.geometry.coordinates.length
                    );
                }

                path.setAt(i, lngLatArrToObjFn(lngLatArr));
            }
        };

        const self = this;
        const crParams = this.creationParams;
        const isShrink = twoPointFiveMeterExpansionCount < 0;
        const distance = Math.abs(twoPointFiveMeterExpansionCount) * 250;
        const widthOfCorners = crParams.useRoundedCorners ? crParams.singleCornerWidth * 2 : 0;

        if (this.rectTooSmall(isShrink, expandEast, crParams, widthOfCorners, distance)) {
            tlog("User attempted to shrink an rectangle to 0");
            return false;
        }

        if (crParams.withHole && isShrink) {
            if (!this.holeCanShrinkMore(expandEast)) {
                tlog("User attempted to shrink an rectangle-hole to less then 2.5m x 2.5m");
                return false;
            }
        }

        // Actual logic
        function expandOrShrink(expandEast, path, crParams, isInnerRect) {
            const rot = crParams.rotation;
            // direction relative to pointing up => 180 = expand downwards
            let direction, cornerIdxStart, cornerIdxEnd;

            if (expandEast) {
                direction = isShrink ? rot - 90 : rot - 90 + 180; // Move east-side either east or west
                // Start and end-coordinate of [bottom-right + top-right]-corner
                // = points representing the right half of the rectangle

                // either I check if I'm the innerRect here or pass all in here and discover it here

                /** @type {{start: number, end: number}} */
                let indices = isInnerRect ? self.rightInnerHalfIndices : self.rightOuterHalfIndices;
                cornerIdxStart = indices.start;
                cornerIdxEnd = indices.end;

                crParams.rectangleDistCmX += isInnerRect ? 0 : isShrink ? -distance : distance;
            } else {
                direction = isShrink ? rot : rot + 180;
                // bottom-right + bottom-left
                let indices = isInnerRect
                    ? self.bottomInnerHalfIndices
                    : self.bottomOuterHalfIndices;
                cornerIdxStart = indices.start;
                cornerIdxEnd = indices.end;

                crParams.rectangleDistCmY += isInnerRect ? 0 : isShrink ? -distance : distance;
            }
            // With the given indices, assemble a linestring of the matching coordinates
            const rectangleEdge = self.toLineString(path, cornerIdxStart, cornerIdxEnd);

            // Move the lineString
            transformTranslate(rectangleEdge, distance, direction, {
                units: "centimeters",
                mutate: true
            });
            // And update the poly with the moved points
            bindNewCoordsToPoly(rectangleEdge, path, cornerIdxStart, cornerIdxEnd);
        }

        // Get vars vor logic
        const paths = this.googlePoly.getPaths();

        expandOrShrink(expandEast, paths.getAt(0), this.creationParams, false);
        if (this.hasInnerRect) {
            expandOrShrink(expandEast, paths.getAt(1), this.creationParams, true);
        }

        // round to 1.1 mm accuracy to discard floating-point noise e.g. +-0.00000000001
        paths.getArray().forEach(path => {
            const coords = path.getArray();
            for (let i = 0; i < coords.length; i++) {
                const coord = coords[i];
                const newLatLng = new this.tools.google.maps.LatLng(
                    round8(coord.lat()),
                    round8(coord.lng())
                );
                path.setAt(i, newLatLng);
            }
        });

        // update popup
        this.handleSizeChange();

        return true;
    }

    /**
     * Calculates the area of this rectangle based on the creation-params
     * @returns {number} Area in m²
     * @see designer.jsx#totalAreaFromGooglePolys
     */
    calculateArea() {
        const mathArea = calculateAreaFromParams(this.creationParams);
        return centimetersSqToMetersSq(mathArea);
    }
}

export default RectangleClass;
