export const ONE_CELL = 250;
export const ARC_STEP_SIZE = ONE_CELL; // in-/de-crease by 2.5m
export const MINIMUM_BORDER_THICKNESS = ONE_CELL; // 2.5m in cm
export const BORDER_STEP_SIZE = ONE_CELL; // in-/de-crease by 2.5m
/**
 * Minimum width to contain a ONE_CELL hole
 * @type {number}
 */
export const THREE_CELLS = 3 * ONE_CELL;
export const MIN_DIAMETER_FOR_WITH_HOLE = THREE_CELLS;

export const ARC_TYPE = "polyArc";
