import { pow2 } from "../../../../common/Utils";

/**
 * Note: the outer rectangle point array is arranged counter-clockwise from top left, [TL,BL,BR,TR]
 * while the inner rectangle point array is arranged clockwise from top left. [TL,TR,BR,BL]
 *    0---------------------------------3
 *    |  ️                               |
 *    |   0-------------------------1   |
 *    |   |                         |   |
 *    |   3-------------------------2   |
 *    |  ️                               |
 *    1---------------------------------2
 */
export const INNER_CLOCKWISE = true; // outer shall always be the opposite

/**
 * Distance between an outer and inner rectangle edge
 * +---------------------------------+
 * |   ↕️RectangleBorderWidthCm↕️      |
 * |   +-------------------------+   |
 * | ↔️ |                         | ↔️ |
 * |   +-------------------------+   |
 * |   ↕️RectangleBorderWidthCm↕️      |
 * +---------------------------------+
 *
 */
export const ONE_CELL = 250;
export const RECTANGLE_STEP_SIZE = ONE_CELL; // in-/de-crease by 2.5m
export const MINIMUM_BORDER_THICKNESS = ONE_CELL; // 2.5m
export const BORDER_STEP_SIZE = ONE_CELL; // in-/de-crease by 2.5m

export const RECTANGLE_TYPE = "rectangle";

/**
 * Distance between an outer and inner rectangle edge
 * +---------------------------------+
 * | ↘️                            ↙️ |
 * |   +-------------------------+   |
 * |   |                         |   |
 * |   +-------------------------+   |
 * | ↗️                            ↖️ |
 * +---------------------------------+
 *
 */
export const RectangleBorderCornerDiagonalDistance = (borderWidth, borderHeight) =>
    Math.sqrt(pow2(borderHeight) + pow2(borderWidth));

/**
 * <pre>
 * Rounding-area-edge-length of rectangle = RECTANGLE_ROUNDING_OFFSET
 * ⬇️  ⬇️️
 * +--+--+--+--+
 * |  |  .  |  |
 * +--+  .  +--+
 * |     .     |
 * +...........+
 * |     .     |
 * +--+  .  +--+
 * |  |  .  |  |
 * +--+--+--+--+
 *
 * ├--┤ ONE_CELL/4
 * ├-----------┤ ONE_CELL
 *
 * </pre>
 * <br/>
 * The rectangle corners are rounded using a circle with a diameter of half a cell.
 * W/H of circle = ONE_CELL/2
 * W/H of one corner = (ONE_CELL/2)/2 = ONE_CELL/4
 * An offset of 'ONE_CELL/2' would result in the smallest rectangle being a circle.
 * @type {number} 62.5cm
 */
export const RECTANGLE_ROUNDING_OFFSET = ONE_CELL / 4;
