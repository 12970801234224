import React, {Profiler, useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import Modal from "react-modal";

import {Button} from "components/Button/Button";
import {paths} from "IceRinkApp/route_consts";
import {Designer} from "./designer";
import {profilerCallbackHandler} from "./designer/elements/debugging";
import {calculateEdgeLengthForZoom} from "./designer/elements/grid/GridCoordCalculation";
import {OverridableMethod} from "./designer/OverridableMethod";
import "./index.scss";
import {useGA} from "scenes/common/useGA";

const getEdgeStr = stateTracked => {
  const edgeLengthInCm = calculateEdgeLengthForZoom(stateTracked.common.zoom);
  return edgeLengthInCm > 100000
    ? (edgeLengthInCm / 100000).toString() + "k"
    : (edgeLengthInCm / 100).toString();
};

const SetupDesignerScene = ({stateTracked, ...params}) => {
  const {t} = useTranslation();
  useGA();

  const [isAlertModalVisible, setisAlertModalVisible] = useState(false);
  const showAlert = useCallback(message => {
    setAlertMessage(message);
    setisAlertModalVisible(true);
  }, [setAlertMessage, setisAlertModalVisible]);
  const hideAlert = useCallback(() => setisAlertModalVisible(false), [setisAlertModalVisible]);
  const [alertMessage, setAlertMessage] = useState("");

  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const confirm = useCallback(e => {
    e.preventDefault();
    setIsConfirmVisible(true);
  }, [setIsConfirmVisible]);
  const cancelConfirm = useCallback(() => setIsConfirmVisible(false), [setIsConfirmVisible]);

  // Save State to local storage
  const saveState2LS = new OverridableMethod();
  const save = () => saveState2LS.invokeMethod();

  // Clear local storage
  const discardAllElementsOnPageExitOM = new OverridableMethod();

  const discardElements = () => {
    discardAllElementsOnPageExitOM.invokeMethod();
  };

  let [allFieldsConnected, setAllFieldsConnected] = useState(true);
  const updateAllFieldsConnected = newVal => {
    setAllFieldsConnected(newVal);
    allFieldsConnected = newVal;
  };
  let [hasOverlappingElements, setHasOverlappingElements] = useState(false);
  const updateHasOverlappingElements = newVal => {
    setHasOverlappingElements(newVal);
    hasOverlappingElements = newVal;
  };

  /**
   * @type {OverridableMethod} shall be overridden in designer to bind 'hasOverlappingElements', 'allFieldsConnected', 'area' and 'circumfence'
   *
   */
  const requestDesignerUpdate = new OverridableMethod();

  return (
    <Profiler id="SetupDesignerScene" onRender={profilerCallbackHandler}>
      <div className="container">
        <div>
          {/* Eisbahn nach Ihrem Wunsch designen. */}
          <h1 className="introText tc1">{t("designer.texts.intro.explanation")}</h1>
          {/* Tipp: um die vorhandene Fläche noch besser zu nutzen, können Sie auch mehrere Formen miteinander kombinieren */}
          <h3 className="introText tc2 highlight">{t("designer.texts.intro.hint")}</h3>
        </div>

        <div className="innerContainer">
          <AlertPopup modalOpen={isAlertModalVisible} onClose={hideAlert}>
            <h1>{t("modals.alert.title")}</h1>
            <p>{alertMessage}</p>
            <div onClick={hideAlert} className={"modal-close"}>
              <Button color={"orange"}>{t("app.general.close")}</Button>
            </div>
          </AlertPopup>
          <Confirm modalOpen={isConfirmVisible} onClose={cancelConfirm}>
            <h1>{t("modals.confirm.title")}</h1>
            <p>{t("designer.confirm.navigateBackAndLooseData")}</p>
            <div className={"modal-close"}>
              <div onClick={cancelConfirm}>
                <Button color={"orange"}>{t("app.general.close")}</Button>
              </div>
              <div>
                <Button color={"orange"}>
                  <NavLink to={paths.step1} onClick={discardElements}>
                    {t("app.general.confirm")}
                  </NavLink>
                </Button>
              </div>
            </div>
          </Confirm>
          {/* <SetupDesigner latitude={() => g_state.lat} longitude={() => g_state.lng} /> */}
          <Designer
            {...params}
            discardAllElementsOnPageExitOMOverride={fn =>
              // Override the stub with the actual implementation
              discardAllElementsOnPageExitOM.overrideMethod(fn)
            }
            stateTracked={stateTracked}
            navMethodOverride={fn => {
              saveState2LS.overrideMethod(fn);
            }}
            updateAllFieldsConnected={updateAllFieldsConnected}
            updateHasOverlappingElements={updateHasOverlappingElements}
            requestDesignerUpdateOverride={fn => {
              requestDesignerUpdate.overrideMethod(fn);
            }}
          />
          <div className="lowerArea">
            {/* Durch drag & drop können Sie die Eisbahn beliebig einsetzen. Eine Gitterzelle ist ungefähr {{var1}} x {{var1}} groß.*/}
            <p>
              <i>
                {t("app.description.designing", {
                  var1: getEdgeStr(stateTracked)
                })}
              </i>
            </p>
            <div className="navbar">
              <Button color={"orange"} className={"prev"}>
                <NavLink to={paths.step1} onClick={confirm}>
                  {t("app.buttons.previousStep")}
                </NavLink>
              </Button>
              <Button color={"orange"} className={"next"}>
                <NavLink
                  onClick={e => {
                    /**
                     * The designer shall bind 'hasOverlappingElements', 'allFieldsConnected', 'area' and 'circumfence' in this call
                     * Designer#requestDesignerUpdateOverride
                     */
                    requestDesignerUpdate.invokeMethod();
                    save();
                    /** As per request from Intercom for now we'll ignore the warnings
                     if (hasOverlappingElements) {
                                            showAlert(t("designer.error.overlappingFields"));
                                            e.preventDefault();
                                        } else {
                                            if (allFieldsConnected) {
                                                //save(); // => navigate
                                            } else {
                                                showAlert(t("designer.error.multipleFields"));
                                                e.preventDefault();
                                            }
                                        }
                     */
                  }}
                  to={paths.step3}
                >
                  {t("app.buttons.nextStep")}
                </NavLink>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Profiler>
  );
};

SetupDesignerScene.propTypes = {
  stateTracked: PropTypes.object,
  totalArea: PropTypes.number
};

export default SetupDesignerScene;

const customStyles = {
  content: {
    position: "absolute",
    top: "40px",
    left: "40px",
    right: "40px",
    bottom: "40px",
    border: "1px solid #ccc",
    background: "#fff",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "4px",
    outline: "none",
    padding: "20px",
    height: "300px",
    margin: "0 auto",
    width: "700px"
  }
};

const AlertPopup = ({modalOpen, onClose, children}) => {
  useEffect(() => {
    Modal.setAppElement("#appRoot");
  });

  return (
    <div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={onClose}
        style={customStyles}
        contentLabel="alert"
      >
        <div className={"alertPopup"}>{children}</div>
      </Modal>
    </div>
  );
};

const Confirm = ({modalOpen, onClose, children}) => {
  useEffect(() => {
    Modal.setAppElement("#appRoot");
  });

  return (
    <div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={onClose}
        style={customStyles}
        contentLabel="confirm"
      >
        <div className={"alertPopup"}>{children}</div>
      </Modal>
    </div>
  );
};
