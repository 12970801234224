import { lineString } from "@turf/helpers";
import transformTranslate from "@turf/transform-translate";
import turfPolygon from "turf-polygon";
import { lngLatArrToObj, pow2 } from "../../../../common/Utils";
import { debugPoly, isDebugging, tlog } from "../debugging";

/**
 * Converts turfPoly to latLng array
 * @param {Feature<Polygon>} turfPoly
 * @returns {LatLng[]}
 */
const turfPolyToLatLngObjArray = turfPoly => {
    const coords = turfPoly.geometry.coordinates[0];
    const latLngObjArr = coords.map(lngLatArrToObj);
    return latLngObjArr;
};

/**
 * This method shall resize the given path to the given path +2.5m border
 *
 * @param {Polygon} polygonTurfType
 * @param {google.maps.Polygon} googleMapsPoly
 * @returns {Feature<Polygon>} turf rectangle that is +2.5m bigger than param.turfPolygon
 */
export function rectangleError(polygonTurfType, googleMapsPoly) {
    const deepCopy = tPoly => {
        const coords = tPoly.geometry.coordinates[0];
        const copyCoords = [];
        coords.forEach(coordPair => {
            copyCoords.push([coordPair[0], coordPair[1]]);
        });
        return turfPolygon([copyCoords], {});
    };

    const crParams = googleMapsPoly.creationParams;
    const copyOfTurfPolygonParam = deepCopy(polygonTurfType);
    const turfCoords = copyOfTurfPolygonParam.geometry.coordinates[0];
    const errorBorder250cm = 250; // 250cm error

    /**
     * From the given path copy a quarter into a lineString,
     * e.g. all cords from the top-left to bottom-left corner
     * @param {number} quarter - Size of a quarter e.g. path.length/4
     * @param {number[]} turfPolyCoords LngLat
     * @param {number} start At which quarter to start
     * @param {number} end  At which quarter to end
     */
    function toLineString(quarter, turfPolyCoords, start, end) {
        if (quarter % 1 !== 0) {
            console.error("a fourth of the poly should be an integer and not ", quarter);
        }

        const turfPolyArr = [];
        // bottom-left + bottom-right
        for (let i = quarter * start; i < quarter * end; i++) {
            turfPolyArr.push(turfPolyCoords[i]); // turf order lngLat
        }

        return lineString(turfPolyArr);
    }

    /**
     *
     * @param {number} quarter
     * @param {*} rectangleEdge new coords
     * @param {*} turfCoords target
     * @param {*} start
     * @param {*} end
     */
    function bindNewCoordsToPoly(quarter, rectangleEdge, turfCoords, start, end) {
        const startIdx = quarter * start;
        for (let i = startIdx; i < quarter * end; i++) {
            const k = i - startIdx;
            const lngLatArr = rectangleEdge.geometry.coordinates[k]; // modified via mutate: true
            turfCoords[i] = lngLatArr;
        }
    }

    const expandXorY = (expandX, crParams, turfCoords) => {
        const rotation = crParams.rotation + 180;
        // direction relative to pointing up => 180 = expand downwards
        let direction;
        let cornerIdx;

        if (expandX) {
            // bottom-right + top-right
            direction = rotation - 90; // right?
            cornerIdx = [2, 4];
        } else {
            // bottom-right + bottom-left
            direction = rotation; // down
            cornerIdx = [1, 3];
        }
        const coordCountOfAQuarter = (turfCoords.length - 1) / 4;
        const rectangleEdge = toLineString(
            coordCountOfAQuarter,
            turfCoords,
            cornerIdx[0],
            cornerIdx[1]
        );
        // Translate the lineString = modify the polygon-coords via reference (mutate: true)
        transformTranslate(rectangleEdge, errorBorder250cm / 100, direction, {
            units: "meters",
            mutate: true
        });
        bindNewCoordsToPoly(
            coordCountOfAQuarter,
            rectangleEdge,
            turfCoords,
            cornerIdx[0],
            cornerIdx[1]
        );
    };

    // 1. Move the right edge (top-right, bottom-right) 250cm east
    const EXPAND_X = true;
    expandXorY(EXPAND_X, crParams, turfCoords);
    // 2. Move the bottom edge (bottom-left, bottom-right) 250cm south
    const EXPAND_Y = false;
    expandXorY(EXPAND_Y, crParams, turfCoords);
    // 3. Move the expanded rectangle-boundingbox north-west, relative to its rotation, to center it on the rectangle it contains
    const rota = crParams.rotation - 45;
    const diagonalDistanceCm = Math.sqrt(pow2(errorBorder250cm) + pow2(errorBorder250cm));
    const diagonalDistanceM = diagonalDistanceCm / 100 / 2;

    tlog("######", polygonTurfType);

    // debugPoly(turfPolyToLatLngObjArray(polygonTurfType))
    // transformTranslate(polygonTurfType, distance, rota, { units: 'meters', mutate: true });
    transformTranslate(copyOfTurfPolygonParam, diagonalDistanceM, rota, {
        units: "meters",
        mutate: true
    });
    // debugPoly(turfPolyToLatLngObjArray(polygonTurfType))

    // 4. display the boundingbox for debugging purposes
    isDebugging() && debugPoly(turfPolyToLatLngObjArray(copyOfTurfPolygonParam));

    return copyOfTurfPolygonParam;
}

export default rectangleError;
