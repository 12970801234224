import React from "react";

import "./Button.scss";

interface ButtonProps {
    color: "blue" | "orange";
    className?: string;
}

export const Button: React.FC<ButtonProps> = ({ color = "blue", children, className }) => {
    return (
        <button className={`i-button ${color} ${className}`}>
            <span className={"i-button-content"}>{children}</span>
        </button>
    );
};
