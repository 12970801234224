/**
 * Returns the Popup class.
 *
 * Unfortunately, the Popup class can only be defined after
 * google.maps.OverlayView is defined, when the Maps API is loaded.
 * This function should be called by initMap.
 */
import { dbg } from "./debugging";
const { log } = console;

export const createPopupClass = function (google) {
    /**
     * @class Popup
     * @property {HTMLElement} containerDiv
     * A customized popup on the map.
     * @param {!google.maps.LatLng} position
     * @param {!String} textContent The textContent for the bubble div.
     * @param {object?} map to attach to
     * @constructor
     * @extends {google.maps.OverlayView}
     */
    function Popup(position, textContent, map) {
        dbg(() => log(`Popup ${position.lat()},${position.lng()}; '${textContent}'`));
        this.position = position;

        const newDiv = () => document.createElement("div");

        const icon = document.createElement("i");
        icon.classList.add("ri-drag-move-2-fill");

        const textDiv = newDiv();
        textDiv.id = "content";
        textDiv.classList.add("popup-bubble");
        textDiv.addEventListener("click", e => {
            console.log(e.target.style.transform);
            if (e.target.style.transform === "translate(50%, 100%)") {
                e.target.style.transform = "translate(-50%, -100%)";
            } else {
                e.target.style.transform = "translate(50%, 100%)";
            }
        });
        textDiv.innerHTML = textContent;
        textDiv.append(icon);

        // This zero-height div is positioned at the bottom of the bubble.
        const bubbleAnchor = newDiv();
        bubbleAnchor.classList.add("popup-bubble-anchor");
        bubbleAnchor.appendChild(textDiv);

        // This zero-height div is positioned at the bottom of the tip.
        this.containerDiv = newDiv();
        this.containerDiv.classList.add("popup-container");
        this.containerDiv.appendChild(bubbleAnchor);

        this.textDiv = textDiv;

        // Optionally stop clicks, etc., from bubbling up to the map.
        google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
        if (map) {
            this.setMap(map);
        }
        return this;
    }
    // ES5 magic to extend google.maps.OverlayView.
    Popup.prototype = Object.create(google.maps.OverlayView.prototype);

    /** Called when the popup is added to the map. */
    Popup.prototype.onAdd = function () {
        this.getPanes().floatPane.appendChild(this.containerDiv);
    };

    /** Called when the popup is removed from the map. */
    Popup.prototype.onRemove = function () {
        console.error("onRemove", this);
        if (this.containerDiv.parentElement) {
            this.containerDiv.parentElement.removeChild(this.containerDiv);
        }
    };

    /** Called each frame when the popup needs to draw itself. */
    Popup.prototype.draw = function () {
        if (this.getMap() === null) {
            this.containerDiv.style.display = "none";
            return;
        }
        const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);

        const { abs } = Math;
        const { x, y } = divPosition;
        // Hide the popup when it is far out of view.
        const display = abs(x) < 4000 && abs(y) < 4000 ? "block" : "none";

        const { style } = this.containerDiv;

        if (display === "block") {
            style.left = x + "px";
            style.top = y + "px";
        }
        if (style.display !== display) {
            style.display = display;
        }
    };

    Popup.prototype.updatePositionAndText = function (newPosition, statBlock) {
        const icon = document.createElement("i");
        icon.classList.add("ri-drag-move-2-fill");

        this.textDiv.innerHTML = statBlock;
        this.textDiv.append(icon);
        console.log("Popup", newPosition, this.position);
        this.position = newPosition;
    };

    return Popup;
};
export default createPopupClass;
