import React from "react";
import "./index.scss";

export const FormGroup = ({ title, icon, children }) => {
    return (
        <div className="form-group">
            <div>
                <h2>{title}</h2>
                {icon}
            </div>
            <div className="form-group-container">{children}</div>
        </div>
    );
};
